import {
  getInscriptionForGeneralMenus,
  getInscriptionsBySeasonId,
  getInscriptionsBySeasonIdToExport,
} from "../../service/InscriptionApi";
import { getPhasesBySeasonId } from "../../service/PhaseApi";
// import { getTeamsBySeason, getTeamsBySeasonExport } from "../../service/TeamApi";
import { endSeason, reactivateSeason, getSeason } from "../../service/SeasonApi";
import router from "../../router/index";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { util_function } from "@/util_functions";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";

Vue.component("downloadExcel", JsonExcel);

const state = {
  filter: {
    statusInscriptions: [],
    area: null,
    discipline: null,
    inscription: null,
    city: null,
    rg: null,
    cpf: null,
    has_cpf: null,
    has_rg: null,
    has_bank_account: null,
    neighborhood: null,
    equipment: null,
    inscription_origin: null,
    area_of_interest: null,
  },
  params: {
    inscription_menu_name: null,
  },
  inscriptions: [],
  inscriptionsForGeneralMenus: [],
  inscriptionsForSpreadsheet: [],
  inscriptionsToExport: [],
  inscriptionFiltered: null,
  targetTeamFiltered: null,
  sourceTeamFiltered: null,
  teamToRemoveFiltered: null,
  hasTargetTeams: 0,
  hasSourceTeams: 0,
  hasRemoveTeams: 0,
  season: {
    name: "",
  },
  bodyTable: [],
  bodyTableStudent: [],
  bodyTableStudentDisconnected: [],
  students: [],
  filterStudents: [],
  filterStudentsPreInscription: [],
  areas: [],
  equipments: [],
  phases: [],
  disciplines: [],
  inscriptionTotal: 0,
  amountInscriptionOpen: 0,
  amountInscriptionUnderAnalysis: 0,
  amountInscriptionAccepted: 0,
  amountInscriptionNotAccepted: 0,
  amountInscriptionGraduate: 0,
  amountInscriptionArchived: 0,
  totalPerPage: 10,
  offset: 0,
  typeTable: null,
  partial: "inscriptions",
  phase: {
    disciplines: [],
  },
  showStudentDropdown: false,
  modalTransferStudentOpen: false,
  modalRemoveStudentFromTeamOpen: false,
  modalConfirmationRemoveStudent: false,
  toggleEndSeasonConfirmation: false,
  toggleReactivateSeasonConfirmation: false,
  allInscriptions: null,
  json_data: null,
  isLoadedData: false,
  isLoadedTeam: false,
  inscription_student_teams_attributes: [],
  hasSpreadsheetResponse: true,
  modalToggleExportSeasonSpreadsheet: false,
  user_equipments: [],
  user_equipments_filtered: null,
  loading: {
    data_season: false,
  },
};

function getRelativeDegreeKinship(array, index, relative_degree_kinship) {
  JSON.parse(JSON.stringify(array[index].student.relatives_attributes)).forEach((item) => {
    switch (item.degree_kinship) {
      case "wife":
        item.degree_kinship = "Esposa";
        break;
      case "husband":
        item.degree_kinship = "Esposo";
        break;
      case "mom":
        item.degree_kinship = "Mãe";
        break;
      case "father":
        item.degree_kinship = "Pai";
        break;
      case "son":
        item.degree_kinship = "Filho";
        break;
      case "daughter":
        item.degree_kinship = "Filha";
        break;
      case "brother":
        item.degree_kinship = "Irmão";
        break;
      case "sister":
        item.degree_kinship = "Irmã";
        break;
      case "grandmother":
        item.degree_kinship = "Avô";
        break;
      case "grandfather":
        item.degree_kinship = "Avó";
        break;
      case "uncle":
        item.degree_kinship = "Tio";
        break;
      case "aunt":
        item.degree_kinship = "Tia";
        break;
      case "stepfather":
        item.degree_kinship = "Padastro";
        break;
      case "stepmother":
        item.degree_kinship = "Madrasta";
        break;
      case "cousin":
        item.degree_kinship = "Prima";
        break;
      default:
        item.degree_kinship = "Outro";
        break;
    }
    relative_degree_kinship.push(item.degree_kinship);
  });
}

const mutations = {
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },
  openCloseEndSeasonConfirmationMutation: (state) => {
    state.toggleEndSeasonConfirmation = !state.toggleEndSeasonConfirmation;
  },
  openCloseReactivateSeasonConfirmationMutation: (state) => {
    state.toggleReactivateSeasonConfirmation = !state.toggleReactivateSeasonConfirmation;
  },
  filterStatusInscriptions: (state, status) => {
    const index = state.filter.statusInscriptions.indexOf(status);
    index !== -1
      ? state.filter.statusInscriptions.splice(index, 1)
      : state.filter.statusInscriptions.push(status);
  },
  filterInscriptionMutation: (state, inscriptionFiltered) => {
    state.inscriptionFiltered = inscriptionFiltered;
  },
  filterTargetTeamMutation: (state, targetTeamFiltered) => {
    state.targetTeamFiltered = targetTeamFiltered;
  },
  filterSourceTeamMutation: (state, sourceTeamFiltered) => {
    state.sourceTeamFiltered = sourceTeamFiltered;
  },
  filterTeamToRemoveMutation: (state, teamToRemoveFiltered) => {
    state.teamToRemoveFiltered = teamToRemoveFiltered;
  },
  hasSourceTeamsMutation: (state, hasSourceTeams) => {
    state.hasSourceTeams = hasSourceTeams;
  },
  hasTargetTeamsMutation: (state, hasTargetTeams) => {
    state.hasTargetTeams = hasTargetTeams;
  },
  hasRemoveTeamsMutation: (state, hasRemoveTeams) => {
    state.hasRemoveTeams += hasRemoveTeams;
  },
  setSeason: (state, season) => {
    state.season = season;
  },
  setDisciplines: (state, disciplines) => {
    state.disciplines = disciplines;
  },
  setInscriptions: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  setInscriptionsSpreadsheet: (state, inscriptionsForSpreadsheet) => {
    state.inscriptionsForSpreadsheet = inscriptionsForSpreadsheet;
  },
  setInscriptionStudentTeamsAttributes: (state, inscription_student_teams_attributes) => {
    state.inscription_student_teams_attributes = inscription_student_teams_attributes;
  },
  setEquipmentsMutation: (state, equipments) => {
    state.equipments = equipments;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setInscriptionsToExport: (state, inscriptionsToExport) => {
    state.json_data = null;
    const arrayData = new Array();
    inscriptionsToExport.forEach((element, index, array) => {
      let status = util_function.processInscriptionStatuses(array[index].status);
      let feedback = array[index].feedback ? array[index].feedback : "-";
      let created_at = array[index].created_at;
      let responsible_professional = array[index]?.responsible_professional?.userName
        ? array[index].responsible_professional.userName
        : "Demanda Espontânea";
      let area = array[index].student.student_area_exceptions_attributes.area
        ? array[index].student.student_area_exceptions_attributes.area.name
        : "-";
      let equipment = array[index].student.equipment ? array[index].student.equipment.name : "-";
      let institutional_referral = array[index].student.institutional_referral
        ? array[index].student.institutional_referral.name
        : "-";
      let first_option_area_of_interest = array[index].student.first_option_area_of_interest
        ? array[index].student.first_option_area_of_interest.name
        : "-";
      let second_option_area_of_interest = array[index].student.second_option_area_of_interest
        ? array[index].student.second_option_area_of_interest.name
        : "-";
      let third_option_area_of_interest = array[index].student.third_option_area_of_interest
        ? array[index].student.third_option_area_of_interest.name
        : "-";
      let name = array[index].student.name;
      let social_name = array[index].student.social_name;
      let email = array[index].student.email;
      let team_code = "-";
      let presence_percent = "-";
      let count = 0;
      let last_presence = "-";
      let array_dates = [];
      if (array[index].student?.teams_attributes) {
        array[index].student.teams_attributes.forEach((team) => {
          if (team.last_presence.updated_at) {
            array_dates.push(team.last_presence.updated_at);
          }
        });
      }
      last_presence = array_dates.length > 0 ? util_function.getLastPresence(array_dates) : "-";
      if (array[index].student.teams_attributes && array[index].student.teams_attributes.length > 0) {
        if (array[index].student.teams_attributes.length === 1) {
          presence_percent = `${array[index].student.teams_attributes[0].presence_percent}%`;
          team_code = array[index].student.teams_attributes[0].code;
          count += 1;
        } else {
          array[index].student.teams_attributes.forEach((item) => {
            team_code += item.code + " ";
            count += 1;
            item.valid = true;
          });
          if (count >= 2) {
            array[index].student.teams_attributes.forEach((item) => {
              if (item.valid && item.discipline.id === 3) {
                presence_percent = `${item.presence_percent}%`;
              }
            });
          } else {
            array[index].student.teams_attributes.forEach((item) => {
              if (item.valid) {
                presence_percent = `${item.presence_percent}%`;
              }
            });
          }
        }
      } else {
        team_code = "Aluno(a) não alocado em turma";
      }
      let rg = array[index].student.rg;
      let cpf = array[index].student.cpf;
      let city_of_birth = array[index].student.city_of_birth;
      let whatsapp = array[index].student.whatszap;
      let birthdate = array[index].student.birthdate;
      let civil_state = array[index].student.civil_state != null ? array[index].student.civil_state.name : "";
      let gender = array[index].student.gender;
      let deficience = [];
      JSON.parse(JSON.stringify(array[index].student.deficiencies_attributes)).forEach((item) => {
        deficience.push(item.name);
      });
      let race = array[index].student.race.name;
      let cep = array[index].student.cep;
      let street = array[index].student.street;
      let street_number = array[index].student.street_number;
      let complement = array[index].student.complement;
      let community = array[index].student.community;
      let busy = "";
      switch (array[index].student.busy) {
        case false:
          busy = "Não";
          break;
        case true:
          busy = "Sim";
          break;
      }
      let studying = "";
      switch (array[index].student.studying) {
        case false:
          studying = "Não";
          break;
        case true:
          studying = "Sim";
          break;
      }
      let completed_series =
        array[index].student.completed_series != null ? array[index].student.completed_series.name : "";
      let height = array[index].student.height;
      let weight = array[index].student.weight;
      let shirt_size = array[index].student.shirt_size != null ? array[index].student.shirt_size.name : "";
      let pants_size = array[index].student.pants_size != null ? array[index].student.pants_size.name : "";
      let shoe_size1 = array[index].student.shoe_size1 != null ? array[index].student.shoe_size1.name : "";
      let contacted = "";
      switch (array[index].contacted) {
        case false:
          contacted = "Não";
          break;
        case true:
          contacted = "Sim";
          break;
      }
      let date_face_to_face_service = array[index].date_face_to_face_service;
      let cellphone_number = array[index].student.cellphone_number;
      let age = array[index].student.age;
      let neighborhood = array[index].student.neighborhood.name;
      let bank_name = array[index].student.bank_name != null ? array[index].student.bank_name.name : "";
      let agency = array[index].student.agency;
      let account = array[index].student.account;
      let account_digit = array[index].student.account_digit;
      let nis = array[index].student.nis ? array[index].student.nis : "";
      let educational_status = array[index].student.educational_status
        ? array[index].student.educational_status.name
        : "";
      let account_type =
        array[index].student.account_type != null ? array[index].student.account_type.name : "";
      let relative_degree_kinship = [];
      let relative_rg = [];
      let relative_cpf = [];
      let relative_cellphone_number = [];
      let relative_birth_date = [];
      let number_sons = 0;
      let mother_name = null;
      getRelativeDegreeKinship(array, index, relative_degree_kinship);
      let relative_name = [];
      JSON.parse(JSON.stringify(array[index].student.relatives_attributes)).forEach((item) => {
        relative_name.push(item.name);
        relative_rg.push(item.rg);
        relative_cpf.push(item.cpf);
        relative_cellphone_number.push(item.cellphone_number);
        relative_birth_date.push(item.birth_date);
        if (item.degree_kinship === "mom") {
          mother_name = item.name;
        }
      });
      relative_degree_kinship.forEach((item) => {
        if (item === "Filho" || item === "Filha") {
          number_sons++;
        }
      });
      let last_team = array[index].student.last_team ? array[index].student.last_team.code : null;
      let all_teams =
        array[index].student.all_teams && array[index].student.all_teams.length > 0
          ? array[index].student.all_teams
          : "-";
      let last_team_course =
        array[index].student.last_team && array[index].student.last_team.course
          ? array[index].student.last_team.course.name
          : "-";
      let qualification_status = array[index].student.qualification_status
        ? array[index].student.qualification_status.name
        : null;
      let forwarding_productive_by_area_management =
        array[index].student.productive_insertion.forwarding_productive_by_area_management === "0"
          ? "0"
          : array[index].student.productive_insertion.forwarding_productive_by_area_management;
      let forwarding_productive_by_area_teams =
        array[index].student.productive_insertion.forwarding_productive_by_area_teams === "0"
          ? "0"
          : array[index].student.productive_insertion.forwarding_productive_by_area_teams;
      let forwarding_productive_insertion_by_feedback_type =
        array[index].student.productive_insertion.forwarding_productive_insertion_by_feedback_type > 0
          ? "Sim"
          : "Não";
      let spontaneous_productive_insertion_count =
        array[index].student.productive_insertion.spontaneous_productive_insertion_count === "0"
          ? "0"
          : array[index].student.productive_insertion.spontaneous_productive_insertion_count;
      let is_pregnant = array[index].student.is_pregnant === true ? "Sim" : "Não";
      let month_pregnant = array[index].student.month_pregnant
        ? array[index].student.month_pregnant.name
        : "-";
      const arrayItem = new Array();
      arrayItem["feedback"] = feedback;
      arrayItem["created_at"] = created_at;
      arrayItem["responsible_professional"] = responsible_professional;
      arrayItem["status"] = status;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["name"] = name;
      arrayItem["social_name"] = social_name;
      arrayItem["team_code"] = team_code;
      arrayItem["presence_percent"] = presence_percent;
      arrayItem["last_presence"] = last_presence;
      arrayItem["rg"] = rg;
      arrayItem["cpf"] = cpf;
      arrayItem["city_of_birth"] = city_of_birth;
      arrayItem["whatsapp"] = whatsapp;
      arrayItem["birthdate"] = birthdate;
      arrayItem["civil_state"] = civil_state;
      arrayItem["gender"] = gender;
      arrayItem["deficience"] = deficience;
      arrayItem["race"] = race;
      arrayItem["cep"] = cep;
      arrayItem["street"] = street;
      arrayItem["street_number"] = street_number;
      arrayItem["complement"] = complement;
      arrayItem["community"] = community;
      arrayItem["busy"] = busy;
      arrayItem["studying"] = studying;
      arrayItem["completed_series"] = completed_series;
      arrayItem["height"] = height;
      arrayItem["weight"] = weight;
      arrayItem["shirt_size"] = shirt_size;
      arrayItem["pants_size"] = pants_size;
      arrayItem["shoe_size1"] = shoe_size1;
      arrayItem["contacted"] = contacted;
      arrayItem["date_face_to_face_service"] = date_face_to_face_service;
      arrayItem["email"] = email;
      arrayItem["cellphone_number"] = cellphone_number;
      arrayItem["age"] = age;
      arrayItem["neighborhood"] = neighborhood;
      arrayItem["mother_name"] = mother_name;
      arrayItem["relative_degree_kinship"] = relative_degree_kinship;
      arrayItem["relative_name"] = relative_name;
      arrayItem["relative_rg"] = relative_rg;
      arrayItem["relative_cpf"] = relative_cpf;
      arrayItem["relative_cellphone_number"] = relative_cellphone_number;
      arrayItem["relative_birth_date"] = relative_birth_date;
      arrayItem["number_sons"] = number_sons;
      arrayItem["bank_name"] = bank_name;
      arrayItem["agency"] = agency;
      arrayItem["account"] = account;
      arrayItem["account_digit"] = account_digit;
      arrayItem["account_type"] = account_type;
      arrayItem["institutional_referral"] = institutional_referral;
      arrayItem["educational_status"] = educational_status;
      arrayItem["nis"] = nis;
      arrayItem["last_team"] = last_team;
      arrayItem["all_teams"] = all_teams;
      arrayItem["last_team_course"] = last_team_course;
      arrayItem["qualification_status"] = qualification_status;
      arrayItem["forwarding_productive_by_area_management"] = forwarding_productive_by_area_management;
      arrayItem["forwarding_productive_by_area_teams"] = forwarding_productive_by_area_teams;
      arrayItem["forwarding_productive_insertion_by_feedback_type"] =
        forwarding_productive_insertion_by_feedback_type;
      arrayItem["spontaneous_productive_insertion_count"] = spontaneous_productive_insertion_count;
      arrayItem["is_pregnant"] = is_pregnant;
      arrayItem["month_pregnant"] = month_pregnant;
      arrayItem["first_option_area_of_interest"] = first_option_area_of_interest;
      arrayItem["second_option_area_of_interest"] = second_option_area_of_interest;
      arrayItem["third_option_area_of_interest"] = third_option_area_of_interest;
      arrayData.push(arrayItem);
    });
    state.json_data = arrayData;
    state.hasSpreadsheetResponse = true;
    state.modalToggleExportSeasonSpreadsheet = true;
  },
  setAllInscriptions: (state, allInscriptions) => {
    state.allInscriptions = allInscriptions;
  },
  setBodyTable: (state, bodyTable) => {
    state.bodyTable = bodyTable;
  },
  setBodyTableStudent: (state, bodyTableStudent) => {
    state.bodyTableStudent = bodyTableStudent;
  },
  setBodyTableStudentDisconnected: (state, bodyTableStudentDisconnected) => {
    state.bodyTableStudentDisconnected = bodyTableStudentDisconnected;
  },
  setAreas: (state, areas) => {
    state.areas = areas;
  },
  setAmountInscriptionOpen: (state, amountInscriptionOpen) => {
    state.amountInscriptionOpen = amountInscriptionOpen;
  },
  setInscriptionTotal: (state, inscriptionTotal) => {
    state.inscriptionTotal = inscriptionTotal;
  },
  setAmountInscriptionUnderAnalysis: (state, amountInscriptionUnderAnalysis) => {
    state.amountInscriptionUnderAnalysis = amountInscriptionUnderAnalysis;
  },
  setAmountInscriptionAccepted: (state, amountInscriptionAccepted) => {
    state.amountInscriptionAccepted = amountInscriptionAccepted;
  },
  setAmountInscriptionNotAccepted: (state, amountInscriptionNotAccepted) => {
    state.amountInscriptionNotAccepted = amountInscriptionNotAccepted;
  },
  setAmountInscriptionGraduate: (state, amountInscriptionGraduate) => {
    state.amountInscriptionGraduate = amountInscriptionGraduate;
  },
  setAmountInscriptionArchived: (state, amountInscriptionArchived) => {
    state.amountInscriptionArchived = amountInscriptionArchived;
  },
  setTotalPerPage: (state, totalPerPage) => {
    state.totalPerPage = totalPerPage;
  },
  setOffset: (state, offset) => {
    state.offset = offset;
  },
  setAreaMutation: (state, area) => {
    state.filter.area = area;
  },
  setFilterInscriptionMutation: (state, inscription) => {
    state.filter.inscription = inscription;
  },
  setNameMutation: (state, name) => {
    state.filter.name = name;
  },
  setCPFMutation: (state, cpf) => {
    state.filter.cpf = cpf;
  },
  setRGMutation: (state, rg) => {
    state.filter.rg = rg;
  },
  setNeighborhoodMutation: (state, neighborhood) => {
    state.filter.neighborhood = neighborhood;
  },
  setHasCpfMutation: (state, has_cpf) => {
    state.filter.has_cpf = has_cpf;
  },
  setHasRgMutation: (state, has_rg) => {
    state.filter.has_rg = has_rg;
  },
  setHasBankAccountMutation: (state, has_bank_account) => {
    state.filter.has_bank_account = has_bank_account;
  },
  setCityMutation: (state, city) => {
    state.filter.city = city;
  },
  setInscriptionOriginMutation: (state, inscription_origin) => {
    state.filter.inscription_origin = inscription_origin;
  },
  setEquipmentMutation: (state, equipment) => {
    state.filter.equipment = equipment;
  },
  setAreaOfInterestMutation: (state, area_of_interest) => {
    state.filter.area_of_interest = area_of_interest;
  },
  setInscriptionMenuNameMutation: (state, inscription_menu_name) => {
    state.params.inscription_menu_name = inscription_menu_name;
  },
  setPhasesMutation: (state, phases) => {
    state.phases = phases;
  },
  setPartialMutation: (state, partial) => {
    state.partial = partial;
  },
  setPhaseMutation: (state, phase) => {
    state.phase = phase;
  },
  toggleShowStudentDropdownMutation: (state) => {
    state.showStudentDropdown = !state.showStudentDropdown;
  },
  toggleModalTransferStudentOpenMutation: (state) => {
    state.modalTransferStudentOpen = !state.modalTransferStudentOpen;
  },
  toggleModalRemoveStudentFromTeamOpenMutation: (state) => {
    state.modalRemoveStudentFromTeamOpen = !state.modalRemoveStudentFromTeamOpen;
  },
  toggleModalRemoveStudentMutation: (state) => {
    state.modalConfirmationRemoveStudent = !state.modalConfirmationRemoveStudent;
  },
  setTypeTableMutation: (state, typeTable) => {
    state.typeTable = typeTable;
  },
  setFilterStudents: (state, filterStudents) => {
    state.filterStudents = filterStudents;
  },
  setFilterStudentsPreInscription: (state, filterStudentsPreInscription) => {
    state.filterStudentsPreInscription = filterStudentsPreInscription;
  },
  isLoadedData: (state, isLoadedData) => {
    state.isLoadedData = isLoadedData;
  },
  isLoadedTeam: (state, isLoadedTeam) => {
    state.isLoadedTeam = isLoadedTeam;
  },
  hasSpreadsheetResponseMutation: (state, hasSpreadsheetResponse) => {
    state.hasSpreadsheetResponse = hasSpreadsheetResponse;
  },
  setSeasonSpreadsheetMutation: (state, season_spreadsheet) => {
    state.season_spreadsheet = season_spreadsheet;
  },
  toggleModalExportSeasonSpreadsheet: (state) => {
    state.modalToggleExportSeasonSpreadsheet = !state.modalToggleExportSeasonSpreadsheet;
  },
  setLoadingDataSeason: (state, data_season) => {
    state.loading.data_season = data_season;
  },
  clearFilter: (state) => {
    state.filter.statusInscriptions = [];
    state.filter.area = null;
    state.filter.city = null;
    state.filter.inscription_origin = null;
    state.filter.discipline = null;
    state.filter.inscription = null;
    state.filter.rg = null;
    state.filter.cpf = null;
    state.filter.neighborhood = null;
    state.filter.equipment = null;
    state.filter.has_cpf = null;
    state.filter.has_rg = null;
    state.filter.has_bank_account = null;
    state.filter.area_of_interest = null;
  },
};

function getUserAreas(rootGetters, state) {
  let user_areas_ids =
    rootGetters["UtilFunctions/areaIds"] && rootGetters["UtilFunctions/areaIds"].length > 0
      ? rootGetters["UtilFunctions/areaIds"]
      : null;
  let area_filter = state.filter.area ? state.filter.area.id : null;
  return { user_areas_ids, area_filter };
}

function getFilterParamsToLoadDataSeason(area_filter, user_areas_ids, state) {
  return {
    area: area_filter ? area_filter : user_areas_ids,
    status: state.filter.statusInscriptions?.length > 0 ? state.filter.statusInscriptions : null,
    name: state.filter.inscription ? state.filter.inscription.name : null,
    cpf: state.filter.cpf,
    has_cpf: state.filter.has_cpf ? state.filter.has_cpf.id : null,
    city_id: state.filter.city ? state.filter.city.id : null,
    inscription_origin_id: state.filter.inscription_origin?.id,
    has_rg: state.filter.has_rg ? state.filter.has_rg.id : null,
    has_bank_account: state.filter.has_bank_account ? state.filter.has_bank_account.id : null,
    area_of_interest: state.filter.area_of_interest?.id,
    rg: state.filter.rg,
    neighborhood: state.filter.neighborhood,
    equipment_id: state.filter.equipment?.id,
    typetable: state.typeTable,
    offset: state.offset,
    perPage: state.totalPerPage,
    edition_id: state.season.id,
    by: "students.name",
    order: "asc",
  };
}

const actions = {
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  filterListByName: ({ commit }, payload) => {
    commit("setFilterInscriptionMutation", payload);
  },
  filterListByCPF: ({ commit }, payload) => {
    commit("setCPFMutation", payload.value);
  },
  filterListByRG: ({ commit }, payload) => {
    commit("setRGMutation", payload.value);
  },
  filterListByEquipment: ({ commit }, payload) => {
    commit("setEquipmentMutation", payload);
  },
  filterListByNeighborhood: ({ commit }, payload) => {
    commit("setNeighborhoodMutation", payload.value);
  },
  filterListByHasCpf: ({ commit }, payload) => {
    commit("setHasCpfMutation", payload);
  },
  filterListByHasRg: ({ commit }, payload) => {
    commit("setHasRgMutation", payload);
  },
  filterListByHasBankAccount: ({ commit }, payload) => {
    commit("setHasBankAccountMutation", payload);
  },
  filterListByAreaOfInterest: ({ commit }, payload) => {
    commit("setAreaOfInterestMutation", payload);
  },
  filterListByCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  filterListByInscriptionOrigin: ({ commit }, payload) => {
    commit("setInscriptionOriginMutation", payload);
  },
  filterInscription: ({ commit }, payload) => {
    let inscription = state.inscriptions.find((inscription) => inscription.id === payload.id);
    commit("filterInscriptionMutation", inscription);
  },
  filterTargetTeam: ({ commit }, payload) => {
    commit("filterTargetTeamMutation", payload);
  },
  filterSourceTeam: ({ commit }, payload) => {
    commit("filterSourceTeamMutation", payload);
  },
  filterTeamToRemove: ({ commit }, payload) => {
    commit("filterTeamToRemoveMutation", payload);
  },
  hasSourceTeams: ({ commit }, payload) => {
    commit("hasSourceTeamsMutation", payload);
  },
  hasTargetTeams: ({ commit }, payload) => {
    commit("hasTargetTeamsMutation", payload);
  },
  hasRemoveTeams: ({ commit }, payload) => {
    commit("hasRemoveTeamsMutation", payload);
  },
  openCloseEndSeasonConfirmation: ({ commit }) => {
    commit("openCloseEndSeasonConfirmationMutation");
  },
  openCloseReactivateSeasonConfirmation: ({ commit }) => {
    commit("openCloseReactivateSeasonConfirmationMutation");
  },
  openExportSeasonSpreadsheetModal: ({ commit }) => {
    commit("toggleModalExportSeasonSpreadsheet");
  },
  closeExportSeasonSpreadsheetModal: ({ commit, dispatch }) => {
    commit("toggleModalExportSeasonSpreadsheet");
    dispatch("SelectFieldsToExport/clearFields", null, { root: true });
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeason", payload);
  },
  setDisciplines: ({ commit }, payload) => {
    commit("setDisciplines", payload);
  },
  setFilterStatusInscriptions: ({ commit }, payload) => {
    commit("filterStatusInscriptions", payload.status);
  },
  setTypeTable: ({ commit }, payload) => {
    commit("setTypeTableMutation", payload);
  },
  setTotalPerPage: ({ commit }, payload) => {
    commit("setTotalPerPage", payload.totalPerPage);
  },
  setOffset: ({ commit }, payload) => {
    commit("setOffset", payload.offset);
  },
  setArea: ({ commit }, payload) => {
    commit("setAreaMutation", payload);
    if (!payload) {
      commit("setEquipmentsMutation", []);
      commit("setEquipmentMutation", null);
    }
  },
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload);
  },
  setPartial: ({ commit }, payload) => {
    commit("setPartialMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
  toggleShowStudentDropdown: ({ commit }) => {
    commit("toggleShowStudentDropdownMutation");
  },
  isLoadedData: ({ commit }, payload) => {
    commit("isLoadedData", payload);
  },
  setBodyTableStudent: ({ commit }, payload) => {
    commit("setBodyTableStudent", payload);
  },
  setPhase: ({ commit, dispatch }, payload) => {
    commit("isLoadedTeam", false);
    commit("Teams/setPhaseIdMutation", { id: payload.id, name: payload.name }, { root: true });
    if (payload) {
      commit("setPhaseMutation", payload);
      dispatch("Teams/setTabTypeTeamView", "teams_in_progress", { root: true });
      // const params = {
      //   season_id: state.season.id,
      //   phase_id: payload.id,
      //   perPage: rootState.Teams.totalPerPage,
      //   in_progress: rootState.Teams.tabTypeTeamView === "teams_in_progress",
      //   offset: rootState.Teams.offset,
      //   area_id: rootState.Teams.filter.areas_equipment ? rootState.Teams.filter.areas_equipment.id : null,
      //   code: rootState.Teams.filter.code ? rootState.Teams.filter.code : null,
      //   equipment: rootState.Teams.filter.equipment ? rootState.Teams.filter.equipment : null,
      //   course: rootState.Teams.filter.course ? rootState.Teams.filter.course : null,
      //   teacher: rootState.Teams.filter.teacher ? rootState.Teams.filter.teacher.id : null,
      // };
      // getTeamsBySeason(params)
      //   .then((response) => {
      //     dispatch("Teams/setTeamsTotal", response.data.teams_count, { root: true });
      //     dispatch("Teams/setAll", response.data.teams, { root: true });
      //     commit("isLoadedTeam", true);
      //   })
      //   .catch((e) => {
      //     console.log("error", e.response); //TODO: tratar esse error
      //   });

      commit("setPartialMutation", payload.name);
    }
  },
  // getTeamsBySeasonExport: ({ dispatch }, payload) => {
  //   if (payload) {
  //     const params = {
  //       season_id: payload.season.id,
  //       phase_id: payload.phase.id,
  //     };
  //     getTeamsBySeasonExport(params)
  //       .then((response) => {
  //         console.log("response", response);
  //         dispatch("Teams/setTeamsToExportPresencesMutation", response.data.teams, { root: true });
  //       })
  //       .catch((e) => {
  //         console.log("error", e.response); //TODO: tratar esse error
  //       });
  //   }
  // },
  openEditModalInscription: ({ dispatch, state }, payload = {}) => {
    let inscription = state.inscriptions.find((inscription) => inscription.id === payload.id);
    dispatch("Inscription/load", inscription, { root: true });
    dispatch("ModalInscription/setStudent", inscription.student, { root: true });
    dispatch("ModalInscription/openModal", payload, { root: true });
    dispatch("ModalInscription/updateAllIdDocumentStudent", inscription.student.id, { root: true });
  },
  openStudentDropdown: ({ dispatch, state, commit }, payload = {}) => {
    let inscription = state.inscriptions.find((inscription) => inscription.id === payload.id);
    dispatch("Inscription/load", inscription, { root: true });
    commit("filterInscriptionMutation", inscription);
    commit("toggleShowStudentDropdownMutation");
  },
  openModalTransferStudentOpen: ({ dispatch, state, commit }, payload = {}) => {
    let inscription = state.inscriptions.find((inscription) => inscription.id === payload.id);
    if (payload.student.teams_attributes.length > 0) {
      commit("setInscriptionStudentTeamsAttributes", payload.student.teams_attributes);
    }
    dispatch("Inscription/load", inscription, { root: true });
    commit("filterInscriptionMutation", inscription);
    commit("toggleModalTransferStudentOpenMutation");
  },
  closeModalTransferStudentOpen: ({ commit }) => {
    commit("toggleModalTransferStudentOpenMutation");
    commit("hasSourceTeamsMutation", 0);
    commit("hasTargetTeamsMutation", 0);
    commit("hasRemoveTeamsMutation", 0);
    let payload = {
      disciplines: [],
    };
    commit("setPhaseMutation", payload);
    commit("setPartialMutation", "inscriptions");
  },
  openModalRemoveStudentFromTeamOpen: ({ dispatch, state, commit }, payload = {}) => {
    let inscription = state.inscriptions.find((inscription) => inscription.id === payload.id);
    if (payload.student.teams_attributes.length > 0) {
      commit("setInscriptionStudentTeamsAttributes", payload.student.teams_attributes);
    }
    dispatch("Inscription/load", inscription, { root: true });
    commit("filterInscriptionMutation", inscription);
    commit("toggleModalRemoveStudentFromTeamOpenMutation");
  },
  closeModalRemoveStudentFromTeamOpen: ({ commit }) => {
    commit("toggleModalRemoveStudentFromTeamOpenMutation");
  },
  toggleModalRemoveStudent: ({ commit }) => {
    commit("toggleModalRemoveStudentMutation");
  },
  loadSeason: ({ state, commit }, payload) => {
    const id = payload.id ? payload.id : state.season.id;
    getSeason(id)
      .then((response) => {
        commit("setSeason", response.data.season);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadInscriptionsForGeneralMenus: ({ commit, rootGetters }, value) => {
    let { user_areas_ids, area_filter } = getUserAreas(rootGetters, state);
    const params = {
      participant_name: value,
      area_id: area_filter && area_filter.length > 0 ? area_filter : user_areas_ids,
      season_id: state.season.id,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForGeneralMenus(params)
      .then((response) => {
        commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadDataSeason: ({ commit, getters, state, rootGetters }) => {
    commit("setLoadingDataSeason", true);
    commit("setTypeTableMutation", window.location.href);
    let { user_areas_ids, area_filter } = getUserAreas(rootGetters, state);

    const filters = getFilterParamsToLoadDataSeason(area_filter, user_areas_ids, state);
    if (state.season.id) {
      getInscriptionsBySeasonId(filters)
        .then((response) => {
          let season = response.data.season;
          commit("setSeason", season);
          commit("setInscriptions", season.inscriptions);
          commit("setInscriptionTotal", season.inscriptions_total);
          commit("setBodyTable", getters.mountBodyTable);
          commit("setBodyTableStudent", getters.mountBodyTableStudent);
          commit("setBodyTableStudentDisconnected", getters.mountBodyTableStudentDisconnected);
          commit("setAreas", season.areas_attributes);
          commit("setAmountInscriptionOpen", season.total_inscriptions_open);
          commit("setAmountInscriptionUnderAnalysis", season.total_inscriptions_under_analysis);
          commit("setAmountInscriptionAccepted", season.total_inscriptions_accepted);
          commit("setAmountInscriptionNotAccepted", season.total_inscriptions_not_accepted);
          commit("setAmountInscriptionGraduate", season.total_inscriptions_graduate);
          commit("setAmountInscriptionArchived", season.total_inscriptions_archived);
          commit("setFilterStudents", getters.mountBodyTableStudent);
          commit("setFilterStudentsPreInscription", getters.mountBodyTable);
          commit("setLoadingDataSeason", false);
        })
        .catch((e) => {
          console.log("error", e); //TODO: tratar esse error
          commit("setLoadingDataSeason", false);
        });
    }
  },
  loadDataSeasonToExport: async ({ commit, state, rootGetters }) => {
    commit("hasSpreadsheetResponseMutation", false);

    commit("setTypeTableMutation", window.location.href);
    let { user_areas_ids, area_filter } = getUserAreas(rootGetters, state);

    const filters = getFilterParamsToLoadDataSeason(area_filter, user_areas_ids, state);
    if (state.season.id) {
      await getInscriptionsBySeasonIdToExport(filters)
        .then(async (response) => {
          commit("setInscriptionsSpreadsheet", response.data.season.inscriptionsToExport);
          if (state.inscriptionsForSpreadsheet.length > 0) {
            commit("setInscriptionsToExport", response.data.season.inscriptionsToExport);
          }
        })
        .catch((e) => {
          console.log("error", e); //TODO: tratar esse error
        });
    }
  },
  loadEquipments: ({ commit }) => {
    const params = {
      city_id: localStorage?.city_id,
      area_id: state?.filter?.area?.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsMutation", equipments);
        commit("setEquipmentMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  endSeason: ({ state, dispatch }) => {
    const id = state.season.id;
    endSeason(id)
      .then(() => {
        dispatch("openCloseEndSeasonConfirmation");
        router.push({ name: "seasons", params: { status: "ativa" } });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  reactivateSeason: ({ state, dispatch }) => {
    const id = state.season.id;
    reactivateSeason(id)
      .then(() => {
        dispatch("openCloseReactivateSeasonConfirmation");
        router.push({ name: "seasons", params: { status: "ativa" } });
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadPhasesSeason: ({ commit, state }) => {
    getPhasesBySeasonId(state.season.id)
      .then((response) => {
        commit("setPhasesMutation", response.data.phases);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  clearFilter: ({ commit }) => {
    commit("clearFilter");
  },
};

const getters = {
  mountBodyTable: (state) => {
    let rows = [];
    state.inscriptions.forEach((inscription) => {
      let named = inscription.student.social_name
        ? inscription.student.name + " - " + inscription.student.social_name
        : inscription.student.name;
      let area = inscription.student.neighborhood.area
        ? inscription.student.neighborhood.area.name
        : "Sem Área";
      let manual_area = inscription.student.student_area_exceptions_attributes.area
        ? inscription.student.student_area_exceptions_attributes.area.name
        : "-";
      let cpf = inscription.student.cpf ? inscription.student.cpf : false;
      let equipment_named = inscription.student.equipment
        ? inscription.student.equipment.name
        : "Sem equipamento vinculado";
      let status = util_function.processInscriptionStatuses(inscription.status);
      let team_code = "";
      let count = 0;
      let percent = null;
      let array_dates = [];
      if (inscription.student?.teams_attributes) {
        inscription.student.teams_attributes.forEach((team) => {
          if (team.last_presence.updated_at) {
            array_dates.push(team.last_presence.updated_at);
          }
        });
      }
      let last_presence = array_dates.length > 0 ? util_function.getLastPresence(array_dates) : "-";
      if (inscription.student.teams_attributes) {
        if (inscription.student.teams_attributes.length > 0) {
          if (inscription.student.teams_attributes.length === 1) {
            percent = `${inscription.student.teams_attributes[0].presence_percent}%`;
            team_code = inscription.student.teams_attributes[0].code;
            count += 1;
          } else {
            inscription.student.teams_attributes.forEach((item) => {
              team_code += item.code + " ";
              count += 1;
              item.valid = true;
            });
            if (count >= 2) {
              inscription.student.teams_attributes.forEach((item) => {
                if (item.valid && item.discipline.id === 3) {
                  percent = `${item.presence_percent}%`;
                }
              });
            } else {
              inscription.student.teams_attributes.forEach((item) => {
                if (item.valid) {
                  percent = `${item.presence_percent}%`;
                }
              });
            }
          }
        } else {
          team_code = "Aluno(a) não alocado em turma";
        }
      } else {
        team_code = "Aluno(a) não alocado em turma";
      }
      let row = {
        id: inscription.id,
        student_name: {
          items: [
            { isImage: true, name: "jogador-1.png" },
            { isLabel: true, text: named, weight: "semibold" },
          ],
        },
        phone_number: {
          items: [{ isLabel: true, text: inscription.student.cellphone_number }],
        },
        cpf: {
          items: [{ isLabel: true, text: cpf }],
        },
        rg: {
          items: [{ isLabel: true, text: inscription.student.rg }],
        },
        equipment: {
          items: [{ isLabel: true, text: equipment_named }],
        },
        age: {
          items: [{ isLabel: true, text: inscription.student.age + "" }],
        },
        neighborhood: {
          items: [{ isLabel: true, text: inscription.student.neighborhood.name }],
        },
        area: {
          items: [{ isLabel: true, text: area }],
        },
        manual_area: {
          items: [{ isLabel: true, text: manual_area }],
        },
        status: {
          items: [{ isLabel: true, text: status }],
        },
        team_code: {
          items: [{ isLabel: true, text: team_code }],
        },
        percent: {
          items: [{ isLabel: true, text: percent !== null ? percent : "-" }],
        },
        last_presence: {
          items: [{ isLabel: true, text: last_presence }],
        },
        actionButton: {
          items: [
            {
              payload: { id: inscription.id },
              dispatch: "Season/openEditModalInscription",
              isButton: true,
              iconName: "visibility",
              styleButton: "success",
              showIcon: true,
              label: " ",
              classCss: "w-15",
              typeText: "button-uppercase",
            },
          ],
        },
        actionTransfer: {
          items: [
            {
              payload: inscription,
              dispatch: "Season/openModalTransferStudentOpen",
              isButton: count > 0 && localStorage.getItem("season_date_manual_execution_end") === "null",
              showIcon: true,
              iconName: "autorenew",
              showButton: count > 0 && localStorage.getItem("season_date_manual_execution_end") === "null",
              label: " ",
              styleButton: "primary",
              typeText: "custom",
              classCss: "w-25",
            },
          ],
        },
        actionRemove: {
          items: [
            {
              payload: inscription,
              dispatch: "Season/openModalRemoveStudentFromTeamOpen",
              isButton: count > 0 && localStorage.getItem("season_date_manual_execution_end") === "null",
              showIcon: true,
              iconName: "unpublished",
              showButton: count > 0 && localStorage.getItem("season_date_manual_execution_end") === "null",
              label: " ",
              styleButton: "danger",
              typeText: "custom",
              classCss: "w-20",
            },
          ],
        },
        actionEdition: {
          items: [{ isButtonIcon: true, iconName: "edit" }],
        },
      };

      rows.push(row);
    });

    return rows;
  },
  mountBodyTableStudent: (state) => {
    let rows = [];
    state.inscriptions.forEach((inscription) => {
      // let isActive = inscription.active === true ? "Ativo" : "Desligado";
      let area = inscription.student.neighborhood.area
        ? inscription.student.neighborhood.area.name
        : "Sem Área";
      let team_code = "";
      let count = 0;
      let percent = null;
      let status = util_function.processInscriptionStatuses(inscription.status);
      let phaseName = inscription.student.teams_attributes
        ? inscription.student.teams_attributes.length > 0
          ? inscription.student.teams_attributes[0].discipline
            ? inscription.student.teams_attributes[0].discipline.phase
              ? inscription.student.teams_attributes[0].discipline.phase.name
              : "Nenhuma Fase Ativa"
            : "Nenhuma Fase Ativa"
          : "Nenhuma Fase Ativa"
        : "Nenhuma Fase";

      let discipline = inscription.student.teams_attributes
        ? inscription.student.teams_attributes.length > 0
          ? inscription.student.teams_attributes[0].discipline
            ? inscription.student.teams_attributes[0].discipline.name
            : "Aluno(a) não alocado em módulo"
          : "Aluno(a) não alocado em módulo"
        : "Nenhuma disciplina";

      let active = inscription.student.teams_attributes
        ? inscription.student.teams_attributes.length > 0
          ? inscription.student.teams_attributes[0].presence_percent < 25
            ? "Em risco de evasão"
            : "-"
          : "-"
        : "Sem turmas";

      let equipment_named = inscription.student.equipment
        ? inscription.student.equipment.name
        : "Sem equipamento vinculado";

      let cpf = inscription.student.cpf ? inscription.student.cpf : false;

      if (inscription.student.teams_attributes) {
        if (inscription.student.teams_attributes.length > 0) {
          if (inscription.student.teams_attributes.length === 1) {
            percent = `${inscription.student.teams_attributes[0].presence_percent}%`;
            team_code = inscription.student.teams_attributes[0].code;
            count += 1;
          } else {
            inscription.student.teams_attributes.forEach((item) => {
              team_code += item.code + " ";
              count += 1;
              item.valid = true;
            });
            if (count >= 2) {
              inscription.student.teams_attributes.forEach((item) => {
                if (item.valid && item.discipline.id === 3) {
                  percent = `${item.presence_percent}%`;
                }
              });
            } else {
              inscription.student.teams_attributes.forEach((item) => {
                if (item.valid) {
                  percent = `${item.presence_percent}%`;
                }
              });
            }
          }
        } else {
          team_code = "Aluno(a) não alocado em turma";
        }
      } else {
        team_code = "Aluno(a) não alocado em turma";
      }

      let isPercent = percent != null && percent < 25;
      let row = {
        id: inscription.id,
        student_name: {
          items: [
            { isImage: true, name: "jogador-1.png" },
            { isLabel: true, text: inscription.student.name, weight: "semibold" },
          ],
        },
        phone_number: {
          items: [{ isLabel: true, text: inscription.student.cellphone_number }],
        },
        age: {
          items: [{ isLabel: true, text: inscription.student.age + "" }],
        },
        cpf: {
          items: [{ isLabel: true, text: cpf }],
        },
        rg: {
          items: [{ isLabel: true, text: inscription.student.rg }],
        },
        neighborhood: {
          items: [{ isLabel: true, text: inscription.student.neighborhood.name }],
        },
        team_code: {
          items: [{ isLabel: true, text: team_code }],
        },
        evasao: {
          items: [{ isLabel: true, text: active }],
        },
        equipment: {
          items: [{ isLabel: true, text: equipment_named }],
        },
        percent: {
          items: [{ isLabel: true, text: percent !== null ? percent : "-" }],
        },
        evadido: {
          items: [{ isButtonIcon: isPercent, iconName: "warning" }],
        },
        phaseName: {
          items: [{ text: phaseName }],
        },
        discipline: {
          items: [{ text: discipline }],
        },
        status: {
          items: [{ isLabel: true, text: status }],
        },
        // isActive: {
        //   items: [{ isLabel: true, text: isActive }],
        // },
        area: {
          items: [{ isLabel: true, text: area }],
        },
        actionButton: {
          items: [
            {
              payload: { id: inscription.id },
              dispatch: "Season/openEditModalInscription",
              isButton: true,
              iconName: "visibility",
              styleButton: "success",
              showIcon: true,
              label: " ",
              classCss: "w-15",
              typeText: "button-uppercase",
            },
          ],
        },
        actionTransfer: {
          items: [
            {
              payload: inscription,
              dispatch: "Season/openModalTransferStudentOpen",
              isButton: true,
              showIcon: true,
              iconName: "autorenew",
              showButton: count > 0 && localStorage.getItem("season_date_manual_execution_end") === "null",
              label: " ",
              styleButton: "primary",
              typeText: "custom",
              classCss: "w-25",
            },
          ],
        },
        actionRemove: {
          items: [
            {
              payload: inscription,
              dispatch: "Season/openModalRemoveStudentFromTeamOpen",
              isButton: true,
              showIcon: true,
              iconName: "unpublished",
              showButton: count > 0 && localStorage.getItem("season_date_manual_execution_end") === "null",
              label: " ",
              styleButton: "danger",
              typeText: "custom",
              classCss: "w-20",
            },
          ],
        },
      };
      rows.push(row);
    });
    return rows;
  },
  mountBodyTableStudentDisconnected: (state, getters) => {
    let rows = [];
    state.inscriptions.forEach((inscription) => {
      let phaseName = inscription.student.teams_attributes
        ? inscription.student.teams_attributes.length > 0
          ? inscription.student.teams_attributes[0].discipline
            ? inscription.student.teams_attributes[0].discipline.phase
              ? inscription.student.teams_attributes[0].discipline.phase.name
              : "Nenhuma Fase Ativa"
            : "Nenhuma Fase Ativa"
          : "Nenhuma Fase Ativa"
        : "Nenhuma Fase";
      let discipline = inscription.student.teams_attributes
        ? inscription.student.teams_attributes.length > 0
          ? inscription.student.teams_attributes[0].discipline
            ? inscription.student.teams_attributes[0].discipline.name
            : "Aluno(a) não alocado em módulo"
          : "Aluno(a) não alocado em módulo"
        : "Nenhuma disciplina";
      let cpf = inscription.student.cpf ? inscription.student.cpf : false;
      let team_code = inscription.student.teams_attributes
        ? inscription.student.teams_attributes.length > 0
          ? inscription.student.teams_attributes[0].code
          : "Aluno(a) não alocado em turma"
        : "Sem turmas";
      let row = {
        id: inscription.id,
        student_name: {
          items: [
            { isImage: true, name: "jogador-1.png" },
            { isLabel: true, text: inscription.student.name, weight: "semibold" },
          ],
        },
        phone_number: {
          items: [{ isLabel: true, text: inscription.student.cellphone_number }],
        },
        cpf: {
          items: [{ isLabel: true, text: cpf }],
        },
        team_code: {
          items: [{ isLabel: true, text: team_code }],
        },
        phaseName: {
          items: [{ text: phaseName }],
        },
        discipline: {
          items: [{ text: discipline }],
        },
        actionButton: {
          items: [
            {
              payload: { id: inscription.id },
              dispatch: "Season/openEditModalInscription",
              isButton: true,
              styleButton: getters.classActionButtonStudent(inscription.status),
              label: getters.labelActionButton(inscription.status),
              typeText: "button-uppercase",
              classCss: "w-60",
            },
          ],
        },
        actionDropdown: {
          items: [
            {
              payload: { id: inscription.id },
              dispatch: "Season/openStudentDropdown",
              isButton: true,
              styleButton: "dropdown",
              iconName: "more_vert",
              showIcon: true,
              label: "",
              classCss: "w-1",
            },
          ],
        },
      };
      rows.push(row);
    });
    return rows;
  },
  classActionButton: () => (status) => {
    switch (status) {
      case "open":
        return "success";
      case "under_analysis" || "disconnected":
        return "success";
      case "accepted":
        return "primary";
      case "graduate":
        return "primary";
      case "archived":
        return "primary";
      case "not_accepted":
        return "primary";
    }
  },
  classActionButtonStudent: () => (status) => {
    switch (status) {
      case "accepted":
        return "success";
    }
  },
  labelActionButton: () => (status) => {
    switch (status) {
      case "open":
        return "abrir atendimento";
      default:
        return "visualizar";
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
