var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c("div", { staticClass: "font-system" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
              },
              [_vm._v("Reinserção Escolar")]
            ),
            _vm.inscriptions.length > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-ashes-hover font-medium text-xl mx-2 col-span-2",
                  },
                  [_vm._v(" " + _vm._s(_vm.getTotalRegisters) + " ")]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "w-52" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "select-placeholder": "Selecionar edição",
                    "multiple-option": false,
                    value: _vm.season,
                    options: _vm.seasons,
                    "label-text": "Edição",
                    dispatch: _vm.setSeason,
                    required: "",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm.season
          ? _c(
              "div",
              {
                staticClass: "mx-16 flex",
                attrs: { id: "menus_school_reinsertion_main_page" },
              },
              [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("notReinserted")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Público da Reinserção Escolar",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorNotReinserted,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "border-l mx-10" }),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("reinserted")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Reinseridos",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorReinserted,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "border-l mx-10" }),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("vacancy_request")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Solicitações de Vagas",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorVacancyRequest,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _c("div", { attrs: { id: "list-school-reinsertions" } }, [
          _c(
            "div",
            { staticClass: "mx-12" },
            [
              _c("NotReinsertedsMainPage", {
                attrs: {
                  data: _vm.inscriptions,
                  open: _vm.toggleSchoolReinsertionNotReinserted,
                },
              }),
              _c("ReinsertedsMainPage", {
                attrs: {
                  data: _vm.inscriptions,
                  open: _vm.toggleSchoolReinsertionReinserted,
                },
              }),
              _c("PlannedAwarenessActivityMainPage", {
                attrs: {
                  data: _vm.inscriptions,
                  open: _vm.toggleSchoolReinsertionPlannedAwarenessActivity,
                },
              }),
              _c("VacancyRequest", {
                attrs: {
                  open: _vm.toggleSchoolReinsertionNotReinsertedVacancyRequest,
                },
              }),
            ],
            1
          ),
        ]),
        _vm.loading_inscriptions || _vm.load_vacancy_requests
          ? _c("div", [_c("PreLoading")], 1)
          : _vm._e(),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }