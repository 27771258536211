var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.team.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar turma",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar turma",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: { dispatch: "Team/closeModal", forClose: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-rols-7 gap-5" }, [
                    _c("div", { staticClass: "grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: "informações gerais",
                              typeText: "title-forms",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "border border-ashes-hover w-full",
                      }),
                    ]),
                    _c("div", { staticClass: "grid grid-flow-col gap-5" }, [
                      _c(
                        "div",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: _vm.season.name,
                              typeText: "custom",
                              weight: "medium",
                              mode: "capitalize",
                              size: "lg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: _vm.phase.name,
                              typeText: "custom",
                              weight: "medium",
                              mode: "capitalize",
                              size: "lg",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid grid-cols-2 gap-5" }, [
                      false
                        ? _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  id: "select-season",
                                  "show-label": "",
                                  "close-on-select": "",
                                  "select-placeholder": "Selecione as opções",
                                  "label-text": "Edições",
                                  required: "",
                                  "clear-on-select": false,
                                  "multiple-option": false,
                                  value: _vm.season,
                                  options: _vm.seasons,
                                  dispatch: _vm.setSeason,
                                  "error-message":
                                    _vm.getErrorMessage("season"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  id: "select-phase",
                                  "show-label": "",
                                  "close-on-select": "",
                                  "select-placeholder": "Selecione as opções",
                                  "label-text": "Fases",
                                  required: "",
                                  "clear-on-select": false,
                                  "multiple-option": false,
                                  value: _vm.team.discipline,
                                  options: _vm.phases,
                                  dispatch: _vm.setDiscipline,
                                  "error-message":
                                    _vm.getErrorMessage("discipline"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              id: "select-module",
                              "show-label": "",
                              "close-on-select": "",
                              "select-placeholder": "Selecione as opções",
                              "label-text": "Módulos",
                              required: "",
                              "clear-on-select": false,
                              "multiple-option": false,
                              value: _vm.team.discipline,
                              options: _vm.getOnlyDisciplineEnableForAllocate(),
                              dispatch: _vm.setDiscipline,
                              "error-message":
                                _vm.getErrorMessage("discipline"),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.phase.id == 2
                        ? _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  id: "select-module",
                                  "show-label": "",
                                  "close-on-select": "",
                                  "select-placeholder": "Selecione as opções",
                                  "label-text": "Curso",
                                  required: "",
                                  "clear-on-select": false,
                                  "multiple-option": false,
                                  value: _vm.team.course,
                                  options: _vm.courses,
                                  dispatch: _vm.setCourse,
                                  "error-message":
                                    _vm.getErrorMessage("course"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "grid grid-cols-2 gap-5" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "start",
                              active: "",
                              "input-name": "start",
                              inputType: "date",
                              value: _vm.formatDate(_vm.team.start),
                              required: "",
                              "label-text": "Início",
                              "show-label": "",
                              "error-message": _vm.getErrorMessage("start"),
                              dispatch: "Team/setStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "closing",
                              active: "",
                              "input-name": "closing",
                              inputType: "date",
                              required: "",
                              value: _vm.formatDate(_vm.team.closing),
                              "label-text": "Encerramento",
                              "show-label": "",
                              dispatch: "Team/setClosing",
                              "error-message": _vm.getErrorMessage("closing"),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c("AppCheckBox", {
                          attrs: {
                            values: [
                              {
                                name: "semana",
                                value: "monday",
                                description: "Segunda",
                                checked: _vm.team.monday,
                              },
                              {
                                name: "semana",
                                value: "tuesday",
                                description: "Terça",
                                checked: _vm.team.tuesday,
                              },
                              {
                                name: "semana",
                                value: "wednesday",
                                description: "Quarta",
                                checked: _vm.team.wednesday,
                              },
                              {
                                name: "semana",
                                value: "thursday",
                                description: "Quinta",
                                checked: _vm.team.thursday,
                              },
                              {
                                name: "semana",
                                value: "friday",
                                description: "Sexta",
                                checked: _vm.team.friday,
                              },
                            ],
                            required: "",
                            "error-message":
                              _vm.getErrorMessage("weekly_meetings"),
                            "label-text": "Encontros na semana",
                            "show-label": "",
                            dispatch: "Team/setDayWeek",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "grid grid-cols-2 gap-5" }, [
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              "close-on-select": "",
                              "show-label": "",
                              "select-placeholder": "Selecione a opção",
                              "label-text": "Turno",
                              "multiple-option": false,
                              value: _vm.team.shift,
                              options: _vm.shifts,
                              "error-message": _vm.getErrorMessage("shift"),
                              required: "",
                              dispatch: _vm.setShift,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              "close-on-select": "",
                              "show-label": "",
                              "select-placeholder": "Selecione a opção",
                              "label-text": "Equipamento",
                              "multiple-option": false,
                              value: _vm.team.equipment,
                              options: _vm.equipmentAll,
                              "error-message": _vm.getErrorMessage("equipment"),
                              required: "",
                              dispatch: _vm.setEquipment,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "grid grid-cols-2 gap-5" }, [
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              "close-on-select": "",
                              "show-label": "",
                              "select-placeholder": "Selecione a opção",
                              "label-text": "Instrutor",
                              "multiple-option": false,
                              value: _vm.team.teacher,
                              options: _vm.teachers,
                              "error-message": _vm.getErrorMessage("teacher"),
                              required: "",
                              dispatch: _vm.setTeacher,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              active: "",
                              "input-name": "Vagas",
                              required: "",
                              "label-text": "Vagas",
                              "show-label": "",
                              value: String(_vm.team.vacancy),
                              "error-message": _vm.getErrorMessage("vacancy"),
                              "input-type": "number",
                              dispatch: "Team/setVacancy",
                              inputMin: "1",
                              inputMax: "1000000",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Team/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.team.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Editar",
                                typeButton: "success",
                                dispatch: "Team/updateTeam",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "Team/saveTeam",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          511228247
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }