var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "loader-container",
      style: { backgroundColor: _vm.bg, opacity: _vm.opacity / 100 },
    },
    [
      _c("loader", {
        attrs: {
          object: _vm.object,
          color1: _vm.color1,
          color2: _vm.color2,
          size: _vm.size,
          speed: _vm.speed,
          objectbg: _vm.objectbg,
          name: _vm.name,
        },
      }),
      _vm.text
        ? _c(
            "p",
            { staticClass: "loader-text", style: { color: _vm.textColor } },
            [_vm._v(_vm._s(_vm.animatedText))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }