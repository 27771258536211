var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      { staticClass: "menu-toggle", on: { click: _vm.toggleSidebar } },
      [
        _c("span", { staticClass: "bar" }),
        _c("span", { staticClass: "bar" }),
        _c("span", { staticClass: "bar" }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "view-sidebar",
        class: { "is-active": _vm.isSidebarActive || _vm.isDesktop },
      },
      [_c("SideBar")],
      1
    ),
    _c("div", { staticClass: "view-topbar" }, [_c("TopBar")], 1),
    _c(
      "div",
      { staticClass: "view-container" },
      [_c("router-view", { staticClass: "view" })],
      1
    ),
    !this.finished_after_login ? _c("div", [_c("PreLoading")], 1) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }