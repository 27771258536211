import { getSeasonsForMenus } from "../../service/SeasonApi";
import { getEquipmentByAreaId } from "../../service/EquipmentApi";
import {
  getInscriptionByIdAndResource,
  getInscriptionBySeasonAndArea,
  getInscriptionForGeneralMenus,
  getInscriptionsForPsychosocialAccompanimentsSpreadsheet,
} from "../../service/InscriptionApi";
import {
  createPsychosocialAccompaniment,
  destroyPsychosocialAccompaniment,
  getIdentityGendersOptions,
  getLastService,
  getPsychosocialAccompanimentById,
  getPsychosocialAccompanimentByInscription,
  getPsychosocialAccompanimentByInscriptionSpreadsheet,
  getPsychosocialAccompanimentsStatus,
  getPsychosocialAccompanimentsTypes,
  getPsychosocialGenders,
  getPsychosocialHousingBathroomDrainTypes,
  getPsychosocialHousingBuildingMaterials,
  getPsychosocialHousingBuildingSituations,
  getPsychosocialHousingElectricities,
  getPsychosocialHousingGasSupplies,
  getPsychosocialHousingWaterSupplies,
  getPsychosocialMeetingNotHappenedReasons,
  getPsychosocialSocioEducationalMeasures,
  updatePsychosocialAccompaniment,
} from "../../service/PsychosocialAccompanimentsApi";
import { getHousingSituations } from "../../service/HousingSituationsApi";
import { createForwarding, updateForwarding } from "../../service/ForwardingsApi";
import { getFacilitiesByForwardingPolicy } from "../../service/FacilitiesApi";
import { getPsychosocialAttendantsForPsychosocial } from "../../service/AtendentPsychosocialApi";
import { createAttendantTeam, updateAttendantTeam } from "../../service/AttendantTeamsApi";
import {
  createParticipantPresence,
  getRelationshipDegrees,
  updateParticipantPresence,
} from "../../service/ParticipantPresencesApi";
import { getUsersForPsychosocial } from "../../service/UsersApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { getInstitutionalReferrals } from "@/service/StudentApi";
import router from "@/router";
import { util_function } from "@/util_functions";

Vue.component("downloadExcel", JsonExcel);

const state = {
  psychosocial_accompaniment: {
    id: null,
    inscription_id: null,
    accompaniment_status: null,
    accompaniment_type: null,
    date_accompaniment: null,
    accompaniment_comments: "",
    meeting_happened: false,
    meeting_not_happened_reason: null,
    meeting_format: null,
    meeting_place: null,
    meeting_remote_resource: null,
    meeting_conclusion_description: "",
    housing_situation_id: null,
    housing_situation_comments: "",
    housing_has_bathroom: false,
    housing_has_garbage_collection: false,
    housing_has_latrine: false,
    housing_building_situation: null,
    housing_building_material: null,
    housing_has_electricity: null,
    housing_has_water_supply: null,
    housing_has_gas_supply: null,
    housing_bathroom_drain_type: null,
    socio_educational_measure_situation: null,
    is_half_open_socio_educational_measure: false,
    is_half_closed_socio_educational_measure: false,
    sentence_serving_situation: null,
    is_half_open_sentence_serving_situation: false,
    is_half_closed_sentence_serving_situation: false,
    obey_socio_educational_measure: false,
    obeyed_socio_educational_measure: false,
    is_studying: false,
    reason_for_not_studying: "",
    reason_for_dropout_study: "",
    last_grade_studied: "",
    last_school_studied: "",
    need_school_reinsertion: false,
    is_pregnant: false,
    gestational_age: null,
    is_puerperium: false,
    frequently_uses_health_network: false,
    health_place_frequently: "",
    motivation_uses_health_frequently: "",
    accompaniment_will_continue: false,
    accompaniment_guideline: "",
    spontaneous_attendance: false,
    user_id: null,
    is_young_father: false,
    is_young_mother: false,
    purpose_home_visit: null,
    social_family_context: null,
    social_economic_context: null,
    community_coexistence: null,
    number_of_residents: 0,
    gender: null,
    gender_identity_observations: null,
    identifies_as_lgbtqiapn: null,
    uses_psychoactive_substances: false,
    uses_psychoactive_substances_recreationally: false,
    self_medicate: false,
    show_signs_of_self_harm: false,
    show_signs_of_compromised_physical_health: false,
    show_signs_of_compromised_mental_health: false,
    health_situation_observations: null,
    justification_for_non_acceptance: null,
    justification_for_non_acceptance_date: null,
    disciplinary_sanctions_attributes: [],
  },
  continued_accompaniment: {
    id: null,
    inscription_id: null,
    accompaniment_status: null,
    accompaniment_type: null,
    date_accompaniment: null,
    accompaniment_origin_id: null,
    user_id: null,
  },
  continued_accompaniment_selected: {
    accompaniment_status: null,
    accompaniments_type: null,
  },
  forwarding_attributes: [
    {
      id: null,
      forwarding_policy_id: null,
      inscription_id: null,
      psychosocial_accompaniment_id: null,
      facility_id: null,
      forwarding_description: "",
      forwarding_date: moment().format("YYYY-MM-DD"),
      forwarding_status: 0,
      forwarding_expiration_date: moment().add(15, "days").format("YYYY-MM-DD"),
      forwarding_user_id: null,
      differenceBetweenDates: 15,
      full_date: null,
      city_id: null,
    },
  ],
  forwarding_policy_attributes: [
    {
      id: null,
      name: null,
    },
  ],
  forwarding_facilities_attributes: [
    {
      id: null,
      name: null,
      address: null,
    },
  ],
  participant_presence_attributes: [
    {
      id: null,
      name: null,
      psychosocial_accompaniment_id: null,
      relationship_degree: null,
    },
  ],
  relationship_degree_attributes: [
    {
      id: null,
      name: null,
    },
  ],

  attendant_teams_attributes: [
    {
      id: null,
      psychosocial_attendant_id: null,
      psychosocial_accompaniment_id: null,
    },
  ],
  psychosocial_attendant_attributes: [
    {
      id: null,
      name: null,
    },
  ],
  disciplinary_sanctions_attributes: [
    {
      id: null,
      sanction_type: null,
      sanction_status: null,
      compliance_mode: null,
      penalty_type: null,
      completion_time: null,
      completion_forecast: null,
      compliance_location: null,
      _destroy: false,
      disciplinary_sanctions_measures_attributes: [],
    },
  ],
  seasons: [],
  inscriptions: [],
  psychosocial_accompaniments: [],
  filterPsychosocialAccompaniments: [],
  psychosocial_accompaniments_types: [],
  psychosocial_accompaniments_status: [],
  housing_situations: [],
  housing_building_situations: [],
  housing_building_materials: [],
  housing_has_electricities: [],
  housing_has_water_supplies: [],
  housing_has_gas_supplies: [],
  housing_bathroom_drain_types: [],
  meeting_not_happened_reasons: [],
  socio_educational_measure_situations: [],
  sentence_serving_situations: [],
  genders: [],
  identifies_as_lgbtqiapns: [],
  forwardings: [],
  user_areas: [],
  user_equipments: [],
  psychosocial_accompaniments_pending_reception_spreadsheet: [],
  psychosocial_accompaniments_unaccompanied_spreadsheet: [],
  psychosocial_accompaniments_in_accompaniment_spreadsheet: [],
  psychosocial_accompaniments_evolution_spreadsheet: [],
  equipments_psychosocial: [],
  relationship_degrees: [],
  psychosocial_attendants_for_psychosocial: [],
  attendant_teams: [],
  participant_presences: [],
  users: [],
  institutional_referrals: [],
  matrix_risk_stratifications_by_psychosocial: null,
  facilities: [{ facilities: null }],
  inscriptionsForGeneralMenus: [],

  season: null,
  inscription: null,
  housing_situation: null,
  forwarding: null,
  accompaniments_type: null,
  accompaniment_status: null,
  housing_building_situation: null,
  housing_building_material: null,
  housing_has_electricity: null,
  housing_has_water_supply: null,
  housing_has_gas_supply: null,
  housing_bathroom_drain_type: null,
  meeting_not_happened_reason: null,
  socio_educational_measure_situation: null,
  sentence_serving_situation: null,
  gender: null,
  identifies_as_lgbtqiapn: null,
  user: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,
  accompaniment_origin: null,

  register_forwarding: false,
  register_vulnerability_matrix: false,
  register_participant_presence: false,
  register_attendant_teams: false,

  hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponse: true,
  hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse: true,
  hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse: true,
  hasSpreadsheetPsychosocialAccompanimentEvolutionResponse: true,
  json_data_psychosocial_pending_reception: null,
  json_data_psychosocial_unaccompanied: null,
  json_data_psychosocial_in_accompaniment: null,
  json_data_psychosocial_evolution: null,

  psychosocialAccompanimentId: 0,

  modalTogglePsychosocialAccompaniments: false,
  modalToggleAddAttendance: false,
  modalToggleFirstPsychosocialAccompaniment: false,
  modalTogglePsychosocialAccompanimentJustifyNonReception: false,
  modalTogglePsychosocialIndividualMonitoring: false,
  modalTogglePsychosocialHomeVisit: false,
  modalTogglePsychosocialAccompanimentPostponeDate: false,

  modalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet: false,
  modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet: false,
  modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet: false,
  modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet: false,

  tabPsychosocialAccompanimentView: "inAccompaniment",
  togglePsychosocialAccompanimentPendingReception: false,
  togglePsychosocialAccompanimentUnaccompanied: false,
  togglePsychosocialAccompanimentInAccompaniment: true,

  tabPsychosocialAccompanimentType: "personal_data",
  togglePsychosocialAccompanimentPersonalData: true,
  togglePsychosocialAccompanimentVulnerability: false,
  togglePsychosocialAccompanimentGuidelines: false,
  togglePsychosocialAccompanimentForwardings: false,
  togglePsychosocialAccompanimentEvolution: false,

  modalConfirmDeletePsychosocialAccompaniment: false,
  modalViewPsychosocialAccompanimentByInscription: false,
  modalConfirmSavePsychosocialAccompanimentWithoutMatrix: false,
  ignore_matrix_risk: false,

  inscriptionsTotal: 0,
  totalPerPage: 10,
  offset: 0,
  psychosocialAccompanimentsByInscriptionTotal: 0,
  psychosocialAccompanimentsByInscriptionTotalPerPage: 10,
  psychosocialAccompanimentsByInscriptionOffset: 0,

  params: {
    main_page: {
      cpf: null,
      disciplinary_sanction: null,
      first_service: null,
      accompaniment_will_continue: null,
      equipment: null,
      area: null,
      state: null,
      city: null,
      status_accompaniment: null,
      type_attendance: null,
      type_situation: null,
      inscription: null,
      user: null,
      is_pregnant: null,
      show_signs_of_self_harm: null,
      uses_substances: null,
      institutional_referral: null,
      matrix_situations: [],
      matrix_problems: [],
    },
    evolution_page: {
      type_attendance: null,
      type_status: null,
    },
  },
  loading: {
    inscriptions: false,
  },
};

const mutations = {
  // --> MUTATIONS - DATA COMING FROM API <--
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
    state.offset = 0;
  },
  setInscriptionsMutation: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  setUsersMutation: (state, users) => {
    state.users = users;
  },
  setPsychosocialAccompanimentsMutation: (state, psychosocial_accompaniments) => {
    state.psychosocial_accompaniments = psychosocial_accompaniments;
  },
  setForwadingsMutation: (state, forwardings) => {
    state.forwardings = forwardings;
  },
  setMatrixRiskStratificationsMutation: (state, matrix_risk_stratifications_by_psychosocial) => {
    state.matrix_risk_stratifications_by_psychosocial = matrix_risk_stratifications_by_psychosocial;
  },
  setPsychosocialAccompanimentsTypesMutation: (state, psychosocial_accompaniments_types) => {
    state.psychosocial_accompaniments_types = psychosocial_accompaniments_types;
  },
  setPsychosocialAccompanimentsStatusMutation: (state, psychosocial_accompaniments_status) => {
    state.psychosocial_accompaniments_status = psychosocial_accompaniments_status;
  },
  setHousingSituationsMutation: (state, housing_situations) => {
    state.housing_situations = housing_situations;
  },
  setHousingBuildingSituationsMutation: (state, housing_building_situations) => {
    state.housing_building_situations = housing_building_situations;
  },
  setHousingBuildingMaterialsMutation: (state, housing_building_materials) => {
    state.housing_building_materials = housing_building_materials;
  },
  setHousingHasElectricitiesMutation: (state, housing_has_electricities) => {
    state.housing_has_electricities = housing_has_electricities;
  },
  setHousingHasWaterSuppliesMutation: (state, housing_has_water_supplies) => {
    state.housing_has_water_supplies = housing_has_water_supplies;
  },
  setHousingHasGasSuppliesMutation: (state, housing_has_gas_supplies) => {
    state.housing_has_gas_supplies = housing_has_gas_supplies;
  },
  setHousingBathroomDrainTypesMutation: (state, housing_bathroom_drain_types) => {
    state.housing_bathroom_drain_types = housing_bathroom_drain_types;
  },
  setMeetingNotHappenedReasonsMutation: (state, meeting_not_happened_reasons) => {
    state.meeting_not_happened_reasons = meeting_not_happened_reasons;
  },
  setSocioEducationalMeasureSituationsMutation: (state, socio_educational_measure_situations) => {
    state.socio_educational_measure_situations = socio_educational_measure_situations;
  },
  setSentenceServingSituationsMutation: (state, sentence_serving_situations) => {
    state.sentence_serving_situations = sentence_serving_situations;
  },
  setGendersMutation: (state, genders) => {
    state.genders = genders;
  },
  loadInstitutionalReferrals: (state, institutional_referrals) => {
    state.institutional_referrals = institutional_referrals;
  },
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },
  setidentifiesAsLgbtqiapnsMutation: (state, identifies_as_lgbtqiapns) => {
    state.identifies_as_lgbtqiapns = identifies_as_lgbtqiapns;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsForPsychosocialMutation: (state, equipments_psychosocial) => {
    state.equipments_psychosocial = equipments_psychosocial;
  },
  setPsychosocialAccompanimentPendingReceptionSpreadsheetMutation: (
    state,
    psychosocial_accompaniments_pending_reception_spreadsheet
  ) => {
    state.psychosocial_accompaniments_pending_reception_spreadsheet =
      psychosocial_accompaniments_pending_reception_spreadsheet;
  },
  setPsychosocialAccompanimentUnaccompaniedSpreadsheetMutation: (
    state,
    psychosocial_accompaniments_unaccompanied_spreadsheet
  ) => {
    state.psychosocial_accompaniments_unaccompanied_spreadsheet =
      psychosocial_accompaniments_unaccompanied_spreadsheet;
  },
  setPsychosocialAccompanimentInAccompanimentSpreadsheetMutation: (
    state,
    psychosocial_accompaniments_in_accompaniment_spreadsheet
  ) => {
    state.psychosocial_accompaniments_in_accompaniment_spreadsheet =
      psychosocial_accompaniments_in_accompaniment_spreadsheet;
  },
  setPsychosocialAccompanimentEvolutionSpreadsheetMutation: (
    state,
    psychosocial_accompaniments_evolution_spreadsheet
  ) => {
    state.psychosocial_accompaniments_evolution_spreadsheet =
      psychosocial_accompaniments_evolution_spreadsheet;
  },
  setForwardingMutation: (state, forwarding) => {
    state.forwarding = forwarding;
  },
  setFacilitiesMutation: (state, payload) => {
    state.facilities.forEach((facility, i) => {
      if (payload.index === i) {
        facility.facilities = payload.response;
      }
    });
  },
  setRelationshipDegreesMutation: (state, relationship_degrees) => {
    state.relationship_degrees = relationship_degrees;
  },
  setPsychosocialAttendantsForPsychosocialMutation: (state, psychosocial_attendants_for_psychosocial) => {
    state.psychosocial_attendants_for_psychosocial = psychosocial_attendants_for_psychosocial;
  },
  setAttendantTeamsMutation: (state, attendant_teams) => {
    state.attendant_teams = attendant_teams;
  },
  setPartipantPresencesMutation: (state, participant_presences) => {
    state.participant_presences = participant_presences;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE <--
  setIdMutation: (state, id) => {
    state.psychosocial_accompaniment.id = id;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
    state.psychosocial_accompaniment.inscription_id = inscription.id;
    sessionStorage.setItem("inscription_id", inscription.id);
  },
  setPsychosocialAccompanimentsTypeMutation: (state, psychosocial_accompaniments_type) => {
    state.accompaniments_type = psychosocial_accompaniments_type;
    state.psychosocial_accompaniment.accompaniment_type = psychosocial_accompaniments_type.id;
  },
  setPsychosocialAccompanimentStatusMutation: (state, psychosocial_accompaniment_status) => {
    state.accompaniment_status = psychosocial_accompaniment_status;
    state.psychosocial_accompaniment.accompaniment_status = psychosocial_accompaniment_status?.id;
  },
  setDateAccompanimentMutation: (state, date_accompaniment) => {
    state.psychosocial_accompaniment.date_accompaniment = date_accompaniment;
  },
  setAccompanimentCommentsMutation: (state, accompaniment_comments) => {
    state.psychosocial_accompaniment.accompaniment_comments = accompaniment_comments;
  },
  setAccompanimentOriginMutation: (state, accompaniment_origin) => {
    state.accompaniment_origin = accompaniment_origin;
  },
  setMeetingHappenedMutation: (state, meeting_happened) => {
    state.psychosocial_accompaniment.meeting_happened = meeting_happened;
  },
  setMeetingFormatMutation: (state, meeting_format) => {
    state.psychosocial_accompaniment.meeting_format = meeting_format;
  },
  setMeetingPlaceMutation: (state, meeting_place) => {
    state.psychosocial_accompaniment.meeting_place = meeting_place;
  },
  setMeetingRemoteResourceMutation: (state, meeting_remote_resource) => {
    state.psychosocial_accompaniment.meeting_remote_resource = meeting_remote_resource;
  },
  setMeetingConclusionDescriptionMutation: (state, meeting_conclusion_description) => {
    state.psychosocial_accompaniment.meeting_conclusion_description = meeting_conclusion_description;
  },
  setForwardedMutation: (state, forwarded) => {
    state.psychosocial_accompaniment.forwarded = forwarded;
  },
  setHousingSituationMutation: (state, housing_situation) => {
    state.housing_situation = housing_situation;
    if (housing_situation != null) {
      state.psychosocial_accompaniment.housing_situation_id = housing_situation.id;
    }
  },
  setHousingSituationCommentsMutation: (state, housing_situation_comments) => {
    state.psychosocial_accompaniment.housing_situation_comments = housing_situation_comments;
  },
  setHousingHasLatrineMutation: (state, housing_has_latrine) => {
    state.psychosocial_accompaniment.housing_has_latrine = housing_has_latrine;
  },
  setHousingHasBathroomMutation: (state, housing_has_bathroom) => {
    state.psychosocial_accompaniment.housing_has_bathroom = housing_has_bathroom;
  },
  setHousingHasGarbageCollectionMutation: (state, housing_has_garbage_collection) => {
    state.psychosocial_accompaniment.housing_has_garbage_collection = housing_has_garbage_collection;
  },
  setHousingBuildingSituationMutation: (state, housing_building_situation) => {
    state.housing_building_situation = housing_building_situation;
    if (housing_building_situation != null) {
      state.psychosocial_accompaniment.housing_building_situation = housing_building_situation.id;
    }
  },
  setHousingBuildingMaterialMutation: (state, housing_building_material) => {
    state.housing_building_material = housing_building_material;
    if (housing_building_material != null) {
      state.psychosocial_accompaniment.housing_building_material = housing_building_material.id;
    }
  },
  setHousingHasElectricityMutation: (state, housing_has_electricity) => {
    state.housing_has_electricity = housing_has_electricity;
    if (housing_has_electricity != null) {
      state.psychosocial_accompaniment.housing_has_electricity = housing_has_electricity.id;
    }
  },
  setHousingHasWaterSupplyMutation: (state, housing_has_water_supply) => {
    state.housing_has_water_supply = housing_has_water_supply;
    if (housing_has_water_supply != null) {
      state.psychosocial_accompaniment.housing_has_water_supply = housing_has_water_supply.id;
    }
  },
  setHousingHasGasSupplyMutation: (state, housing_has_gas_supply) => {
    state.housing_has_gas_supply = housing_has_gas_supply;
    if (housing_has_gas_supply != null) {
      state.psychosocial_accompaniment.housing_has_gas_supply = housing_has_gas_supply.id;
    }
  },
  setHousingBathroomDrainTypeMutation: (state, housing_bathroom_drain_type) => {
    state.housing_bathroom_drain_type = housing_bathroom_drain_type;
    if (housing_bathroom_drain_type != null) {
      state.psychosocial_accompaniment.housing_bathroom_drain_type = housing_bathroom_drain_type.id;
    }
  },
  setMeetingNotHappenedReasonMutation: (state, meeting_not_happened_reason) => {
    state.meeting_not_happened_reason = meeting_not_happened_reason;
    if (meeting_not_happened_reason != null) {
      state.psychosocial_accompaniment.meeting_not_happened_reason = meeting_not_happened_reason.id;
    }
  },
  setSocioEducationalMeasureSituationMutation: (state, socio_educational_measure_situation) => {
    state.socio_educational_measure_situation = socio_educational_measure_situation;
    if (socio_educational_measure_situation != null) {
      state.psychosocial_accompaniment.socio_educational_measure_situation =
        socio_educational_measure_situation.id;
    }
  },
  setSentenceServingSituationMutation: (state, sentence_serving_situation) => {
    state.sentence_serving_situation = sentence_serving_situation;
    state.psychosocial_accompaniment.sentence_serving_situation = sentence_serving_situation
      ? sentence_serving_situation.id
      : null;
  },
  setIsHalfOpenSentenceServingSituationMutation: (state, is_half_open_sentence_serving_situation) => {
    state.psychosocial_accompaniment.is_half_open_sentence_serving_situation =
      is_half_open_sentence_serving_situation;
  },
  setIsHalfClosedSentenceServingSituationMutation: (state, is_half_closed_sentence_serving_situation) => {
    state.psychosocial_accompaniment.is_half_closed_sentence_serving_situation =
      is_half_closed_sentence_serving_situation;
  },
  setObeyedSocioEducationalMeasureMutation: (state, obeyed_socio_educational_measure) => {
    state.psychosocial_accompaniment.obeyed_socio_educational_measure = obeyed_socio_educational_measure;
  },
  setIsHalfOpenSocioEducationalMeasureMutation: (state, is_half_open_socio_educational_measure) => {
    state.psychosocial_accompaniment.is_half_open_socio_educational_measure =
      is_half_open_socio_educational_measure;
  },
  setIsHalfClosedSocioEducationalMeasureMutation: (state, is_half_closed_socio_educational_measure) => {
    state.psychosocial_accompaniment.is_half_closed_socio_educational_measure =
      is_half_closed_socio_educational_measure;
  },
  setIsStudyingMutation: (state, is_studying) => {
    state.psychosocial_accompaniment.is_studying = is_studying;
  },
  setReasonForNotStudyingMutation: (state, reason_for_not_studying) => {
    state.psychosocial_accompaniment.reason_for_not_studying = reason_for_not_studying;
  },
  setReasonForDropoutStudyMutation: (state, reason_for_dropout_study) => {
    state.psychosocial_accompaniment.reason_for_dropout_study = reason_for_dropout_study;
  },
  setLastGradeStudiedMutation: (state, last_grade_studied) => {
    state.psychosocial_accompaniment.last_grade_studied = last_grade_studied;
  },
  setLastSchoolStudiedMutation: (state, last_school_studied) => {
    state.psychosocial_accompaniment.last_school_studied = last_school_studied;
  },
  setNeedSchoolReinsertionMutation: (state, need_school_reinsertion) => {
    state.psychosocial_accompaniment.need_school_reinsertion = need_school_reinsertion;
  },
  setIsPregnantMutation: (state, is_pregnant) => {
    state.psychosocial_accompaniment.is_pregnant = is_pregnant;
  },
  setGestationalAgeMutation: (state, gestational_age) => {
    state.psychosocial_accompaniment.gestational_age = gestational_age;
  },
  setIsPuerperiumMutation: (state, is_puerperium) => {
    state.psychosocial_accompaniment.is_puerperium = is_puerperium;
  },
  setUsePsychoactiveMutation: (state, use_psychoactive) => {
    state.psychosocial_accompaniment.use_psychoactive = use_psychoactive;
  },
  setFrequentlyUsesHealthNetworkMutation: (state, frequently_uses_health_network) => {
    state.psychosocial_accompaniment.frequently_uses_health_network = frequently_uses_health_network;
  },
  setHealthPlaceFrequentlyMutation: (state, health_place_frequently) => {
    state.psychosocial_accompaniment.health_place_frequently = health_place_frequently;
  },
  setMotivationUsesHealthFrquentlyMutation: (state, motivation_uses_health_frequently) => {
    state.psychosocial_accompaniment.motivation_uses_health_frequently = motivation_uses_health_frequently;
  },
  setAccompanimentWillContinueMutation: (state, accompaniment_will_continue) => {
    state.psychosocial_accompaniment.accompaniment_will_continue = accompaniment_will_continue;
  },
  setAccompanimentGuidelineMutation: (state, accompaniment_guideline) => {
    state.psychosocial_accompaniment.accompaniment_guideline = accompaniment_guideline;
  },
  setSpontaneousAttendanceMutation: (state, spontaneous_attendance) => {
    state.psychosocial_accompaniment.spontaneous_attendance = spontaneous_attendance;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.psychosocial_accompaniment.user_id = user.id;
      } else {
        state.psychosocial_accompaniment.user_id = user.userId;
      }
    }
  },
  setIsYoungFatherMutation: (state, is_young_father) => {
    state.psychosocial_accompaniment.is_young_father = is_young_father;
  },
  setIsYoungMotherMutation: (state, is_young_mother) => {
    state.psychosocial_accompaniment.is_young_mother = is_young_mother;
  },
  setPurposeHomeVisitMutation: (state, purpose_home_visit) => {
    state.psychosocial_accompaniment.purpose_home_visit = purpose_home_visit;
  },
  setSocialFamilyContextMutation: (state, social_family_context) => {
    state.psychosocial_accompaniment.social_family_context = social_family_context;
  },
  setSocialEconomicContextMutation: (state, social_economic_context) => {
    state.psychosocial_accompaniment.social_economic_context = social_economic_context;
  },
  setCommunityCoexistenceMutation: (state, community_coexistence) => {
    state.psychosocial_accompaniment.community_coexistence = community_coexistence;
  },
  setNumberOfResidentsMutation: (state, number_of_residents) => {
    state.psychosocial_accompaniment.number_of_residents = number_of_residents;
  },
  setGenderMutation: (state, gender) => {
    state.gender = gender;
    if (gender != null) {
      state.psychosocial_accompaniment.gender = gender.id;
    }
  },
  setGenderIdentityObservationsMutation: (state, gender_identity_observations) => {
    state.psychosocial_accompaniment.gender_identity_observations = gender_identity_observations;
  },
  setidentifiesAsLgbtqiapnMutation: (state, identifies_as_lgbtqiapn) => {
    state.identifies_as_lgbtqiapn = identifies_as_lgbtqiapn;
    if (identifies_as_lgbtqiapn != null) {
      state.psychosocial_accompaniment.identifies_as_lgbtqiapn = identifies_as_lgbtqiapn.id;
    }
  },
  setUsesPsychoactiveSubstancesMutation: (state, uses_psychoactive_substances) => {
    state.psychosocial_accompaniment.uses_psychoactive_substances = uses_psychoactive_substances;
  },
  setUsesPsychoactiveSubstancesRecreationallyMutation: (
    state,
    uses_psychoactive_substances_recreationally
  ) => {
    state.psychosocial_accompaniment.uses_psychoactive_substances_recreationally =
      uses_psychoactive_substances_recreationally;
  },
  setSelfMedicateMutation: (state, self_medicate) => {
    state.psychosocial_accompaniment.self_medicate = self_medicate;
  },
  setShowSignsOfSelfHarmMutation: (state, show_signs_of_self_harm) => {
    state.psychosocial_accompaniment.show_signs_of_self_harm = show_signs_of_self_harm;
  },
  setShowSignsOfCompromisedPhysicalHealthMutation: (state, show_signs_of_compromised_physical_health) => {
    state.psychosocial_accompaniment.show_signs_of_compromised_physical_health =
      show_signs_of_compromised_physical_health;
  },
  setShowSignsOfCompromisedMentalHealthMutation: (state, show_signs_of_compromised_mental_health) => {
    state.psychosocial_accompaniment.show_signs_of_compromised_mental_health =
      show_signs_of_compromised_mental_health;
  },
  setHealthSituationObservationsMutation: (state, health_situation_observations) => {
    state.psychosocial_accompaniment.health_situation_observations = health_situation_observations;
  },
  setJustificationForNonAcceptanceMutation: (state, justification_for_non_acceptance) => {
    state.psychosocial_accompaniment.justification_for_non_acceptance = justification_for_non_acceptance;
  },
  setJustificationForNonAcceptanceDateMutation: (state, justification_for_non_acceptance_date) => {
    state.psychosocial_accompaniment.justification_for_non_acceptance_date =
      justification_for_non_acceptance_date;
  },
  setAccompanimentOriginIdtMutation: (state, accompaniment_origin_id) => {
    state.psychosocial_accompaniment.accompaniment_origin_id = accompaniment_origin_id;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE [CONTINUED ACCOMPANIMENT] <--
  setIdContinuedAccompanimentMutation: (state, id) => {
    state.continued_accompaniment.id = id;
  },
  setInscriptionContinuedAccompanimentMutation: (state, inscription_id) => {
    state.continued_accompaniment.inscription_id = inscription_id;
  },
  setPsychosocialAccompanimentsTypeContinuedAccompanimentMutation: (
    state,
    psychosocial_accompaniments_type
  ) => {
    state.continued_accompaniment_selected.accompaniments_type = psychosocial_accompaniments_type;
    state.continued_accompaniment.accompaniment_type = psychosocial_accompaniments_type.id;
  },
  setPsychosocialAccompanimentStatusContinuedAccompanimentMutation: (
    state,
    psychosocial_accompaniment_status
  ) => {
    state.continued_accompaniment_selected.accompaniment_status = psychosocial_accompaniment_status;
    state.continued_accompaniment.accompaniment_status = psychosocial_accompaniment_status.id;
  },
  setDateAccompanimentContinuedAccompanimentMutation: (state, date_accompaniment) => {
    state.continued_accompaniment.date_accompaniment = date_accompaniment;
  },
  setUserContinuedAccompanimentMutation: (state, user_id) => {
    state.continued_accompaniment.user_id = user_id;
  },
  setAccompanimentOriginIdContinuedAccompanimentMutation: (state, accompaniment_origin_id) => {
    state.continued_accompaniment.accompaniment_origin_id = accompaniment_origin_id;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE FORWARDINGS <--
  setForwardingPolicyMutation: (state, payload) => {
    let index = payload.index;
    let forwarding_policy = payload;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.forwarding_policy_id = forwarding_policy.id;
      }
    });

    state.forwarding_policy_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.id = forwarding_policy.id;
        forwarding.name = forwarding_policy.name;
      }
    });
  },
  setForwardingFacilityMutation: (state, payload) => {
    if (payload.null) {
      state.forwarding_attributes.forEach((forwarding, i) => {
        if (payload.index === i) {
          forwarding.facility_id = null;
        }
      });
      state.forwarding_facilities_attributes.forEach((facility, i) => {
        if (payload.index === i) {
          facility.id = null;
          facility.name = null;
          facility.address = null;
        }
      });
    } else {
      let index = payload.index;
      let forwarding_facility = payload;

      state.forwarding_attributes.forEach((forwarding, i) => {
        if (index === i) {
          forwarding.facility_id = forwarding_facility.id;
        }
      });

      state.forwarding_facilities_attributes.forEach((facility, i) => {
        if (index === i) {
          facility.id = forwarding_facility.id;
          facility.name = forwarding_facility.name;
          facility.address = forwarding_facility.address;
        }
      });
    }
  },
  setForwardingInscriptionMutation: (state, payload) => {
    state.forwarding_attributes.forEach((forwarding) => {
      forwarding.inscription_id = payload;
    });
  },
  setForwardingUserMutation: (state, payload) => {
    state.forwarding_attributes.forEach((forwarding) => {
      forwarding.forwarding_user_id = payload;
    });
  },
  setForwardingIdMutation: (state, payload) => {
    let index = payload.index;
    let id = payload.id;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.id = id;
      }
    });
  },
  setForwardingPsychosocialAccompanimentMutation: (state, payload) => {
    state.forwarding_attributes.forEach((forwarding) => {
      forwarding.psychosocial_accompaniment_id = payload;
    });
  },
  setForwardingEquipmentMutation: (state, payload) => {
    let index = payload.index;
    let equipment = payload.value;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.forwarding_equipment = equipment;
      }
    });
  },
  setForwardingDateMutation: (state, payload) => {
    let index = payload.index;
    let date = payload.value;
    let expiration_date = null;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.forwarding_date = date;
        expiration_date = state.forwarding_attributes[index].forwarding_expiration_date;
      }
    });
    const forwardingDate = moment(date);
    const forwardingExpirationDate = moment(expiration_date);

    let days = forwardingExpirationDate.diff(forwardingDate, "days");
    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.differenceBetweenDates = days;
      }
    });
  },
  setForwardingExpirationDateMutation: (state, payload) => {
    let index = payload.index;
    let expiration_date = payload.value;
    let forwarding_date = null;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.forwarding_expiration_date = expiration_date;
        forwarding_date = state.forwarding_attributes[index].forwarding_date;
      }
    });
    const forwardingDate = moment(forwarding_date);
    const forwardingExpirationDate = moment(expiration_date);

    let days = forwardingExpirationDate.diff(forwardingDate, "days");
    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.differenceBetweenDates = days;
      }
    });
  },
  setForwardingDescriptionMutation: (state, payload) => {
    let index = payload.index;
    let description = payload.value;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.forwarding_description = description;
      }
    });
  },
  setForwardingDifferenceBetweenDatesMutation: (state, payload) => {
    let index = payload.index;
    let differenceBetweenDates = payload.value;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.differenceBetweenDates = differenceBetweenDates;
        state.forwarding_attributes[index].forwarding_expiration_date = moment(
          state.forwarding_attributes[index].forwarding_date
        )
          .add(differenceBetweenDates, "days")
          .format("YYYY-MM-DD");
      }
    });
  },
  setForwardingFullDateMutation: (state, payload) => {
    let index = payload.index;
    let full_date = payload.value;

    state.forwarding_attributes.forEach((forwarding, i) => {
      if (index === i) {
        forwarding.full_date = full_date;
      }
    });
  },
  addForwarding: (state) => {
    state.forwarding_attributes.push({
      id: null,
      forwarding_policy_id: null,
      inscription_id: null,
      psychosocial_accompaniment_id: null,
      facility_id: null,
      forwarding_description: "",
      forwarding_date: moment().format("YYYY-MM-DD"),
      forwarding_status: 0,
      forwarding_expiration_date: moment().add(15, "days").format("YYYY-MM-DD"),
      forwarding_user_id: null,
      differenceBetweenDates: 15,
      full_date: null,
      city_id: null,
    });
  },
  deleteForwarding: (state) => {
    state.forwarding_attributes.pop({
      id: null,
      forwarding_policy_id: null,
      inscription_id: null,
      psychosocial_accompaniment_id: null,
      facility_id: null,
      forwarding_description: "",
      forwarding_date: moment().format("YYYY-MM-DD"),
      forwarding_status: 0,
      forwarding_expiration_date: moment().add(15, "days").format("YYYY-MM-DD"),
      forwarding_user_id: null,
      differenceBetweenDates: 15,
      full_date: null,
      city_id: null,
    });
  },
  addForwardingPolicy: (state) => {
    state.forwarding_policy_attributes.push({
      id: null,
      name: null,
    });
  },
  deleteForwardingPolicy: (state) => {
    state.forwarding_policy_attributes.pop({
      id: null,
      name: null,
    });
  },
  addForwardingFacility: (state) => {
    state.forwarding_facilities_attributes.push({
      id: null,
      name: null,
      address: null,
    });
  },
  deleteForwardingFacility: (state) => {
    state.forwarding_facilities_attributes.pop({
      id: null,
      name: null,
      address: null,
    });
  },
  addFacilities: (state) => {
    state.facilities.push({
      facilities: null,
    });
  },
  deleteFacilities: (state) => {
    state.facilities.pop({
      facilities: null,
    });
  },

  // --> MUTATIONS - FIELDS TO THE TABLE PARTICIPANT_PRESENCE <--
  setRelationshipDegreeMutation: (state, payload) => {
    let index = payload.index;
    let relationship_degree = payload;

    state.participant_presence_attributes.forEach((participant_presence, i) => {
      if (index === i) {
        participant_presence.relationship_degree = relationship_degree.id;
      }
    });

    state.relationship_degree_attributes.forEach((relation, i) => {
      if (index === i) {
        relation.id = relationship_degree.id;
        relation.name = relationship_degree.name;
      }
    });
  },
  setParticipantPresencePsychosocialAccompanimentMutation: (state, payload) => {
    state.participant_presence_attributes.forEach((participant_presence) => {
      participant_presence.psychosocial_accompaniment_id = payload;
    });
  },
  setParticipantPresenceNameMutation: (state, payload) => {
    let index = payload.index;
    let name = payload.value;

    state.participant_presence_attributes.forEach((participant_presence, i) => {
      if (index === i) {
        participant_presence.name = name;
      }
    });
  },
  setParticipantPresenceIdMutation: (state, payload) => {
    let index = payload.index;
    let id = payload.id;

    state.participant_presence_attributes.forEach((participant_presence, i) => {
      if (index === i) {
        participant_presence.id = id;
      }
    });
  },
  addParticipantPresence: (state) => {
    state.participant_presence_attributes.push({
      id: null,
      name: null,
      psychosocial_accompaniment_id: null,
      relationship_degree: null,
    });
  },
  deleteParticipantPresence: (state) => {
    state.participant_presence_attributes.pop({
      id: null,
      name: null,
      psychosocial_accompaniment_id: null,
      relationship_degree: null,
    });
  },
  addRelationshipDegreeParticipantPresence: (state) => {
    state.relationship_degree_attributes.push({
      id: null,
      name: null,
    });
  },
  deleteRelationshipDegreeParticipantPresence: (state) => {
    state.relationship_degree_attributes.pop({
      id: null,
      name: null,
    });
  },

  // --> MUTATIONS - FIELDS TO THE TABLE ATTENDANT_TEAMS <--
  setPsychosocialAttendantMutation: (state, payload) => {
    let index = payload.index;
    let psychosocial_attendant = payload;

    state.attendant_teams_attributes.forEach((attendant_team, i) => {
      if (index === i) {
        attendant_team.psychosocial_attendant_id = psychosocial_attendant.id;
      }
    });

    state.psychosocial_attendant_attributes.forEach((attendant, i) => {
      if (index === i) {
        attendant.id = psychosocial_attendant.id;
        attendant.name = psychosocial_attendant.name;
      }
    });
  },
  setAttendantTeamIdMutation: (state, payload) => {
    let index = payload.index;
    let id = payload.id;

    state.attendant_teams_attributes.forEach((attendant, i) => {
      if (index === i) {
        attendant.id = id;
      }
    });
  },
  setPsychosocialAttendantPsychosocialAccompanimentMutation: (state, payload) => {
    state.attendant_teams_attributes.forEach((attendant_team) => {
      attendant_team.psychosocial_accompaniment_id = payload;
    });
  },
  addAttendantTeam: (state) => {
    state.attendant_teams_attributes.push({
      id: null,
      psychosocial_attendant_id: null,
      psychosocial_accompaniment_id: null,
    });
  },
  deleteAttendantTeam: (state) => {
    state.attendant_teams_attributes.pop({
      id: null,
      psychosocial_attendant_id: null,
      psychosocial_accompaniment_id: null,
    });
  },
  addPsychosocialAttendant: (state) => {
    state.psychosocial_attendant_attributes.push({
      id: null,
      name: null,
    });
  },
  deletePsychosocialAttendant: (state) => {
    state.psychosocial_attendant_attributes.pop({
      id: null,
      name: null,
    });
  },

  // --> MUTATIONS - FIELDS TO THE TABLE DISCIPLINARY_SANCTION <--
  setDisciplinarySanctionMutation(state, payload) {
    state.disciplinary_sanctions_attributes = payload;
  },
  addDisciplinarySanctionMutation(state) {
    state.disciplinary_sanctions_attributes.push({
      id: null,
      sanction_type: null,
      sanction_status: null,
      compliance_mode: null,
      penalty_type: null,
      completion_time: null,
      completion_forecast: null,
      compliance_location: null,
      _destroy: false,
      disciplinary_sanctions_measures_attributes: [],
    });
  },
  updateDisciplinarySanctionMutation(state, { index, field, value }) {
    if (state.disciplinary_sanctions_attributes[index]) {
      state.disciplinary_sanctions_attributes[index][field] = value;
      if (field === "sanction_type" || field === "sanction_status") {
        state.disciplinary_sanctions_attributes[index]["penalty_type"] = null;
        state.disciplinary_sanctions_attributes[index]["disciplinary_sanctions_measures_attributes"] = [];
        state.disciplinary_sanctions_attributes[index]["penalty_type"] = null;
        state.disciplinary_sanctions_attributes[index]["compliance_mode"] = null;
        state.disciplinary_sanctions_attributes[index]["completion_time"] = null;
        state.disciplinary_sanctions_attributes[index]["completion_forecast"] = null;
        state.disciplinary_sanctions_attributes[index]["compliance_location"] = null;
      }
    }
  },
  setDisciplinarySanctionMeasureMutation: (state, payload) => {
    const measures = payload.map((item) => ({
      id: item.id || null,
      measure_id: item.measure_id,
      name: item.name,
      index: item.index,
      _destroy: item._destroy || false,
    }));

    const lastIndex = measures[measures.length - 1].index;
    if (state.disciplinary_sanctions_attributes[lastIndex]) {
      const existingMeasures =
        state.disciplinary_sanctions_attributes[lastIndex].disciplinary_sanctions_measures_attributes || [];
      const missingMeasures = existingMeasures.filter(
        (existing) => !measures.some((measure) => measure.id === existing.id)
      );

      missingMeasures.forEach((missing) => {
        missing._destroy = true;
        if (!measures.some((measure) => measure.id === missing.id)) {
          measures.push(missing);
        }
      });

      state.disciplinary_sanctions_attributes[lastIndex].disciplinary_sanctions_measures_attributes =
        measures;
    }
  },
  removeDisciplinarySanctionMutation(state, index) {
    const sanction = state.disciplinary_sanctions_attributes[index];
    if (sanction.id) {
      Vue.set(sanction, "_destroy", true);
    } else {
      state.disciplinary_sanctions_attributes.splice(index, 1);
    }
  },

  updateDisciplinarySanctionsAttributes(state) {
    // Filtra apenas as sanções que têm sanction_type e sanction_status definidos
    const validSanctions = state.disciplinary_sanctions_attributes.filter((sanction) => {
      return sanction.sanction_type && sanction.sanction_status;
    });

    // Mapeia as sanções válidas para o formato esperado
    state.psychosocial_accompaniment.disciplinary_sanctions_attributes = validSanctions.map((sanction) => ({
      id: sanction?.id,
      sanction_type: sanction.sanction_type ? sanction.sanction_type.id : null,
      sanction_status: sanction.sanction_status ? sanction.sanction_status.id : null,
      compliance_mode: sanction.compliance_mode ? sanction.compliance_mode.id : null,
      penalty_type: sanction.penalty_type ? sanction.penalty_type.id : null,
      completion_time: sanction.completion_time ? sanction.completion_time.id : null,
      completion_forecast: sanction.completion_forecast ? sanction.completion_forecast.id : null,
      compliance_location: sanction.compliance_location,
      _destroy: sanction._destroy,
      disciplinary_sanctions_measures_attributes: sanction?.disciplinary_sanctions_measures_attributes?.map(
        (measure) => ({
          id: measure.id,
          measure_id: measure.measure_id,
          _destroy: measure._destroy,
        })
      ),
    }));
  },

  // --> MUTATIONS - FILTERS <--

  // -- MAIN PAGE --
  filterListByCPF: (state, input) => {
    state.params.main_page.cpf = input.value;
  },
  filterListByInscription: (state, input) => {
    state.params.main_page.inscription = input;
  },
  filterListByFirstService: (state, input) => {
    state.params.main_page.first_service = input;
  },
  filterListByHasDisciplinarySanction: (state, input) => {
    state.params.main_page.disciplinary_sanction = input;
  },
  filterListByAccompanimentWillContinue: (state, input) => {
    state.params.main_page.accompaniment_will_continue = input;
  },
  filterListByArea: (state, input) => {
    state.params.main_page.area = input;
  },
  filterListByState: (state, input) => {
    state.params.main_page.state = input;
  },
  filterListByCity: (state, input) => {
    state.params.main_page.city = input;
  },
  filterListByEquipment: (state, input) => {
    state.params.main_page.equipment = input;
  },
  filterListByStatusAccompaniment: (state, status_accompaniment) => {
    state.params.main_page.status_accompaniment = status_accompaniment;
  },
  filterListByUser: (state, user) => {
    state.params.main_page.user = user;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },
  filterListByTypeAttendanceMainPage: (state, type_attendance) => {
    state.params.main_page.type_attendance = type_attendance;
  },
  filterListByTypeSituation: (state, input) => {
    state.params.main_page.type_situation = input;
  },
  filterListByMatrixSituations: (state, matrix_situations) => {
    state.params.main_page.matrix_situations = matrix_situations;
  },
  filterListByMatrixProblems: (state, matrix_problems) => {
    state.params.main_page.matrix_problems = matrix_problems;
  },
  filterListByIsPregnant: (state, is_pregnant) => {
    state.params.main_page.is_pregnant = is_pregnant;
  },
  filterListByShowSignsOfSelfHarm: (state, show_signs_of_self_harm) => {
    state.params.main_page.show_signs_of_self_harm = show_signs_of_self_harm;
  },
  filterListByUsesSubstances: (state, uses_substances) => {
    state.params.main_page.uses_substances = uses_substances;
  },
  filterListByInstitutionalReferral: (state, institutional_referral) => {
    state.params.main_page.institutional_referral = institutional_referral;
  },

  // -- EVOLUTION PAGE --
  filterListByTypeAttendance: (state, type_attendance) => {
    state.params.evolution_page.type_attendance = type_attendance;
  },
  filterListByTypeStatusEvolution: (state, type_status) => {
    state.params.evolution_page.type_status = type_status;
  },

  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalAddAttendance: (state) => {
    state.modalToggleAddAttendance = !state.modalToggleAddAttendance;
  },
  toggleModalFirstPsychosocialAccompaniment: (state) => {
    state.modalToggleFirstPsychosocialAccompaniment = !state.modalToggleFirstPsychosocialAccompaniment;
  },
  toggleModalPsychosocialAccompanimentJustifyNonReception: (state) => {
    state.modalTogglePsychosocialAccompanimentJustifyNonReception =
      !state.modalTogglePsychosocialAccompanimentJustifyNonReception;
  },
  toggleModalPsychosocialIndividualMonitoring: (state) => {
    state.modalTogglePsychosocialIndividualMonitoring = !state.modalTogglePsychosocialIndividualMonitoring;
  },
  toggleModalPsychosocialHomeVisit: (state) => {
    state.modalTogglePsychosocialHomeVisit = !state.modalTogglePsychosocialHomeVisit;
  },
  togglePsychosocialAccompanimentPendingReception: (state, value) => {
    state.togglePsychosocialAccompanimentPendingReception = value;
  },
  togglePsychosocialAccompanimentUnaccompanied: (state, value) => {
    state.togglePsychosocialAccompanimentUnaccompanied = value;
  },
  togglePsychosocialAccompanimentInAccompaniment: (state, value) => {
    state.togglePsychosocialAccompanimentInAccompaniment = value;
  },
  togglePsychosocialAccompanimentPersonalData: (state, value) => {
    state.togglePsychosocialAccompanimentPersonalData = value;
  },
  togglePsychosocialAccompanimentVulnerability: (state, value) => {
    state.togglePsychosocialAccompanimentVulnerability = value;
  },
  togglePsychosocialAccompanimentGuidelines: (state, value) => {
    state.togglePsychosocialAccompanimentGuidelines = value;
  },
  togglePsychosocialAccompanimentForwardings: (state, value) => {
    state.togglePsychosocialAccompanimentForwardings = value;
  },
  togglePsychosocialAccompanimentEvolution: (state, value) => {
    state.togglePsychosocialAccompanimentEvolution = value;
  },
  toggleModalDeletePsychosocialAccompaniment: (state) => {
    state.modalConfirmDeletePsychosocialAccompaniment = !state.modalConfirmDeletePsychosocialAccompaniment;
  },
  toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix: (state) => {
    state.modalConfirmSavePsychosocialAccompanimentWithoutMatrix =
      !state.modalConfirmSavePsychosocialAccompanimentWithoutMatrix;
  },
  toggleModalViewPsychosocialAccompanimentByInscription: (state) => {
    state.modalViewPsychosocialAccompanimentByInscription =
      !state.modalViewPsychosocialAccompanimentByInscription;
  },
  toggleModalPsychosocialAccompanimentPostponeDate: (state) => {
    state.modalTogglePsychosocialAccompanimentPostponeDate =
      !state.modalTogglePsychosocialAccompanimentPostponeDate;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet: (state) => {
    state.modalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet =
      !state.modalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet;
  },
  toggleModalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet: (state) => {
    state.modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet =
      !state.modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet;
  },
  toggleModalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet: (state) => {
    state.modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet =
      !state.modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet;
  },
  toggleModalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet: (state) => {
    state.modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet =
      !state.modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setTotalPerPage: (state, totalPerPage) => {
    state.totalPerPage = totalPerPage;
  },
  setOffset: (state, offset) => {
    state.offset = offset;
  },
  setInscriptionsTotal: (state, inscriptionsTotal) => {
    state.inscriptionsTotal = inscriptionsTotal;
  },
  setPsychosocialAccompanimentByInscriptionTotalPerPage: (
    state,
    psychosocialAccompanimentsByInscriptionTotalPerPage
  ) => {
    state.psychosocialAccompanimentsByInscriptionTotalPerPage =
      psychosocialAccompanimentsByInscriptionTotalPerPage;
  },
  setPsychosocialAccompanimentByInscriptionOffset: (state, psychosocialAccompanimentsByInscriptionOffset) => {
    state.psychosocialAccompanimentsByInscriptionOffset = psychosocialAccompanimentsByInscriptionOffset;
  },
  setPsychosocialAccompanimentByInscriptionsTotal: (state, psychosocialAccompanimentsByInscriptionTotal) => {
    state.psychosocialAccompanimentsByInscriptionTotal = psychosocialAccompanimentsByInscriptionTotal;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponseMutation: (
    state,
    hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponse
  ) => {
    state.hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponse =
      hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponse;
  },
  hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponseMutation: (
    state,
    hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse
  ) => {
    state.hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse =
      hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse;
  },

  hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponseMutation: (
    state,
    hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse
  ) => {
    state.hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse =
      hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse;
  },

  hasSpreadsheetPsychosocialAccompanimentEvolutionResponseMutation: (
    state,
    hasSpreadsheetPsychosocialAccompanimentEvolutionResponseMutation
  ) => {
    state.hasSpreadsheetPsychosocialAccompanimentEvolutionResponseMutation =
      hasSpreadsheetPsychosocialAccompanimentEvolutionResponseMutation;
  },

  setPsychosocialMainPageToExportMutation: (state, data) => {
    state.json_data_psychosocial_pending_reception = null;
    state.json_data_psychosocial_unaccompanied = null;
    state.json_data_psychosocial_in_accompaniment = null;
    const arrayData = new Array();
    data.forEach((element, index, array) => {
      let status = util_function.processInscriptionStatuses(array[index].status);
      let student_name = array[index].student ? array[index].student.name : "";
      let student_cpf = array[index].student ? array[index].student.cpf : "";
      let student_email = array[index].student ? array[index].student.email : "";
      let student_neighborhood = array[index].student.neighborhood
        ? array[index].student.neighborhood.name
        : "";
      let season_name = array[index].student.season ? array[index].student.season.name : "";
      let student_area = array[index].student.neighborhood.area
        ? array[index].student.neighborhood.area.name
        : "";
      let last_socio_educational_measure = array[index].last_socio_educational_measure
        ? array[index].last_socio_educational_measure.name
        : "";
      let student_equipment = array[index].student.equipment ? array[index].student.equipment.name : "";
      let student_age = array[index].student.age ? array[index].student.age : "";
      let student_percent = array[index].student.teams_attributes
        ? array[index].student.teams_attributes.length > 0
          ? array[index].student.teams_attributes[0].presence_percent
            ? `${array[index].student.teams_attributes[0].presence_percent}%`
            : ""
          : ""
        : "";
      let situation_risk = array[index].situation_risk ? array[index].situation_risk : "";
      let attendant_team = [];
      if (array[index].last_attendant_team.length > 0) {
        array[index].last_attendant_team.forEach((item) => {
          attendant_team.push(item);
        });
      } else {
        attendant_team = "";
      }

      let user = [];
      if (array[index].users.length > 0) {
        array[index].users.forEach((item) => {
          user.push(item.name);
        });
      } else {
        user = "";
      }
      let matrix_situations = array[index].matrix_situations.length > 0 ? array[index].matrix_situations : "";
      let matrix_problems = array[index].matrix_problems.length > 0 ? array[index].matrix_problems : "";
      let last_grade_studied = array[index].last_psychosocial_accompaniment.last_grade_studied
        ? array[index].last_psychosocial_accompaniment.last_grade_studied
        : "";
      let last_school_studied = array[index].last_psychosocial_accompaniment.last_school_studied
        ? array[index].last_psychosocial_accompaniment.last_school_studied
        : "";
      let reason_for_dropout_study = array[index].last_psychosocial_accompaniment.reason_for_dropout_study
        ? array[index].last_psychosocial_accompaniment.reason_for_dropout_study
        : "";
      let reason_for_not_studying = array[index].last_psychosocial_accompaniment.reason_for_not_studying
        ? array[index].last_psychosocial_accompaniment.reason_for_not_studying
        : "";
      let justification_for_non_acceptance = array[index].last_psychosocial_accompaniment
        .justification_for_non_acceptance
        ? array[index].last_psychosocial_accompaniment.justification_for_non_acceptance
        : "";
      let justification_for_non_acceptance_date = array[index].last_psychosocial_accompaniment
        .justification_for_non_acceptance_date
        ? moment(array[index].last_psychosocial_accompaniment.justification_for_non_acceptance_date).format(
            "DD/MM/YYYY"
          )
        : "";
      let is_studying = "";
      let need_school_reinsertion = "";
      let is_pregnant = "";
      let show_signs_of_self_harm = "";
      let uses_psychoactive_substances = "";
      if (array[index].last_psychosocial_accompaniment.is_studying != null) {
        is_studying = array[index].last_psychosocial_accompaniment.is_studying === true ? "Sim" : "Não";
      }
      if (array[index].last_psychosocial_accompaniment.need_school_reinsertion != null) {
        need_school_reinsertion =
          array[index].last_psychosocial_accompaniment.need_school_reinsertion === true ? "Sim" : "Não";
      }
      if (array[index].last_psychosocial_accompaniment.is_pregnant != null) {
        is_pregnant = array[index].last_psychosocial_accompaniment.is_pregnant === true ? "Sim" : "Não";
      }
      if (array[index].last_psychosocial_accompaniment.show_signs_of_self_harm != null) {
        show_signs_of_self_harm =
          array[index].last_psychosocial_accompaniment.show_signs_of_self_harm === true ? "Sim" : "Não";
      }
      if (array[index].last_psychosocial_accompaniment.uses_psychoactive_substances != null) {
        uses_psychoactive_substances =
          array[index].last_psychosocial_accompaniment.uses_psychoactive_substances === true ? "Sim" : "Não";
      }
      let disciplinary_sanctions_types = [];
      let disciplinary_sanctions_status = [];

      if (array[index].disciplinary_sanctions && array[index].disciplinary_sanctions.length > 0) {
        array[index].disciplinary_sanctions.forEach((item) => {
          disciplinary_sanctions_types.push(`Medida - ${item.sanction_type.name}`);
          disciplinary_sanctions_status.push(item.sanction_status.name);
        });
      }

      const arrayItem = new Array();
      arrayItem["season_name"] = season_name;
      arrayItem["status"] = status;
      arrayItem["student_area"] = student_area;
      arrayItem["student_equipment"] = student_equipment;
      arrayItem["student_name"] = student_name;
      arrayItem["student_cpf"] = student_cpf;
      arrayItem["student_email"] = student_email;
      arrayItem["student_neighborhood"] = student_neighborhood;
      arrayItem["last_socio_educational_measure"] = last_socio_educational_measure;
      arrayItem["student_age"] = student_age;
      arrayItem["student_percent"] = student_percent;
      arrayItem["situation_risk"] = situation_risk;
      arrayItem["attendant_team"] = attendant_team;
      arrayItem["matrix_situations"] = matrix_situations;
      arrayItem["matrix_problems"] = matrix_problems;
      arrayItem["last_grade_studied"] = last_grade_studied;
      arrayItem["last_school_studied"] = last_school_studied;
      arrayItem["last_school_studied"] = last_school_studied;
      arrayItem["last_school_studied"] = last_school_studied;
      arrayItem["reason_for_dropout_study"] = reason_for_dropout_study;
      arrayItem["reason_for_not_studying"] = reason_for_not_studying;
      arrayItem["justification_for_non_acceptance_date"] = justification_for_non_acceptance_date;
      arrayItem["justification_for_non_acceptance"] = justification_for_non_acceptance;
      arrayItem["is_studying"] = is_studying;
      arrayItem["need_school_reinsertion"] = need_school_reinsertion;
      arrayItem["is_pregnant"] = is_pregnant;
      arrayItem["show_signs_of_self_harm"] = show_signs_of_self_harm;
      arrayItem["uses_psychoactive_substances"] = uses_psychoactive_substances;
      arrayItem["disciplinary_sanctions_types"] = disciplinary_sanctions_types;
      arrayItem["disciplinary_sanctions_status"] = disciplinary_sanctions_status;
      arrayItem["user"] = user;
      arrayData.push(arrayItem);
    });

    if (state.togglePsychosocialAccompanimentPendingReception) {
      state.json_data_psychosocial_pending_reception = arrayData;
      state.hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponse = true;
      state.modalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet = true;
    }

    if (state.togglePsychosocialAccompanimentUnaccompanied) {
      state.json_data_psychosocial_unaccompanied = arrayData;
      state.hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse = true;
      state.modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet = true;
    }

    if (state.togglePsychosocialAccompanimentInAccompaniment) {
      state.json_data_psychosocial_in_accompaniment = arrayData;
      state.hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponse = true;
      state.modalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet = true;
    }
  },
  setPsychosocialEvolutionPageToExportMutation: (state, psychosocial_evolution) => {
    state.json_data_psychosocial_evolution = null;
    const arrayData = new Array();
    psychosocial_evolution.forEach((element, index, array) => {
      let student_name = array[index].inscription.student ? array[index].inscription.student.name : "";
      let student_cpf = array[index].inscription.student ? array[index].inscription.student.cpf : "";
      let student_email = array[index].inscription.student ? array[index].inscription.student.email : "";
      let date_accompaniment = moment(array[index].date_accompaniment).format("DD/MM/YYYY");
      let accompaniment_type = array[index].accompaniment_type ? array[index].accompaniment_type.name : "";
      let accompaniment_status = array[index].accompaniment_status
        ? array[index].accompaniment_status.name
        : "";
      let accompaniment_will_continue = array[index].accompaniment_will_continue ? "Sim" : "Não";
      let has_forwardings = array[index].forwarding.length > 0 ? "Sim" : "Não";
      let frequently_uses_health_network = array[index].frequently_uses_health_network ? "Sim" : "Não";
      let gestational_age = array[index].gestational_age ? array[index].gestational_age : "";
      let health_place_frequently = array[index].health_place_frequently
        ? array[index].health_place_frequently
        : "";
      let housing_building_material = array[index].housing_building_material
        ? array[index].housing_building_material.name
        : "";
      let housing_building_situation = array[index].housing_building_situation
        ? array[index].housing_building_situation.name
        : "";
      let housing_has_bathroom = array[index].housing_has_bathroom ? "Sim" : "Não";
      let housing_has_electricity = array[index].housing_has_electricity
        ? array[index].housing_has_electricity.name
        : "";
      let housing_has_garbage_collection = array[index].housing_has_garbage_collection ? "Sim" : "Não";
      let housing_has_gas_supply = array[index].housing_has_gas_supply
        ? array[index].housing_has_gas_supply.name
        : "";
      let housing_has_latrine = array[index].housing_has_latrine ? "Sim" : "Não";
      let housing_has_water_supply = array[index].housing_has_water_supply
        ? array[index].housing_has_water_supply.name
        : "";
      let housing_situation = array[index].housing_situation ? array[index].housing_situation.name : "";
      let housing_situation_comments = array[index].housing_situation_comments
        ? array[index].housing_situation_comments
        : "";
      let is_pregnant = array[index].is_pregnant ? "Sim" : "Não";
      let is_puerperium = array[index].is_puerperium ? "Sim" : "Não";
      let is_studying = array[index].is_studying ? "Sim" : "Não";
      let last_grade_studied = array[index].last_grade_studied ? array[index].last_grade_studied : "";
      let last_school_studied = array[index].last_school_studied ? array[index].last_school_studied : "";
      let has_matrix = array[index].matrix_risk_stratifications ? "Sim" : "Não";
      let meeting_format = array[index].meeting_format ? array[index].meeting_format : "";
      let meeting_happened = array[index].meeting_happened ? "Sim" : "Não";
      let meeting_not_happened_reason = array[index].meeting_not_happened_reason
        ? array[index].meeting_not_happened_reason.name
        : "";
      let meeting_place = array[index].meeting_place ? array[index].meeting_place : "";
      let meeting_remote_resource = array[index].meeting_remote_resource
        ? array[index].meeting_remote_resource
        : "";
      let motivation_uses_health_frequently = array[index].motivation_uses_health_frequently
        ? array[index].motivation_uses_health_frequently
        : "";
      let need_school_reinsertion = array[index].need_school_reinsertion ? "Sim" : "Não";
      let obey_socio_educational_measure = array[index].obey_socio_educational_measure ? "Sim" : "Não";
      let obeyed_socio_educational_measure = array[index].obeyed_socio_educational_measure ? "Sim" : "Não";
      let reason_for_dropout_study = array[index].reason_for_dropout_study
        ? array[index].reason_for_dropout_study
        : "";
      let reason_for_not_studying = array[index].reason_for_not_studying
        ? array[index].reason_for_not_studying
        : "";
      let justification_for_non_acceptance_date = array[index].justification_for_non_acceptance_date
        ? moment(array[index].justification_for_non_acceptance_date).format("DD/MM/YYYY")
        : "";
      let justification_for_non_acceptance = array[index].justification_for_non_acceptance
        ? array[index].justification_for_non_acceptance
        : "";
      let spontaneous_attendance = array[index].spontaneous_attendance ? "Sim" : "Não";
      let user = array[index].user ? array[index].user.name : "";
      let attendant_team = [];

      if (array[index].attendant_teams && array[index].attendant_teams.length > 0) {
        array[index].attendant_teams.forEach((item) => {
          attendant_team.push(item.name);
        });
      }

      const arrayItem = new Array();
      arrayItem["student_name"] = student_name;
      arrayItem["student_cpf"] = student_cpf;
      arrayItem["student_email"] = student_email;
      arrayItem["accompaniment_type"] = accompaniment_type;
      arrayItem["accompaniment_status"] = accompaniment_status;
      arrayItem["date_accompaniment"] = date_accompaniment;
      arrayItem["meeting_happened"] = meeting_happened;
      arrayItem["meeting_not_happened_reason"] = meeting_not_happened_reason;
      arrayItem["meeting_format"] = meeting_format;
      arrayItem["meeting_place"] = meeting_place;
      arrayItem["meeting_remote_resource"] = meeting_remote_resource;
      arrayItem["housing_situation"] = housing_situation;
      arrayItem["housing_building_material"] = housing_building_material;
      arrayItem["housing_building_situation"] = housing_building_situation;
      arrayItem["housing_has_bathroom"] = housing_has_bathroom;
      arrayItem["housing_has_electricity"] = housing_has_electricity;
      arrayItem["housing_has_garbage_collection"] = housing_has_garbage_collection;
      arrayItem["housing_has_gas_supply"] = housing_has_gas_supply;
      arrayItem["housing_has_latrine"] = housing_has_latrine;
      arrayItem["housing_has_water_supply"] = housing_has_water_supply;
      arrayItem["housing_situation_comments"] = housing_situation_comments;
      arrayItem["obey_socio_educational_measure"] = obey_socio_educational_measure;
      arrayItem["obeyed_socio_educational_measure"] = obeyed_socio_educational_measure;
      arrayItem["is_studying"] = is_studying;
      arrayItem["reason_for_dropout_study"] = reason_for_dropout_study;
      arrayItem["reason_for_not_studying"] = reason_for_not_studying;
      arrayItem["last_grade_studied"] = last_grade_studied;
      arrayItem["last_school_studied"] = last_school_studied;
      arrayItem["need_school_reinsertion"] = need_school_reinsertion;
      arrayItem["frequently_uses_health_network"] = frequently_uses_health_network;
      arrayItem["health_place_frequently"] = health_place_frequently;
      arrayItem["motivation_uses_health_frequently"] = motivation_uses_health_frequently;
      arrayItem["is_pregnant"] = is_pregnant;
      arrayItem["gestational_age"] = gestational_age;
      arrayItem["is_puerperium"] = is_puerperium;
      arrayItem["accompaniment_will_continue"] = accompaniment_will_continue;
      arrayItem["has_forwardings"] = has_forwardings;
      arrayItem["has_matrix"] = has_matrix;
      arrayItem["spontaneous_attendance"] = spontaneous_attendance;
      arrayItem["user"] = user;
      arrayItem["attendant_team"] = attendant_team;
      arrayItem["justification_for_non_acceptance_date"] = justification_for_non_acceptance_date;
      arrayItem["justification_for_non_acceptance"] = justification_for_non_acceptance;
      arrayData.push(arrayItem);
    });
    state.json_data_psychosocial_evolution = arrayData;
    state.hasSpreadsheetPsychosocialAccompanimentEvolutionResponse = true;
    state.modalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet = true;
  },

  // --> MUTATIONS - DELETION <--
  psychosocialAccompanimentIdForDeletion: (state, payload) => {
    if (payload) {
      state.psychosocialAccompanimentId = payload.id;
    }
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setSeasonMutation: (state, season) => {
    state.season = season;
    let season_information = {
      id: null,
      name: null,
    };
    if (season) {
      (season_information.id = season.id), (season_information.name = season.name);
    }
    sessionStorage.setItem("season_information", JSON.stringify(season_information));
  },
  setTabPsychosocialAccompanimentTypeMutation: (state, type) => {
    state.tabPsychosocialAccompanimentType = type;
  },
  setTabPsychosocialAccompanimentViewMutation: (state, type) => {
    state.tabPsychosocialAccompanimentView = type;
  },
  setRegisterForwardingMutation: (state, register_forwarding) => {
    state.register_forwarding = register_forwarding;
  },
  setRegisterVulnerabilityMatrixMutation: (state, register_vulnerability_matrix) => {
    state.register_vulnerability_matrix = register_vulnerability_matrix;
  },
  setRegisterParticipantPresenceMutation: (state, register_participant_presence) => {
    state.register_participant_presence = register_participant_presence;
  },
  setRegisterAttendatTeamsMutation: (state, register_attendant_teams) => {
    state.register_attendant_teams = register_attendant_teams;
  },
  setIgnoreMatrixRisk: (state, ignore_matrix_risk) => {
    state.ignore_matrix_risk = ignore_matrix_risk;
  },
  setLoadingInscriptions: (state, inscriptions) => {
    state.loading.inscriptions = inscriptions;
  },
  clearQueryMainPage: (state) => {
    state.params.main_page.cpf = null;
    state.params.main_page.inscription = null;
    state.params.main_page.equipment = null;
    state.params.main_page.area = null;
    state.params.main_page.state = null;
    state.params.main_page.city = null;
    state.params.main_page.first_service = null;
    state.params.main_page.status_accompaniment = null;
    state.params.main_page.accompaniment_will_continue = null;
    state.params.main_page.disciplinary_sanction = null;
    state.params.main_page.type_attendance = null;
    state.params.main_page.type_situation = null;
    state.params.main_page.user = null;
    state.params.main_page.is_pregnant = null;
    state.params.main_page.show_signs_of_self_harm = null;
    state.params.main_page.uses_substances = null;
    state.params.main_page.institutional_referral = null;
    state.params.main_page.matrix_situations = [];
    state.params.main_page.matrix_problems = [];
    state.user_areas_filtered = null;
    state.inscriptions = [];
  },
  clearQueryEvolutionPage: (state) => {
    state.params.evolution_page.type_attendance = null;
    state.params.evolution_page.type_status = null;
    state.psychosocial_accompaniments = [];
  },
  clearFields: (state) => {
    state.psychosocial_accompaniment.id = null;
    state.psychosocial_accompaniment.accompaniment_status = null;
    state.psychosocial_accompaniment.accompaniment_type = null;
    state.psychosocial_accompaniment.date_accompaniment = null;
    state.psychosocial_accompaniment.accompaniment_comments = "";
    state.psychosocial_accompaniment.accompaniment_origin_id = null;
    state.psychosocial_accompaniment.meeting_happened = false;
    state.psychosocial_accompaniment.meeting_not_happened_reason = null;
    state.psychosocial_accompaniment.meeting_format = null;
    state.psychosocial_accompaniment.meeting_place = null;
    state.psychosocial_accompaniment.meeting_remote_resource = null;
    state.psychosocial_accompaniment.meeting_conclusion_description = "";
    state.psychosocial_accompaniment.housing_situation_id = null;
    state.psychosocial_accompaniment.housing_situation_comments = "";
    state.psychosocial_accompaniment.housing_has_latrine = false;
    state.psychosocial_accompaniment.housing_has_bathroom = false;
    state.psychosocial_accompaniment.housing_has_garbage_collection = false;
    state.psychosocial_accompaniment.housing_building_situation = null;
    state.psychosocial_accompaniment.housing_building_material = null;
    state.psychosocial_accompaniment.housing_has_electricity = null;
    state.psychosocial_accompaniment.housing_has_water_supply = null;
    state.psychosocial_accompaniment.housing_has_gas_supply = null;
    state.psychosocial_accompaniment.obey_socio_educational_measure = false;
    state.psychosocial_accompaniment.obeyed_socio_educational_measure = false;
    state.psychosocial_accompaniment.is_studying = false;
    state.psychosocial_accompaniment.reason_for_not_studying = "";
    state.psychosocial_accompaniment.reason_for_dropout_study = "";
    state.psychosocial_accompaniment.last_grade_studied = "";
    state.psychosocial_accompaniment.last_school_studied = "";
    state.psychosocial_accompaniment.need_school_reinsertion = false;
    state.psychosocial_accompaniment.is_pregnant = false;
    state.psychosocial_accompaniment.gestational_age = null;
    state.psychosocial_accompaniment.is_puerperium = false;
    state.psychosocial_accompaniment.frequently_uses_health_network = false;
    state.psychosocial_accompaniment.health_place_frequently = "";
    state.psychosocial_accompaniment.motivation_uses_health_frequently = "";
    state.psychosocial_accompaniment.accompaniment_will_continue = false;
    state.psychosocial_accompaniment.accompaniment_guideline = "";
    state.psychosocial_accompaniment.spontaneous_attendance = false;
    state.psychosocial_accompaniment.housing_bathroom_drain_type = null;
    state.psychosocial_accompaniment.purpose_home_visit = null;
    state.psychosocial_accompaniment.social_family_context = null;
    state.psychosocial_accompaniment.social_economic_context = null;
    state.psychosocial_accompaniment.community_coexistence = null;
    state.psychosocial_accompaniment.socio_educational_measure_situation = null;
    state.psychosocial_accompaniment.justification_for_non_acceptance = null;
    state.psychosocial_accompaniment.gender = null;
    state.psychosocial_accompaniment.identifies_as_lgbtqiapn = null;
    state.psychosocial_accompaniment.gender_identity_observations = null;
    state.psychosocial_accompaniment.uses_psychoactive_substances = false;
    state.psychosocial_accompaniment.uses_psychoactive_substances_recreationally = false;
    state.psychosocial_accompaniment.self_medicate = false;
    state.psychosocial_accompaniment.show_signs_of_self_harm = false;
    state.psychosocial_accompaniment.show_signs_of_compromised_physical_health = false;
    state.psychosocial_accompaniment.show_signs_of_compromised_mental_health = false;
    state.psychosocial_accompaniment.is_half_open_socio_educational_measure = false;
    state.psychosocial_accompaniment.is_half_closed_socio_educational_measure = false;
    state.psychosocial_accompaniment.is_young_father = false;
    state.psychosocial_accompaniment.is_young_mother = false;
    state.psychosocial_accompaniment.number_of_residents = 0;
    state.psychosocial_accompaniment.disciplinary_sanctions_attributes = [];
    state.accompaniment_origin = null;
    state.housing_situation = null;
    state.forwarding = null;
    state.accompaniments_type = null;
    state.accompaniment_status = null;
    state.housing_building_situation = null;
    state.housing_building_material = null;
    state.housing_has_electricity = null;
    state.housing_has_water_supply = null;
    state.housing_has_garbage_collection = null;
    state.housing_has_gas_supply = null;
    state.housing_bathroom_drain_type = null;
    state.socio_educational_measure_situation = null;
    state.gender = null;
    state.identifies_as_lgbtqiapn = null;
    state.meeting_not_happened_reason = null;
    state.register_forwarding = false;
    state.register_vulnerability_matrix = false;
    state.register_participant_presence = false;
    state.register_attendant_teams = false;
    state.continued_accompaniment.id = null;
    state.continued_accompaniment.inscription_id = null;
    state.continued_accompaniment.accompaniment_status = null;
    state.continued_accompaniment.accompaniment_type = null;
    state.continued_accompaniment.date_accompaniment = null;
    state.continued_accompaniment.accompaniment_origin_id = null;
    state.continued_accompaniment.user_id = null;
    state.matrix_risk_stratifications = [];
    state.disciplinary_sanctions_attributes = [
      {
        id: null,
        sanction_type: null,
        sanction_status: null,
        compliance_mode: null,
        penalty_type: null,
        completion_time: null,
        completion_forecast: null,
        compliance_location: null,
        _destroy: false,
        disciplinary_sanctions_measures_attributes: [],
      },
    ];
  },
  clearFieldsIfMeetingNotHappened: (state) => {
    state.psychosocial_accompaniment.accompaniment_comments = "";
    state.psychosocial_accompaniment.meeting_format = "";
    state.psychosocial_accompaniment.meeting_place = "";
    state.psychosocial_accompaniment.meeting_remote_resource = "";
    state.psychosocial_accompaniment.housing_situation_id = null;
    state.psychosocial_accompaniment.housing_situation_comments = "";
    state.psychosocial_accompaniment.housing_has_latrine = false;
    state.psychosocial_accompaniment.housing_has_bathroom = false;
    state.psychosocial_accompaniment.housing_has_garbage_collection = false;
    state.psychosocial_accompaniment.housing_building_situation = null;
    state.psychosocial_accompaniment.housing_building_material = null;
    state.psychosocial_accompaniment.housing_has_electricity = null;
    state.psychosocial_accompaniment.housing_has_water_supply = null;
    state.psychosocial_accompaniment.housing_has_gas_supply = null;
    state.psychosocial_accompaniment.obey_socio_educational_measure = false;
    state.psychosocial_accompaniment.obeyed_socio_educational_measure = false;
    state.psychosocial_accompaniment.is_studying = false;
    state.psychosocial_accompaniment.reason_for_not_studying = "";
    state.psychosocial_accompaniment.reason_for_dropout_study = "";
    state.psychosocial_accompaniment.last_grade_studied = "";
    state.psychosocial_accompaniment.last_school_studied = "";
    state.psychosocial_accompaniment.need_school_reinsertion = false;
    state.psychosocial_accompaniment.is_pregnant = false;
    state.psychosocial_accompaniment.gestational_age = null;
    state.psychosocial_accompaniment.is_puerperium = false;
    state.psychosocial_accompaniment.frequently_uses_health_network = false;
    state.psychosocial_accompaniment.health_place_frequently = "";
    state.psychosocial_accompaniment.motivation_uses_health_frequently = "";
    state.psychosocial_accompaniment.accompaniment_will_continue = false;
    state.psychosocial_accompaniment.accompaniment_guideline = "";
    state.psychosocial_accompaniment.spontaneous_attendance = false;
    state.psychosocial_accompaniment.housing_bathroom_drain_type = null;
    state.psychosocial_accompaniment.purpose_home_visit = null;
    state.psychosocial_accompaniment.social_family_context = null;
    state.psychosocial_accompaniment.social_economic_context = null;
    state.psychosocial_accompaniment.community_coexistence = null;
    state.psychosocial_accompaniment.socio_educational_measure_situation = null;
    state.psychosocial_accompaniment.gender = null;
    state.psychosocial_accompaniment.identifies_as_lgbtqiapn = null;
    state.psychosocial_accompaniment.gender_identity_observations = null;
    state.psychosocial_accompaniment.uses_psychoactive_substances = false;
    state.psychosocial_accompaniment.uses_psychoactive_substances_recreationally = false;
    state.psychosocial_accompaniment.self_medicate = false;
    state.psychosocial_accompaniment.show_signs_of_self_harm = false;
    state.psychosocial_accompaniment.show_signs_of_compromised_physical_health = false;
    state.psychosocial_accompaniment.show_signs_of_compromised_mental_health = false;
    state.psychosocial_accompaniment.health_situation_observations = null;
    state.psychosocial_accompaniment.is_half_open_socio_educational_measure = false;
    state.psychosocial_accompaniment.is_half_closed_socio_educational_measure = false;
    state.psychosocial_accompaniment.is_young_father = false;
    state.psychosocial_accompaniment.is_young_mother = false;
    state.psychosocial_accompaniment.number_of_residents = 0;
    state.psychosocial_accompaniment.disciplinary_sanctions_attributes = [];
    state.disciplinary_sanctions_attributes = [
      {
        id: null,
        sanction_type: null,
        sanction_status: null,
        compliance_mode: null,
        penalty_type: null,
        completion_time: null,
        completion_forecast: null,
        compliance_location: null,
        _destroy: false,
        disciplinary_sanctions_measures_attributes: [],
      },
    ];
    state.continued_accompaniment.id = null;
    state.continued_accompaniment.inscription_id = null;
    state.continued_accompaniment.accompaniment_status = null;
    state.continued_accompaniment.accompaniment_type = null;
    state.continued_accompaniment.date_accompaniment = null;
    state.continued_accompaniment.accompaniment_origin_id = null;
    state.continued_accompaniment.user_id = null;
  },
  clearFieldsIfIsNotStudying: (state) => {
    state.psychosocial_accompaniment.reason_for_not_studying = "";
    state.psychosocial_accompaniment.reason_for_dropout_study = "";
    state.psychosocial_accompaniment.last_grade_studied = "";
    state.psychosocial_accompaniment.last_school_studied = "";
  },
  clearFieldsIfIsNotPregnant: (state) => {
    state.psychosocial_accompaniment.gestational_age = null;
  },
  clearFieldsIfNotFrequentlyUsesHealthNetwork: (state) => {
    state.psychosocial_accompaniment.health_place_frequently = "";
    state.psychosocial_accompaniment.motivation_uses_health_frequently = "";
  },
  clearFieldsIfAccompanimentWillNotContinue: (state) => {
    state.continued_accompaniment.id = null;
    state.continued_accompaniment.inscription_id = null;
    state.continued_accompaniment.accompaniment_status = null;
    state.continued_accompaniment.accompaniment_type = null;
    state.continued_accompaniment.date_accompaniment = null;
    state.continued_accompaniment.accompaniment_origin_id = null;
    state.continued_accompaniment.user_id = null;
  },
  clearFieldsForwarding: (state) => {
    state.forwarding_attributes = [
      {
        id: null,
        forwarding_policy_id: null,
        inscription_id: null,
        psychosocial_accompaniment_id: null,
        name: null,
        forwarding_description: "",
        forwarding_date: moment().format("YYYY-MM-DD"),
        forwarding_status: 0,
        forwarding_expiration_date: moment().add(15, "days").format("YYYY-MM-DD"),
        forwarding_user_id: null,
        differenceBetweenDates: 15,
        city_id: null,
      },
    ];
    state.forwarding_policy_attributes = [
      {
        id: null,
        name: null,
      },
    ];
    state.forwarding_facilities_attributes = [
      {
        id: null,
        name: null,
        address: null,
      },
    ];
    state.facilities = [{ facilities: null }];
  },
  clearFieldsParticipantPresence: (state) => {
    state.participant_presence_attributes = [
      {
        id: null,
        name: null,
        psychosocial_accompaniment_id: null,
        relationship_degree: null,
      },
    ];
    state.relationship_degree_attributes = [
      {
        id: null,
        name: null,
      },
    ];
  },
  clearFieldsAttendantTeam: (state) => {
    state.attendant_teams_attributes = [
      {
        id: null,
        psychosocial_attendant_id: null,
        psychosocial_accompaniment_id: null,
      },
    ];
    state.psychosocial_attendant_attributes = [
      {
        id: null,
        name: null,
      },
    ];
  },
};

function getVariablesToLoadInscriptions() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  let matrix_situation_ids = [];
  let matrix_problem_ids = [];
  let first_service_type;
  let accompanimentWillContinue;
  let typeStatus;
  let typeAttendance;
  let typeSituation;
  let accompaniedType = "inAccompaniment";
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }
  if (state.params.main_page.matrix_situations) {
    state.params.main_page.matrix_situations.forEach((item) => {
      matrix_situation_ids.push(item.id);
    });
  }
  if (state.params.main_page.matrix_problems) {
    state.params.main_page.matrix_problems.forEach((item) => {
      matrix_problem_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }

  if (state.params.main_page.first_service) {
    first_service_type = state.params.main_page.first_service.name;
  }

  if (state.params.main_page.accompaniment_will_continue) {
    accompanimentWillContinue = state.params.main_page.accompaniment_will_continue.name;
  }

  if (state.params.main_page.status_accompaniment) {
    typeStatus = state.params.main_page.status_accompaniment.id;
  }

  if (state.params.main_page.type_attendance) {
    typeAttendance = state.params.main_page.type_attendance.id;
  }

  if (state.params.main_page.equipment) {
    user_equipments_ids = state.params.main_page.equipment.id;
  }

  if (state.params.main_page.type_situation) {
    typeSituation = state.params.main_page.type_situation.name;
  }

  if (state.togglePsychosocialAccompanimentPendingReception) {
    accompaniedType = "pendingReception";
  }
  if (state.togglePsychosocialAccompanimentUnaccompanied) {
    accompaniedType = "unaccompanied";
  }
  if (state.togglePsychosocialAccompanimentInAccompaniment) {
    accompaniedType = "inAccompaniment";
  }

  const has_disciplinary_sanction = state.params.main_page.disciplinary_sanction?.id;

  return {
    user_areas_ids,
    user_equipments_ids,
    matrix_situation_ids,
    matrix_problem_ids,
    first_service_type,
    accompanimentWillContinue,
    typeStatus,
    typeAttendance,
    typeSituation,
    accompaniedType,
    has_disciplinary_sanction,
  };
}

function createOrUpdateForwarding(response, dispatch, commit) {
  if (state.register_forwarding) {
    if (state.forwarding_attributes.length > 0) {
      let forwarding_object = {
        id: null,
        forwarding_policy_id: null,
        facility_id: null,
        inscription_id: null,
        psychosocial_accompaniment_id: null,
        forwarding_description: "",
        forwarding_date: null,
        forwarding_status: 0,
        forwarding_expiration_date: null,
        forwarding_user_id: null,
        city_id: state.inscription.student.city ? state.inscription.student.city.id : null,
      };
      state.forwarding_attributes.forEach((item) => {
        forwarding_object.forwarding_policy_id = item.forwarding_policy_id;
        forwarding_object.facility_id = item.facility_id;
        forwarding_object.forwarding_equipment = item.forwarding_equipment;
        forwarding_object.forwarding_description = item.forwarding_description;
        forwarding_object.forwarding_status = item.forwarding_status;
        forwarding_object.forwarding_date = item.forwarding_date;
        forwarding_object.forwarding_expiration_date = item.forwarding_expiration_date;
        forwarding_object.psychosocial_accompaniment_id = response.data.psychosocial_accompaniment.id;
        forwarding_object.forwarding_user_id = response.data.psychosocial_accompaniment.user_id;
        forwarding_object.inscription_id = response.data.psychosocial_accompaniment.inscription_id;
        if (item.id) {
          updateForwarding(item.id, forwarding_object)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel atualizar o encaminhamento! " + e.response.data.error);
              console.log(e);
            });
        } else {
          createForwarding(forwarding_object)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel cadastrar o encaminhamento! " + e.response.data.error);
              console.log(e);
            });
        }
      });
    }
    dispatch("Forwardings/loadForwardings", {}, { root: true });
  } else {
    commit("clearFieldsForwarding");
  }
}

function createOrUpdateParticipantPresence(response, commit) {
  if (state.register_participant_presence) {
    if (state.participant_presence_attributes.length > 0) {
      let participant_presence_object = {
        id: null,
        name: null,
        psychosocial_accompaniment_id: null,
        relationship_degree: null,
      };
      state.participant_presence_attributes.forEach((item) => {
        participant_presence_object.name = item.name;
        participant_presence_object.relationship_degree = item.relationship_degree;
        participant_presence_object.psychosocial_accompaniment_id =
          response.data.psychosocial_accompaniment.id;

        if (item.id) {
          updateParticipantPresence(item.id, participant_presence_object)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel cadastrar a pessoa presente! " + e.response.data.error);
              console.log(e);
            });
        } else {
          createParticipantPresence(participant_presence_object)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel cadastrar a pessoa presente! " + e.response.data.error);
              console.log(e);
            });
        }
      });
    }
  } else {
    commit("clearFieldsParticipantPresence");
  }
}

function createOrUpdateAttendantTeams(response, commit) {
  if (state.register_attendant_teams) {
    if (state.attendant_teams_attributes.length > 0) {
      let attendant_team_object = {
        id: null,
        psychosocial_attendant_id: null,
        psychosocial_accompaniment_id: null,
      };
      state.attendant_teams_attributes.forEach((item) => {
        attendant_team_object.psychosocial_attendant_id = item.psychosocial_attendant_id;
        attendant_team_object.psychosocial_accompaniment_id = response.data.psychosocial_accompaniment.id;

        if (item.id) {
          updateAttendantTeam(item.id, attendant_team_object)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel informar o profissional! " + e.response.data.error);
              console.log(e);
            });
        } else {
          createAttendantTeam(attendant_team_object)
            .then(() => {})
            .catch((e) => {
              Vue.$toast.error("Não foi possivel informar o profissional! " + e.response.data.error);
              console.log(e);
            });
        }
      });
    }
  } else {
    commit("clearFieldsAttendantTeam");
  }
}

function createOrUpdateAtendimentWillContinue(dispatch, response, params, commit) {
  if (state.psychosocial_accompaniment.accompaniment_will_continue) {
    let status = {
      id: 1,
      name: "Agendado",
    };
    dispatch("setPsychosocialAccompanimentStatusContinuedAccompaniment", status);
    dispatch("setInscriptionContinuedAccompaniment", response.data.psychosocial_accompaniment.inscription_id);
    dispatch("setUserContinuedAccompaniment", response.data.psychosocial_accompaniment.user_id);
    dispatch("setAccompanimentOriginIdContinuedAccompaniment", response.data.psychosocial_accompaniment.id);

    createPsychosocialAccompaniment(state.continued_accompaniment)
      .then(() => {
        dispatch("loadPsychosocialAccompanimentsByInscription", params);
      })
      .catch((e) => {
        Vue.$toast.error(e);
      });
  } else {
    commit("clearFieldsIfAccompanimentWillNotContinue");
  }
}

function registerVulnerabilityMatrix(dispatch, response) {
  if (state.register_vulnerability_matrix) {
    dispatch(
      "MatrixRiskStratifications/setPsychosocialAccompaniment",
      response.data.psychosocial_accompaniment,
      {
        root: true,
      }
    );
    dispatch(
      "MatrixRiskStratifications/save",
      {},
      {
        root: true,
      }
    );
  }
}

function getParticipantPresenceAttributes() {
  let participant_presence_attribute = false;
  state.participant_presence_attributes.forEach(function (obj) {
    if (obj.name === null || obj.name === "" || obj.relationship_degree === null) {
      participant_presence_attribute = true;
    }
  });
  return participant_presence_attribute;
}

function getForwardingAttributes() {
  let forwarding_attribute = false;
  state.forwarding_attributes.forEach((item) => {
    if (
      item.forwarding_policy_id === null ||
      item.forwarding_date === null ||
      item.forwarding_expiration_date === null
    ) {
      forwarding_attribute = true;
    }
  });
  return forwarding_attribute;
}

function getAttendantTeamAttributes() {
  let attendant_team_attribute = false;
  state.attendant_teams_attributes.forEach(function (obj) {
    if (obj.psychosocial_attendant_id === null) {
      attendant_team_attribute = true;
    }
  });
  return attendant_team_attribute;
}

function updatePsychosocialAccompanimentLogic(
  rootState,
  forwarding_attribute,
  attendant_team_attribute,
  participant_presence_attribute,
  commit,
  dispatch
) {
  if (
    (state.psychosocial_accompaniment.accompaniment_will_continue &&
      (!state.continued_accompaniment.accompaniment_type ||
        !state.continued_accompaniment.date_accompaniment)) ||
    (state.register_vulnerability_matrix &&
      rootState.MatrixRiskStratifications.matrix_situations_array.length < 1 &&
      !state.matrix_risk_stratifications_by_psychosocial) ||
    (state.register_forwarding && forwarding_attribute) ||
    (state.register_attendant_teams && attendant_team_attribute) ||
    (state.register_participant_presence && participant_presence_attribute)
  ) {
    if (
      state.psychosocial_accompaniment.accompaniment_will_continue &&
      (!state.continued_accompaniment.accompaniment_type || !state.continued_accompaniment.date_accompaniment)
    ) {
      Vue.$toast.error(
        "Ao informar que o atendimento vai continuar, é necessário preencher todos os campos obrigatórios do próximo encontro!"
      );
    }
    if (
      state.register_vulnerability_matrix &&
      rootState.MatrixRiskStratifications.matrix_situations_array.length < 1 &&
      !state.matrix_risk_stratifications_by_psychosocial
    ) {
      Vue.$toast.error(
        "Ao informar que deseja cadastrar a situação de vulnerabilidade, é necessário marcar pelo menos um item da matriz!"
      );
    }
    if (state.register_forwarding && forwarding_attribute) {
      Vue.$toast.error(
        "Ao informar que deseja cadastrar um encaminhamento ou mais, é necessário informar todos os campos marcados como obrigatório!"
      );
    }
    if (state.register_participant_presence && participant_presence_attribute) {
      Vue.$toast.error(
        "Ao informar que deseja cadastrar os participantes presentes, é necessário informar todos os campos marcados como obrigatório!"
      );
    }
    if (state.register_attendant_teams && attendant_team_attribute) {
      Vue.$toast.error(
        "Ao informar que deseja cadastrar os profissionais que acompanham, é necessário informar todos os campos marcados como obrigatório!"
      );
    }
  } else {
    if (state.psychosocial_accompaniment.meeting_happened === false) {
      commit("clearFieldsIfMeetingNotHappened");

      if (!state.modalTogglePsychosocialAccompanimentPostponeDate) {
        let status = {
          id: 3,
          name: "Não Atendido",
        };
        dispatch("setPsychosocialAccompanimentStatus", status);
      } else {
        let status = {
          id: 1,
          name: "Agendado",
        };
        dispatch("setPsychosocialAccompanimentStatus", status);
      }
    } else {
      if (state.psychosocial_accompaniment.is_studying === true) {
        commit("clearFieldsIfIsNotStudying");
      }
      if (state.psychosocial_accompaniment.is_pregnant === false) {
        commit("clearFieldsIfIsNotPregnant");
      }
      if (state.psychosocial_accompaniment.frequently_uses_health_network === false) {
        commit("clearFieldsIfNotFrequentlyUsesHealthNetwork");
      }

      if (!state.modalTogglePsychosocialAccompanimentPostponeDate) {
        let status = {
          id: 0,
          name: "Atendido",
        };
        dispatch("setPsychosocialAccompanimentStatus", status);
      } else {
        let status = {
          id: 1,
          name: "Agendado",
        };
        dispatch("setPsychosocialAccompanimentStatus", status);
      }
    }
    updatePsychosocialAccompaniment(state.psychosocial_accompaniment.id, state.psychosocial_accompaniment)
      .then((response) => {
        let params = {
          inscription_id: state.inscription.id,
        };
        createOrUpdateForwarding(response, dispatch, commit);
        createOrUpdateParticipantPresence(response, commit);
        createOrUpdateAttendantTeams(response, commit);
        createOrUpdateAtendimentWillContinue(dispatch, response, params, commit);
        registerVulnerabilityMatrix(dispatch, response);

        if (!state.modalTogglePsychosocialAccompanimentPostponeDate) {
          Vue.$toast.success("Acompanhamento atualizado com sucesso!");
        }
        if (state.modalToggleFirstPsychosocialAccompaniment) {
          dispatch("closeModalFirstPsychosocialAccompaniment");
        }
        if (state.modalTogglePsychosocialIndividualMonitoring) {
          dispatch("closeEditModalPsychosocialIndividualMonitoring");
        }
        if (state.modalTogglePsychosocialHomeVisit) {
          dispatch("closeEditModalPsychosocialHomeVisit");
        }
        if (state.modalTogglePsychosocialAccompanimentJustifyNonReception) {
          dispatch("closeEditModalPsychosocialAccompanimentJustifyNonReception");
        }
        if (state.modalTogglePsychosocialAccompanimentPostponeDate) {
          dispatch("closeModalPsychosocialAccompanimentPostponeDate");
          Vue.$toast.success("Data do encontro reagendada com sucesso!");
        }
        if (state.modalConfirmSavePsychosocialAccompanimentWithoutMatrix) {
          commit("toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix");
        }

        dispatch("loadPsychosocialAccompanimentsByInscription", params);
        commit("clearFieldsForwarding");
        commit("clearFieldsParticipantPresence");
        commit("clearFieldsAttendantTeam");

        redirectAfterCreateOrUpdate.call(this, response, dispatch);
        dispatch("loadInscriptions");
      })
      .catch((e) => {
        console.log("e", e);
        Vue.$toast.error(e.response.data.error + "!");
      });
  }
}

function redirectAfterCreateOrUpdate(response, dispatch) {
  if (response.data.psychosocial_accompaniment.accompaniment_type === "unaccompanied") {
    dispatch("setTabPsychosocialAccompanimentView", "unaccompanied");
    router.push("/psicossocial").catch(() => {});
    router.go();
  }
}

async function createPsychosocialAccompanimentLogic(
  rootState,
  forwarding_attribute,
  participant_presence_attribute,
  attendant_team_attribute,
  commit,
  dispatch
) {
  const state = rootState.PsychosocialAccompaniments;

  const showError = (condition, message) => {
    if (condition) Vue.$toast.error(message);
  };

  const hasErrors = () => {
    showError(
      state.psychosocial_accompaniment.accompaniment_will_continue &&
        (!state.continued_accompaniment.accompaniment_type ||
          !state.continued_accompaniment.date_accompaniment),
      "Ao informar que o atendimento vai continuar, é necessário preencher todos os campos obrigatórios do próximo encontro!"
    );
    showError(
      state.register_vulnerability_matrix &&
        rootState.MatrixRiskStratifications.matrix_situations_array.length < 1,
      "Ao informar que deseja cadastrar a situação de vulnerabilidade, é necessário marcar pelo menos um item da matriz!"
    );
    showError(
      state.register_forwarding && forwarding_attribute,
      "Ao informar que deseja cadastrar um encaminhamento ou mais, é necessário informar todos os campos marcados como obrigatório!"
    );
    showError(
      state.register_participant_presence && participant_presence_attribute,
      "Ao informar que deseja cadastrar os participantes presentes, é necessário informar todos os campos marcados como obrigatório!"
    );
    showError(
      state.register_attendant_teams && attendant_team_attribute,
      "Ao informar que deseja cadastrar os profissionais que acompanham, é necessário informar todos os campos marcados como obrigatório!"
    );

    if (
      !state.register_vulnerability_matrix &&
      !state.ignore_matrix_risk &&
      state.psychosocial_accompaniment.meeting_happened
    ) {
      commit("toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix");
      return true;
    }

    return false;
  };

  if (hasErrors()) return;

  const setAccompanimentStatus = async () => {
    const data = Date.parse(state.psychosocial_accompaniment.date_accompaniment);
    const today = Date.now();
    let status = { id: 1, name: "Agendado" };

    if (state.psychosocial_accompaniment.meeting_happened === false) {
      commit("clearFieldsIfMeetingNotHappened");
      dispatch("loadDataFromFirstService");
      status = data > today ? status : { id: 3, name: "Não Atendido" };
    } else {
      if (!state.modalToggleAddAttendance && data <= today) {
        status = { id: 0, name: "Atendido" };
      }
      if (state.psychosocial_accompaniment.is_studying) {
        commit("clearFieldsIfIsNotStudying");
      }
      if (!state.psychosocial_accompaniment.is_pregnant) {
        commit("clearFieldsIfIsNotPregnant");
      }
      if (!state.psychosocial_accompaniment.frequently_uses_health_network) {
        commit("clearFieldsIfNotFrequentlyUsesHealthNetwork");
      }
    }
    await dispatch("setPsychosocialAccompanimentStatus", status);
  };

  await setAccompanimentStatus();
  createPsychosocialAccompaniment(state.psychosocial_accompaniment)
    .then((response) => {
      Vue.$toast.success("Atendimento cadastrado com sucesso!");
      const params = { inscription_id: response.data.psychosocial_accompaniment.inscription_id };

      const updateRelatedData = () => {
        createOrUpdateForwarding(response, dispatch, commit);
        createOrUpdateAttendantTeams(response, commit);
        createOrUpdateParticipantPresence(response, commit);
        createOrUpdateAtendimentWillContinue(dispatch, response, params, commit);
        registerVulnerabilityMatrix(dispatch, response);
      };

      updateRelatedData();

      const closeModalsAndClearFields = () => {
        if (state.modalToggleFirstPsychosocialAccompaniment)
          dispatch("closeModalFirstPsychosocialAccompaniment");
        if (state.modalTogglePsychosocialIndividualMonitoring)
          dispatch("closeEditModalPsychosocialIndividualMonitoring");
        if (state.modalTogglePsychosocialHomeVisit) dispatch("closeEditModalPsychosocialHomeVisit");
        if (state.modalToggleAddAttendance) dispatch("closeModalAddAttendance");
        if (state.modalTogglePsychosocialAccompanimentJustifyNonReception)
          dispatch("closeEditModalPsychosocialAccompanimentJustifyNonReception");
        if (state.modalConfirmSavePsychosocialAccompanimentWithoutMatrix)
          commit("toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix");

        commit("setIgnoreMatrixRisk", false);
        dispatch("loadPsychosocialAccompanimentsByInscription", params);
        commit("clearFieldsForwarding");
        commit("clearFieldsParticipantPresence");
        commit("clearFieldsAttendantTeam");
      };

      closeModalsAndClearFields();

      redirectAfterCreateOrUpdate.call(this, response, dispatch);
      dispatch("loadInscriptions");
    })
    .catch((e) => {
      Vue.$toast.error("Não foi possivel cadastrar o atendimento! " + e.response.data.error);
      console.log(e);
    });
}

function getLoadInscriptionsParams(
  user_areas_ids,
  user_equipments_ids,
  matrix_situation_ids,
  matrix_problem_ids,
  first_service_type,
  typeStatus,
  typeAttendance,
  typeSituation,
  accompanimentWillContinue,
  accompaniedType,
  rootGetters
) {
  return {
    season_id: state.season?.id,
    area_id: user_areas_ids,
    cpf: state.params.main_page.cpf,
    name_student: state.params.main_page.inscription ? state.params.main_page.inscription.name : null,
    city_id:
      state.params.main_page.city && state.params.main_page.city.id
        ? state.params.main_page.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    equipment_id: user_equipments_ids,
    matrix_situation_ids: matrix_situation_ids,
    matrix_problem_ids: matrix_problem_ids,
    first_service: first_service_type,
    type_status: typeStatus,
    type_attendance: typeAttendance,
    type_situation: typeSituation,
    accompaniment_will_continue: accompanimentWillContinue,
    resource: "psychosocial_accompaniments",
    accompanied_type: accompaniedType,
    perPage: state.totalPerPage,
    offset: state.offset,
    user_id: state.params.main_page.user ? state.params.main_page.user.id : null,
    has_disciplinary_sanction: state.params.main_page.disciplinary_sanction
      ? state.params.main_page.disciplinary_sanction.id
      : null,
    is_pregnant: state.params.main_page.is_pregnant ? state.params.main_page.is_pregnant.id : null,
    show_signs_of_self_harm: state.params.main_page.show_signs_of_self_harm
      ? state.params.main_page.show_signs_of_self_harm.id
      : null,
    uses_substances: state.params.main_page.uses_substances
      ? state.params.main_page.uses_substances.id
      : null,
    institutional_referral_id: state.params.main_page.institutional_referral
      ? state.params.main_page.institutional_referral.id
      : null,
    by: "students.name",
    order: "ASC",
  };
}

const actions = {
  loadSeasons: ({ commit }) => {
    getSeasonsForMenus()
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadUsers: ({ commit }) => {
    const params = {
      ability_profile_id: 7,
    };
    getUsersForPsychosocial(params)
      .then((response) => {
        commit("setUsersMutation", response.data.user);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      area_id: state.user_areas_filtered.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsForPsychosocialMutation", equipments);
        commit("filterListByEquipment", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptionsForGeneralMenus: ({ commit }, value) => {
    let { user_areas_ids } = getVariablesToLoadInscriptions();
    const params = {
      participant_name: value,
      area_id: user_areas_ids,
      season_id: state.season?.id,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForGeneralMenus(params)
      .then((response) => {
        commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInscriptions: async ({ commit, rootGetters }) => {
    commit("setLoadingInscriptions", true);
    let {
      user_areas_ids,
      user_equipments_ids,
      matrix_situation_ids,
      matrix_problem_ids,
      first_service_type,
      accompanimentWillContinue,
      typeStatus,
      typeAttendance,
      typeSituation,
      accompaniedType,
    } = getVariablesToLoadInscriptions();

    const params = getLoadInscriptionsParams(
      user_areas_ids,
      user_equipments_ids,
      matrix_situation_ids,
      matrix_problem_ids,
      first_service_type,
      typeStatus,
      typeAttendance,
      typeSituation,
      accompanimentWillContinue,
      accompaniedType,
      rootGetters
    );
    await getInscriptionBySeasonAndArea(params)
      .then(async (response) => {
        if (response.data.inscriptionsTotal < 1) {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
        await commit("setInscriptionsMutation", response.data.inscriptions);
        await commit("setInscriptionsTotal", response.data.inscriptionsTotal);
        commit("setLoadingInscriptions", false);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        commit("setLoadingInscriptions", false);
      });
  },
  loadPsychosocialMaingPageSpreadsheet: async ({ commit, rootGetters }) => {
    if (state.togglePsychosocialAccompanimentPendingReception)
      commit("hasSpreadsheetPsychosocialAccompanimentPendingReceptionResponseMutation", false);
    if (state.togglePsychosocialAccompanimentUnaccompanied)
      commit("hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponseMutation", false);
    if (state.togglePsychosocialAccompanimentInAccompaniment)
      commit("hasSpreadsheetPsychosocialAccompanimentInAccompanimentResponseMutation", false);

    let {
      user_areas_ids,
      user_equipments_ids,
      matrix_situation_ids,
      matrix_problem_ids,
      first_service_type,
      accompanimentWillContinue,
      typeStatus,
      typeAttendance,
      typeSituation,
      accompaniedType,
    } = getVariablesToLoadInscriptions();

    const params = getLoadInscriptionsParams(
      user_areas_ids,
      user_equipments_ids,
      matrix_situation_ids,
      matrix_problem_ids,
      first_service_type,
      typeStatus,
      typeAttendance,
      typeSituation,
      accompanimentWillContinue,
      accompaniedType,
      rootGetters
    );
    getInscriptionsForPsychosocialAccompanimentsSpreadsheet(params)
      .then(async (response) => {
        if (state.togglePsychosocialAccompanimentPendingReception)
          commit(
            "setPsychosocialAccompanimentPendingReceptionSpreadsheetMutation",
            response.data.inscriptions
          );
        if (state.togglePsychosocialAccompanimentUnaccompanied)
          commit("setPsychosocialAccompanimentUnaccompaniedSpreadsheetMutation", response.data.inscriptions);
        if (state.togglePsychosocialAccompanimentInAccompaniment)
          commit(
            "setPsychosocialAccompanimentInAccompanimentSpreadsheetMutation",
            response.data.inscriptions
          );

        if (
          state.psychosocial_accompaniments_pending_reception_spreadsheet.length > 0 ||
          state.psychosocial_accompaniments_unaccompanied_spreadsheet.length > 0 ||
          state.psychosocial_accompaniments_in_accompaniment_spreadsheet.length > 0
        ) {
          await commit("setPsychosocialMainPageToExportMutation", response.data.inscriptions);
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptionByIdForPsychosocialAccompaniment: async ({ commit }, inscription_id) => {
    const params = {
      id: inscription_id,
      resource: "psychosocial_accompaniment",
    };
    await getInscriptionByIdAndResource(params)
      .then(async (response) => {
        await commit("setInscriptionMutation", response.data.inscription);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadPsychosocialAccompanimentsByInscription: async ({ commit }) => {
    const params = {
      inscription_id: sessionStorage.inscription_id,
      type_attendance: state.params.evolution_page.type_attendance
        ? state.params.evolution_page.type_attendance.id
        : null,
      type_status: state.params.evolution_page.type_status
        ? state.params.evolution_page.type_status.id
        : null,
      perPage: state.psychosocialAccompanimentsByInscriptionTotalPerPage,
      offset: state.psychosocialAccompanimentsByInscriptionOffset,
    };
    await getPsychosocialAccompanimentByInscription(params)
      .then(async (response) => {
        await commit("setPsychosocialAccompanimentsMutation", response.data.psychosocial_accompaniments);
        await commit(
          "setPsychosocialAccompanimentByInscriptionsTotal",
          response.data.psychosocial_accompaniments_total
        );
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadPsychosocialAccompanimentsByInscriptionSpreadsheet: async ({ commit }) => {
    commit("hasSpreadsheetPsychosocialAccompanimentEvolutionResponseMutation", false);
    let type_attendance_filtered = null;
    if (state.params.evolution_page.type_attendance) {
      type_attendance_filtered = state.params.evolution_page.type_attendance.id;
    }
    const params = {
      inscription_id: sessionStorage.inscription_id,
      type_attendance: type_attendance_filtered,
    };
    await getPsychosocialAccompanimentByInscriptionSpreadsheet(params)
      .then(async (response) => {
        commit(
          "setPsychosocialAccompanimentEvolutionSpreadsheetMutation",
          response.data.psychosocial_accompaniments
        );
        if (state.psychosocial_accompaniments_evolution_spreadsheet.length > 0) {
          await commit(
            "setPsychosocialEvolutionPageToExportMutation",
            response.data.psychosocial_accompaniments
          );
        }
      })
      .catch((e) => {
        console.log(e);
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadPsychosocialAccompanimentsTypes: ({ commit }) => {
    getPsychosocialAccompanimentsTypes()
      .then((response) => {
        commit("setPsychosocialAccompanimentsTypesMutation", response.data.accompaniment_type);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadPsychosocialAccompanimentsStatus: ({ commit }) => {
    getPsychosocialAccompanimentsStatus()
      .then((response) => {
        commit("setPsychosocialAccompanimentsStatusMutation", response.data.accompaniment_status);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingSituations: ({ commit }) => {
    getHousingSituations()
      .then((response) => {
        commit("setHousingSituationsMutation", response.data.housing_situations);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingBuildingSituations: ({ commit }) => {
    getPsychosocialHousingBuildingSituations()
      .then((response) => {
        commit("setHousingBuildingSituationsMutation", response.data.housing_building_situation);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingBuildingMaterials: ({ commit }) => {
    getPsychosocialHousingBuildingMaterials()
      .then((response) => {
        commit("setHousingBuildingMaterialsMutation", response.data.housing_building_material);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingHasElectricities: ({ commit }) => {
    getPsychosocialHousingElectricities()
      .then((response) => {
        commit("setHousingHasElectricitiesMutation", response.data.housing_has_electricity);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingHasWaterSupplies: ({ commit }) => {
    getPsychosocialHousingWaterSupplies()
      .then((response) => {
        commit("setHousingHasWaterSuppliesMutation", response.data.housing_has_water_supply);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingHasGasSupplies: ({ commit }) => {
    getPsychosocialHousingGasSupplies()
      .then((response) => {
        commit("setHousingHasGasSuppliesMutation", response.data.housing_has_gas_supply);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadHousingBathroomDrainTypes: ({ commit }) => {
    getPsychosocialHousingBathroomDrainTypes()
      .then((response) => {
        commit("setHousingBathroomDrainTypesMutation", response.data.housing_bathroom_drain_type);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadMeetingNotHappenedReasons: ({ commit }) => {
    getPsychosocialMeetingNotHappenedReasons()
      .then((response) => {
        commit("setMeetingNotHappenedReasonsMutation", response.data.meeting_not_happened_reason);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadSocioEducationalMeasureSituations: ({ commit }) => {
    getPsychosocialSocioEducationalMeasures()
      .then((response) => {
        commit(
          "setSocioEducationalMeasureSituationsMutation",
          response.data.socio_educational_measure_situation
        );
        commit("setSentenceServingSituationsMutation", response.data.socio_educational_measure_situation);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadGenders: ({ commit }) => {
    getPsychosocialGenders()
      .then((response) => {
        commit("setGendersMutation", response.data.gender);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadIdentityGendersOptions: ({ commit }) => {
    getIdentityGendersOptions()
      .then((response) => {
        commit("setidentifiesAsLgbtqiapnsMutation", response.data.identifies_as_lgbtqiapn);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadFacilitiesByForwardingPolicy: ({ commit }, payload) => {
    let params = {
      forwarding_policy_id: payload.id,
      by: "facilities.name",
      order: "ASC",
    };
    getFacilitiesByForwardingPolicy(params)
      .then((response) => {
        let response_facility = { index: payload.index, response: response.data.facilities };
        commit("setFacilitiesMutation", response_facility);
        let null_payload = { index: payload.index, null: true };
        commit("setForwardingFacilityMutation", null_payload);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadRelationshipDegrees: ({ commit }) => {
    getRelationshipDegrees()
      .then((response) => {
        commit("setRelationshipDegreesMutation", response.data.relationship_degree);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadPsychosocialAttendantsForPsychosocial: ({ commit }) => {
    let psychosocial_attendants = [];
    getPsychosocialAttendantsForPsychosocial()
      .then((response) => {
        const user_id = localStorage.user_id;
        response.data.psychosocial_attendants.forEach((item) => {
          if (item.user.id !== user_id) {
            psychosocial_attendants.push(item);
          }
        });
        commit("setPsychosocialAttendantsForPsychosocialMutation", psychosocial_attendants);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInstitutionalReferrals: ({ commit }) => {
    getInstitutionalReferrals()
      .then((response) => {
        commit("loadInstitutionalReferrals", response.data.institutional_referral);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadDataFromFirstService: async ({ dispatch, commit }) => {
    const params = {
      inscription_id: sessionStorage.inscription_id,
    };
    await getLastService(params)
      .then(async (response) => {
        if (response.data.psychosocial_accompaniments == null) {
          commit("setIsStudyingMutation", state.inscription.student.studying);
          if (state.inscription.student.completed_series) {
            commit("setLastGradeStudiedMutation", state.inscription.student.completed_series.name);
          } else {
            commit("setLastGradeStudiedMutation", "");
          }

          if (state.inscription.student.gender) {
            let gender = {
              id: null,
              name: null,
            };
            switch (state.inscription.student.gender) {
              case "Feminino":
                gender.id = 0;
                gender.name = "Mulher Cis";
                await dispatch("setGender", gender);
                break;
              case "Masculino":
                gender.id = 2;
                gender.name = "Homem Cis";
                await dispatch("setGender", gender);
                break;
              case "Não-Binárie":
                gender.id = 4;
                gender.name = "Não-Binárie";
                await dispatch("setGender", gender);
                break;
              case "Outros":
                gender.id = 5;
                gender.name = "Outro";
                await dispatch("setGender", gender);
                break;
              case "Prefiro não Falar":
                gender.id = 6;
                gender.name = "Prefiro não Falar";
                await dispatch("setGender", gender);
                break;
            }
          } else {
            commit("setGenderMutation", null);
          }

          if (state.inscription.student.identifies_as_lgbtqiapn) {
            let identifies_as_lgbtqiapn = {
              id: null,
              name: null,
            };
            switch (state.inscription.student.identifies_as_lgbtqiapn) {
              case "Sim":
                identifies_as_lgbtqiapn.id = 0;
                identifies_as_lgbtqiapn.name = "Sim";
                await dispatch("setidentifiesAsLgbtqiapn", identifies_as_lgbtqiapn);
                break;
              case "Não":
                identifies_as_lgbtqiapn.id = 1;
                identifies_as_lgbtqiapn.name = "Não";
                await dispatch("setidentifiesAsLgbtqiapn", identifies_as_lgbtqiapn);
                break;
              case "Prefiro não Falar":
                identifies_as_lgbtqiapn.id = 2;
                identifies_as_lgbtqiapn.name = "Prefiro não Falar";
                await dispatch("setidentifiesAsLgbtqiapn", identifies_as_lgbtqiapn);
                break;
            }
          } else {
            commit("setidentifiesAsLgbtqiapnMutation", null);
          }
        } else {
          await dispatch("insertDataFromFirstService", response.data.psychosocial_accompaniments);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  insertDataFromFirstService: ({ commit }, payload) => {
    commit("setHousingSituationMutation", payload.housing_situation);
    commit("setHousingHasLatrineMutation", payload.housing_has_latrine);
    commit("setHousingHasBathroomMutation", payload.housing_has_bathroom);
    commit("setHousingHasGarbageCollectionMutation", payload.housing_has_garbage_collection);
    commit("setHousingBuildingSituationMutation", payload.housing_building_situation);
    commit("setHousingBuildingMaterialMutation", payload.housing_building_material);
    commit("setHousingHasElectricityMutation", payload.housing_has_electricity);
    commit("setHousingHasWaterSupplyMutation", payload.housing_has_water_supply);
    commit("setHousingHasGasSupplyMutation", payload.housing_has_gas_supply);
    commit("setHousingBathroomDrainTypeMutation", payload.housing_bathroom_drain_type);
    commit("setHousingSituationCommentsMutation", payload.housing_situation_comments);
    commit("setSocioEducationalMeasureSituationMutation", payload.socio_educational_measure_situation);
    commit("setIsHalfOpenSocioEducationalMeasureMutation", payload.is_half_open_socio_educational_measure);
    commit(
      "setIsHalfClosedSocioEducationalMeasureMutation",
      payload.is_half_closed_socio_educational_measure
    );
    commit("setIsStudyingMutation", payload.is_studying);
    commit("setReasonForNotStudyingMutation", payload.reason_for_not_studying);
    commit("setLastGradeStudiedMutation", payload.last_grade_studied);
    commit("setLastSchoolStudiedMutation", payload.last_school_studied);
    commit("setNeedSchoolReinsertionMutation", payload.need_school_reinsertion);
    commit("setIsPregnantMutation", payload.is_pregnant);
    commit("setGestationalAgeMutation", payload.gestational_age);
    commit("setIsPuerperiumMutation", payload.is_puerperium);
    commit("setFrequentlyUsesHealthNetworkMutation", payload.frequently_uses_health_network);
    commit("setHealthPlaceFrequentlyMutation", payload.health_place_frequently);
    commit("setMotivationUsesHealthFrquentlyMutation", payload.motivation_uses_health_frequently);
    commit("setUsesPsychoactiveSubstancesMutation", payload.uses_psychoactive_substances);
    commit(
      "setUsesPsychoactiveSubstancesRecreationallyMutation",
      payload.uses_psychoactive_substances_recreationally
    );
    commit("setSelfMedicateMutation", payload.self_medicate);
    commit("setShowSignsOfSelfHarmMutation", payload.show_signs_of_self_harm);
    commit(
      "setShowSignsOfCompromisedPhysicalHealthMutation",
      payload.show_signs_of_compromised_physical_health
    );
    commit("setShowSignsOfCompromisedMentalHealthMutation", payload.show_signs_of_compromised_mental_health);
    commit("setHealthSituationObservationsMutation", payload.health_situation_observations);
    commit("setIsYoungFatherMutation", payload.is_young_father);
    commit("setIsYoungMotherMutation", payload.is_young_mother);
    commit("setNumberOfResidentsMutation", payload.number_of_residents);
    commit("setGenderMutation", payload.gender);
    commit("setidentifiesAsLgbtqiapnMutation", payload.identifies_as_lgbtqiapn);
    commit("setGenderIdentityObservationsMutation", payload.gender_identity_observations);
  },
  loadData: async ({ commit, dispatch }, payload) => {
    await getPsychosocialAccompanimentById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.psychosocial_accompaniment.id);
        commit(
          "setPsychosocialAccompanimentsTypeMutation",
          response.data.psychosocial_accompaniment.accompaniment_type
        );
        commit(
          "setPsychosocialAccompanimentStatusMutation",
          response.data.psychosocial_accompaniment.accompaniment_status
        );
        commit("setInscriptionMutation", response.data.psychosocial_accompaniment.inscription);
        commit("setDateAccompanimentMutation", response.data.psychosocial_accompaniment.date_accompaniment);
        commit(
          "setAccompanimentCommentsMutation",
          response.data.psychosocial_accompaniment.accompaniment_comments
        );
        commit("setMeetingHappenedMutation", response.data.psychosocial_accompaniment.meeting_happened);
        commit(
          "setMeetingNotHappenedReasonMutation",
          response.data.psychosocial_accompaniment.meeting_not_happened_reason
        );
        commit("setMeetingFormatMutation", response.data.psychosocial_accompaniment.meeting_format);
        commit("setMeetingPlaceMutation", response.data.psychosocial_accompaniment.meeting_place);
        commit(
          "setMeetingRemoteResourceMutation",
          response.data.psychosocial_accompaniment.meeting_remote_resource
        );
        commit(
          "setMeetingConclusionDescriptionMutation",
          response.data.psychosocial_accompaniment.meeting_conclusion_description
        );
        commit(
          "setAccompanimentWillContinueMutation",
          response.data.psychosocial_accompaniment.accompaniment_will_continue
        );
        commit(
          "setAccompanimentGuidelineMutation",
          response.data.psychosocial_accompaniment.accompaniment_guideline
        );
        commit(
          "setSpontaneousAttendanceMutation",
          response.data.psychosocial_accompaniment.spontaneous_attendance
        );
        commit("setUserMutation", response.data.psychosocial_accompaniment.user);
        commit(
          "setJustificationForNonAcceptanceMutation",
          response.data.psychosocial_accompaniment.justification_for_non_acceptance
        );
        commit(
          "setJustificationForNonAcceptanceDateMutation",
          response.data.psychosocial_accompaniment.justification_for_non_acceptance_date
        );
        commit("setPurposeHomeVisitMutation", response.data.psychosocial_accompaniment.purpose_home_visit);
        commit(
          "setSocialFamilyContextMutation",
          response.data.psychosocial_accompaniment.social_family_context
        );
        commit(
          "setSocialEconomicContextMutation",
          response.data.psychosocial_accompaniment.social_economic_context
        );
        commit(
          "setCommunityCoexistenceMutation",
          response.data.psychosocial_accompaniment.community_coexistence
        );
        commit(
          "setAccompanimentOriginIdtMutation",
          response.data.psychosocial_accompaniment.accompaniment_origin
        );
        if (
          response.data.psychosocial_accompaniment.disciplinary_sanctions_attributes?.length &&
          response.data.psychosocial_accompaniment.disciplinary_sanctions_attributes.length > 0
        ) {
          commit(
            "setDisciplinarySanctionMutation",
            response.data.psychosocial_accompaniment.disciplinary_sanctions_attributes
          );
        }
        if (
          response.data.psychosocial_accompaniment.forwarding &&
          response.data.psychosocial_accompaniment.forwarding.length > 0
        ) {
          commit("setRegisterForwardingMutation", true);
          commit("setForwadingsMutation", response.data.psychosocial_accompaniment.forwarding);
          response.data.psychosocial_accompaniment.forwarding.forEach((item, i) => {
            item.index = i;
            item.forwarding_policy.index = i;
            item.facility.index = i;
            item.psychosocial_accompaniment_id = response.data.psychosocial_accompaniment.id;
            item.inscription_id = response.data.psychosocial_accompaniment.inscription.id;
            if (i > 0) {
              dispatch("addForwarding");
            }
            commit("setForwardingPolicyMutation", item.forwarding_policy);
            commit("setForwardingFacilityMutation", item.facility);
            commit("setForwardingMutation", item);
            commit("setForwardingInscriptionMutation", item.inscription_id);
            commit("setForwardingUserMutation", item.forwarding_user_id);
            commit(
              "setForwardingPsychosocialAccompanimentMutation",
              response.data.psychosocial_accompaniment.id
            );
            if (item.forwarding_equipment) {
              commit("setForwardingEquipmentMutation", { index: i, value: item.forwarding_equipment });
            }
            commit("setForwardingDescriptionMutation", { index: i, value: item.forwarding_description });
            commit("setForwardingExpirationDateMutation", {
              index: i,
              value: item.forwarding_expiration_date,
            });
            commit("setForwardingDateMutation", { index: i, value: item.forwarding_date });
            commit("setForwardingIdMutation", { index: i, id: item.id });
          });
        }
        if (response.data.psychosocial_accompaniment.psychosocial_accompaniment_origin) {
          commit(
            "setAccompanimentOriginMutation",
            response.data.psychosocial_accompaniment.psychosocial_accompaniment_origin
          );
        }

        if (
          response.data.psychosocial_accompaniment.participant_presences &&
          response.data.psychosocial_accompaniment.participant_presences.length > 0
        ) {
          commit("setRegisterParticipantPresenceMutation", true);
          commit(
            "setPartipantPresencesMutation",
            response.data.psychosocial_accompaniment.participant_presences
          );
          response.data.psychosocial_accompaniment.participant_presences.forEach((item, i) => {
            item.index = i;
            item.value = item.name;
            item.relationship_degree.index = i;
            item.relationship_degree.value = item.relationship_degree.name;
            if (i > 0) {
              dispatch("addParticipantPresence");
            }
            commit("setRelationshipDegreeMutation", item.relationship_degree);
            commit("setParticipantPresenceNameMutation", item);
            commit("setParticipantPresenceIdMutation", { index: i, id: item.id });
          });
        }

        if (
          response.data.psychosocial_accompaniment.attendant_teams &&
          response.data.psychosocial_accompaniment.attendant_teams.length > 0
        ) {
          commit("setRegisterAttendatTeamsMutation", true);
          commit("setAttendantTeamsMutation", response.data.psychosocial_accompaniment.attendant_teams);
          response.data.psychosocial_accompaniment.attendant_teams.forEach((item, i) => {
            item.index = i;
            item.psychosocial_accompaniment_id = response.data.psychosocial_accompaniment.id;
            if (i > 0) {
              dispatch("addAttendantTeam");
            }
            commit("setAttendantTeamIdMutation", { index: i, id: item.attendant_team_id });
            commit("setPsychosocialAttendantMutation", item);
            commit("setPsychosocialAttendantPsychosocialAccompanimentMutation", item);
            dispatch("setPsychosocialAttendant", item);
          });
        }

        if (response.data.psychosocial_accompaniment.psychosocial_accompaniment_matrix_risk_stratification) {
          commit("setRegisterVulnerabilityMatrixMutation", true);
          commit(
            "setMatrixRiskStratificationsMutation",
            response.data.psychosocial_accompaniment.psychosocial_accompaniment_matrix_risk_stratification
          );
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAll: async ({ dispatch }, payload) => {
    await dispatch("insertDataFromFirstService", payload);
    await dispatch("loadData", payload);
  },

  // --> ACTIONS - FILTERS <--
  filterListByCPF: ({ commit }, payload) => {
    commit("filterListByCPF", payload);
  },
  filterListByInscription: ({ commit }, payload) => {
    commit("filterListByInscription", payload);
  },
  filterListByFirstService: ({ commit }, payload) => {
    commit("filterListByFirstService", payload);
  },
  filterListByHasDisciplinarySanction: ({ commit }, payload) => {
    commit("filterListByHasDisciplinarySanction", payload);
  },
  filterListByAccompanimentWillContinue: ({ commit }, payload) => {
    commit("filterListByAccompanimentWillContinue", payload);
  },
  filterListByArea: ({ commit }, payload) => {
    commit("filterListByArea", payload);
  },
  filterListByState: ({ commit }, payload) => {
    commit("filterListByState", payload);
  },
  filterListByCity: ({ commit }, payload) => {
    commit("filterListByCity", payload);
  },
  filterListByEquipment: ({ commit }, payload) => {
    commit("filterListByEquipment", payload);
  },
  filterListByStatusAccompaniment: ({ commit }, payload) => {
    commit("filterListByStatusAccompaniment", payload);
  },
  filterListByUser: ({ commit }, payload) => {
    commit("filterListByUser", payload);
  },
  filterListByTypeStatusEvolution: ({ commit }, payload) => {
    commit("filterListByTypeStatusEvolution", payload);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsForPsychosocialMutation", []);
      commit("filterListByEquipment", null);
    }
  },
  filterListByTypeAttendance: ({ commit }, payload) => {
    commit("filterListByTypeAttendance", payload);
  },
  filterListByTypeAttendanceMainPage: ({ commit }, payload) => {
    commit("filterListByTypeAttendanceMainPage", payload);
  },
  filterListByTypeSituation: ({ commit }, payload) => {
    commit("filterListByTypeSituation", payload);
  },
  filterListByMatrixSituations: ({ commit }, payload) => {
    commit("filterListByMatrixSituations", payload);
  },
  filterListByMatrixProblems: ({ commit }, payload) => {
    commit("filterListByMatrixProblems", payload);
  },
  filterListByIsPregnant: ({ commit }, payload) => {
    commit("filterListByIsPregnant", payload);
  },
  filterListByShowSignsOfSelfHarm: ({ commit }, payload) => {
    commit("filterListByShowSignsOfSelfHarm", payload);
  },
  filterListByUsesSubstances: ({ commit }, payload) => {
    commit("filterListByUsesSubstances", payload);
  },
  filterListByInstitutionalReferral: ({ commit }, payload) => {
    commit("filterListByInstitutionalReferral", payload);
  },

  // --> ACTIONS - OPENING AND CLOSING MODALS AND SCREENS <--
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadAll", payload);
    commit("toggleModalViewPsychosocialAccompanimentByInscription");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewPsychosocialAccompanimentByInscription");
    commit("clearFields");
    commit("clearFieldsForwarding");
    commit("clearFieldsParticipantPresence");
    commit("clearFieldsAttendantTeam");
  },
  openModalAddAttendance: async ({ commit, dispatch }) => {
    let tipo = {
      id: 0,
      name: "Acolhimento Inicial",
    };
    const inscription_id = sessionStorage.inscription_id;
    await dispatch("loadInscriptionByIdForPsychosocialAccompaniment", inscription_id);
    if (state.inscription.psychosocial_accompaniments_total < 1) {
      await dispatch("setPsychosocialAccompanimentsType", tipo);
    } else {
      if (!state.inscription.has_first_service) {
        await dispatch("setPsychosocialAccompanimentsType", tipo);
      }
    }
    commit("toggleModalAddAttendance");
  },
  closeModalAddAttendance: ({ commit }) => {
    commit("toggleModalAddAttendance");
    commit("clearFields");
    commit("clearFieldsForwarding");
    commit("clearFieldsParticipantPresence");
    commit("clearFieldsAttendantTeam");
  },
  openEditModalFirstPsychosocialAccompaniment: async ({ commit, dispatch }, payload) => {
    await dispatch("loadAll", payload);
    commit("toggleModalFirstPsychosocialAccompaniment");
  },
  closeModalFirstPsychosocialAccompaniment: ({ commit }) => {
    if (state.modalToggleAddAttendance) {
      commit("toggleModalAddAttendance");
    }
    commit("toggleModalFirstPsychosocialAccompaniment");
    commit("clearFields");
    commit("clearFieldsForwarding");
    commit("clearFieldsParticipantPresence");
    commit("clearFieldsAttendantTeam");
  },
  openEditModalPsychosocialIndividualMonitoring: async ({ commit, dispatch }, payload) => {
    await dispatch("loadDataFromFirstService");
    await dispatch("loadData", payload);
    commit("toggleModalPsychosocialIndividualMonitoring");
  },
  closeEditModalPsychosocialIndividualMonitoring: ({ commit }) => {
    if (state.modalToggleAddAttendance) {
      commit("toggleModalAddAttendance");
    }
    commit("toggleModalPsychosocialIndividualMonitoring");
    commit("clearFields");
    commit("clearFieldsForwarding");
    commit("clearFieldsParticipantPresence");
    commit("clearFieldsAttendantTeam");
  },
  openEditModalPsychosocialHomeVisit: async ({ commit, dispatch }, payload) => {
    await dispatch("loadDataFromFirstService");
    await dispatch("loadData", payload);
    commit("toggleModalPsychosocialHomeVisit");
  },
  closeEditModalPsychosocialHomeVisit: ({ commit }) => {
    if (state.modalToggleAddAttendance) {
      commit("toggleModalAddAttendance");
    }
    commit("toggleModalPsychosocialHomeVisit");
    commit("clearFields");
    commit("clearFieldsForwarding");
    commit("clearFieldsParticipantPresence");
    commit("clearFieldsAttendantTeam");
  },
  openEditModalPsychosocialAccompanimentJustifyNonReception: async ({ commit, dispatch }, payload) => {
    await dispatch("loadDataFromFirstService");
    await dispatch("loadData", payload);
    commit("toggleModalPsychosocialAccompanimentJustifyNonReception");
  },
  closeEditModalPsychosocialAccompanimentJustifyNonReception: ({ commit }) => {
    if (state.modalToggleAddAttendance) {
      commit("toggleModalAddAttendance");
    }
    commit("toggleModalPsychosocialAccompanimentJustifyNonReception");
    commit("clearFields");
    commit("clearFieldsForwarding");
    commit("clearFieldsParticipantPresence");
    commit("clearFieldsAttendantTeam");
  },
  openPsychosocialAccompanimentMainPage: ({ commit }) => {
    commit("togglePsychosocialAccompanimentMainPage");
  },
  closePsychosocialAccompanimentMainPage: ({ commit }) => {
    commit("togglePsychosocialAccompanimentMainPage");
  },
  openModalPsychosocialAccompanimentPostponeDate: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalPsychosocialAccompanimentPostponeDate");
  },
  closeModalPsychosocialAccompanimentPostponeDate: ({ commit }) => {
    commit("toggleModalPsychosocialAccompanimentPostponeDate");
    commit("clearFields");
  },
  toggleModalDeletePsychosocialAccompaniment: ({ commit }, payload) => {
    commit("toggleModalDeletePsychosocialAccompaniment");
    if (state.modalConfirmDeletePsychosocialAccompaniment && payload) {
      commit("psychosocialAccompanimentIdForDeletion", payload);
    } else {
      commit("psychosocialAccompanimentIdForDeletion", null);
    }
  },
  toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix: ({ commit }) => {
    commit("toggleModalConfirmSavePsychosocialAccompanimentWithoutMatrix");
  },
  chooseModalToOpen: async ({ commit, dispatch }) => {
    if (state.accompaniments_type?.name === "Justificar Não Acolhimento") {
      await dispatch("loadDataFromFirstService");
      commit("toggleModalPsychosocialAccompanimentJustifyNonReception");
    } else {
      if (state.psychosocial_accompaniment.date_accompaniment) {
        switch (state.accompaniments_type.name) {
          case "Acolhimento Inicial":
            await dispatch("loadDataFromFirstService");
            commit("toggleModalFirstPsychosocialAccompaniment");
            break;
          case "Atendimento Individual":
            await dispatch("loadDataFromFirstService");
            commit("toggleModalPsychosocialIndividualMonitoring");
            break;
          case "Visita Domiciliar":
            await dispatch("loadDataFromFirstService");
            commit("toggleModalPsychosocialHomeVisit");
            break;
        }
      } else {
        Vue.$toast.error("A data do encontro deve ser informada!");
      }
    }
    if (state.modalToggleAddAttendance) {
      commit("toggleModalAddAttendance");
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportPsychosocialAccompanimentPendingReceptionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet");
  },
  closeExportPsychosocialAccompanimentPendingReceptionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentPendingReceptionSpreadsheet");
  },
  openExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet");
  },
  closeExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet");
  },
  openExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet");
  },
  closeExportPsychosocialAccompanimentInAccompanimentSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentInAccompanimentSpreadsheet");
  },
  openExportPsychosocialAccompanimentEvolutionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet");
  },
  closeExportPsychosocialAccompanimentEvolutionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportPsychosocialAccompanimentEvolutionSpreadsheet");
  },

  // --> ACTIONS - PAGINATIONS <--
  setTotalPerPage: ({ commit }, payload) => {
    commit("setTotalPerPage", payload.totalPerPage);
  },
  setOffset: ({ commit }, payload) => {
    commit("setOffset", payload.offset);
  },
  setPsychosocialAccompanimentByInscriptionTotalPerPage: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentByInscriptionTotalPerPage", payload.totalPerPage);
  },
  setPsychosocialAccompanimentByInscriptionOffset: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentByInscriptionOffset", payload.offset);
  },

  // --> ACTIONS - SETTING TABS <--
  setTabPsychosocialAccompanimentView: ({ commit }, payload) => {
    commit("setTabPsychosocialAccompanimentViewMutation", payload);
    switch (payload) {
      case "pendingReception":
        commit("togglePsychosocialAccompanimentPendingReception", true);
        commit("togglePsychosocialAccompanimentUnaccompanied", false);
        commit("togglePsychosocialAccompanimentInAccompaniment", false);
        break;
      case "unaccompanied":
        commit("togglePsychosocialAccompanimentPendingReception", false);
        commit("togglePsychosocialAccompanimentUnaccompanied", true);
        commit("togglePsychosocialAccompanimentInAccompaniment", false);
        break;
      case "inAccompaniment":
        commit("togglePsychosocialAccompanimentPendingReception", false);
        commit("togglePsychosocialAccompanimentUnaccompanied", false);
        commit("togglePsychosocialAccompanimentInAccompaniment", true);
        break;
    }
  },
  setTabPsychosocialAccompanimentType: async ({ commit, dispatch }, payload) => {
    const inscription_id = sessionStorage.inscription_id;
    await dispatch("loadInscriptionByIdForPsychosocialAccompaniment", inscription_id);
    commit("setTabPsychosocialAccompanimentTypeMutation", payload);
    switch (payload) {
      case "personal_data":
        commit("togglePsychosocialAccompanimentPersonalData", true);
        commit("togglePsychosocialAccompanimentVulnerability", false);
        commit("togglePsychosocialAccompanimentGuidelines", false);
        commit("togglePsychosocialAccompanimentEvolution", false);
        commit("togglePsychosocialAccompanimentForwardings", false);
        break;
      case "vulnerability":
        commit("togglePsychosocialAccompanimentPersonalData", false);
        commit("togglePsychosocialAccompanimentVulnerability", true);
        commit("togglePsychosocialAccompanimentGuidelines", false);
        commit("togglePsychosocialAccompanimentEvolution", false);
        commit("togglePsychosocialAccompanimentForwardings", false);
        break;
      case "guidelines":
        commit("togglePsychosocialAccompanimentPersonalData", false);
        commit("togglePsychosocialAccompanimentVulnerability", false);
        commit("togglePsychosocialAccompanimentGuidelines", true);
        commit("togglePsychosocialAccompanimentEvolution", false);
        commit("togglePsychosocialAccompanimentForwardings", false);
        break;
      case "evolution":
        commit("togglePsychosocialAccompanimentPersonalData", false);
        commit("togglePsychosocialAccompanimentVulnerability", false);
        commit("togglePsychosocialAccompanimentGuidelines", false);
        commit("togglePsychosocialAccompanimentEvolution", true);
        commit("togglePsychosocialAccompanimentForwardings", false);
        break;
      case "forwardings":
        commit("togglePsychosocialAccompanimentPersonalData", false);
        commit("togglePsychosocialAccompanimentVulnerability", false);
        commit("togglePsychosocialAccompanimentGuidelines", false);
        commit("togglePsychosocialAccompanimentEvolution", false);
        commit("togglePsychosocialAccompanimentForwardings", true);
        break;
    }
  },

  // --> ACTIONS - FIELDS TO THE TABLE <--
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setPsychosocialAccompanimentStatus: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentStatusMutation", payload);
  },
  setPsychosocialAccompanimentsType: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentsTypeMutation", payload);
  },
  setDateAccompaniment: ({ commit }, payload) => {
    commit("setDateAccompanimentMutation", payload.value);
  },
  setAccompanimentComments: ({ commit }, payload) => {
    commit("setAccompanimentCommentsMutation", payload.value);
  },
  setAccompanimentOrigin: ({ commit }, payload) => {
    commit("setAccompanimentOriginMutation", payload);
  },
  setMeetingHappened: ({ commit }, payload) => {
    commit("setMeetingHappenedMutation", payload.value);
  },
  setMeetingNotHappenedReason: ({ commit }, payload) => {
    commit("setMeetingNotHappenedReasonMutation", payload);
  },
  setMeetingFormat: ({ commit }, payload) => {
    commit("setMeetingFormatMutation", payload.value);
  },
  setMeetingPlace: ({ commit }, payload) => {
    commit("setMeetingPlaceMutation", payload.value);
  },
  setMeetingRemoteResource: ({ commit }, payload) => {
    commit("setMeetingRemoteResourceMutation", payload.value);
  },
  setMeetingConclusionDescription: ({ commit }, payload) => {
    commit("setMeetingConclusionDescriptionMutation", payload.value);
  },
  setForwarded: ({ commit }, payload) => {
    commit("setForwardedMutation", payload.value);
  },
  setHousingSituation: ({ commit }, payload) => {
    commit("setHousingSituationMutation", payload);
  },
  setHousingSituationComments: ({ commit }, payload) => {
    commit("setHousingSituationCommentsMutation", payload.value);
  },
  setHousingHasLatrine: ({ commit }, payload) => {
    commit("setHousingHasLatrineMutation", payload.value);
  },
  setHousingHasBathroom: ({ commit }, payload) => {
    commit("setHousingHasBathroomMutation", payload.value);
  },
  setHousingHasGarbageCollection: ({ commit }, payload) => {
    commit("setHousingHasGarbageCollectionMutation", payload.value);
  },
  setHousingBuildingSituation: ({ commit }, payload) => {
    commit("setHousingBuildingSituationMutation", payload);
  },
  setHousingBuildingMaterial: ({ commit }, payload) => {
    commit("setHousingBuildingMaterialMutation", payload);
  },
  setHousingHasElectricity: ({ commit }, payload) => {
    commit("setHousingHasElectricityMutation", payload);
  },
  setHousingHasWaterSupply: ({ commit }, payload) => {
    commit("setHousingHasWaterSupplyMutation", payload);
  },
  setHousingHasGasSupply: ({ commit }, payload) => {
    commit("setHousingHasGasSupplyMutation", payload);
  },
  setHousingBathroomDrainType: ({ commit }, payload) => {
    commit("setHousingBathroomDrainTypeMutation", payload);
  },
  setSocioEducationalMeasureSituation: ({ commit }, payload) => {
    commit("setSocioEducationalMeasureSituationMutation", payload);
    if (payload.id === 1 || payload.id === 2) {
      if (state.psychosocial_accompaniment.is_half_open_socio_educational_measure === false) {
        commit("setIsHalfClosedSocioEducationalMeasureMutation", true);
      }
      if (state.psychosocial_accompaniment.is_half_closed_socio_educational_measure === false) {
        commit("setIsHalfOpenSocioEducationalMeasureMutation", true);
      }
    }
  },
  setIsHalfOpenSocioEducationalMeasure: ({ commit }, payload) => {
    commit("setIsHalfOpenSocioEducationalMeasureMutation", payload.value);
    if (
      state.psychosocial_accompaniment.socio_educational_measure_situation === 1 ||
      state.psychosocial_accompaniment.socio_educational_measure_situation === 2
    ) {
      if (payload.value === false) {
        commit("setIsHalfClosedSocioEducationalMeasureMutation", true);
      }
    }
  },
  setIsHalfClosedSocioEducationalMeasure: ({ commit }, payload) => {
    commit("setIsHalfClosedSocioEducationalMeasureMutation", payload.value);
    if (
      state.psychosocial_accompaniment.socio_educational_measure_situation === 1 ||
      state.psychosocial_accompaniment.socio_educational_measure_situation === 2
    ) {
      if (payload.value === false) {
        commit("setIsHalfOpenSocioEducationalMeasureMutation", true);
      }
    }
  },
  setIsHalfOpenSentenceServingSituation: ({ commit }, payload) => {
    commit("setIsHalfOpenSentenceServingSituationMutation", payload.value);
    if (
      state.psychosocial_accompaniment.sentence_serving_situation === 1 ||
      state.psychosocial_accompaniment.sentence_serving_situation === 2
    ) {
      if (payload.value === false) {
        commit("setIsHalfClosedSentenceServingSituationMutation", true);
      }
    }
  },
  setIsHalfClosedSentenceServingSituation: ({ commit }, payload) => {
    commit("setIsHalfClosedSentenceServingSituationMutation", payload.value);
    if (
      state.psychosocial_accompaniment.sentence_serving_situation === 1 ||
      state.psychosocial_accompaniment.sentence_serving_situation === 2
    ) {
      if (payload.value === false) {
        commit("setIsHalfOpenSentenceServingSituationMutation", true);
      }
    }
  },
  setSentenceServingSituation: ({ commit }, payload) => {
    commit("setSentenceServingSituationMutation", payload);
    if (payload.id === 1 || payload.id === 2) {
      if (state.psychosocial_accompaniment.is_half_open_sentence_serving_situation === false) {
        commit("setIsHalfClosedSentenceServingSituationMutation", true);
      }
      if (state.psychosocial_accompaniment.is_half_closed_sentence_serving_situation === false) {
        commit("setIsHalfOpenSentenceServingSituationMutation", true);
      }
    }
  },
  setObeySocioEducationalMeasure: ({ commit }, payload) => {
    commit("setObeySocioEducationalMeasureMutation", payload.value);
  },
  setObeyedSocioEducationalMeasure: ({ commit }, payload) => {
    commit("setObeyedSocioEducationalMeasureMutation", payload.value);
  },
  setIsStudying: ({ commit }, payload) => {
    commit("setIsStudyingMutation", payload.value);
  },
  setReasonForNotStudying: ({ commit }, payload) => {
    commit("setReasonForNotStudyingMutation", payload.value);
  },
  setReasonForDropoutStudy: ({ commit }, payload) => {
    commit("setReasonForDropoutStudyMutation", payload.value);
  },
  setLastGradeStudied: ({ commit }, payload) => {
    commit("setLastGradeStudiedMutation", payload.value);
  },
  setLastSchoolStudied: ({ commit }, payload) => {
    commit("setLastSchoolStudiedMutation", payload.value);
  },
  setNeedSchoolReinsertion: ({ commit }, payload) => {
    commit("setNeedSchoolReinsertionMutation", payload.value);
  },
  setIsPregnant: ({ commit }, payload) => {
    commit("setIsPregnantMutation", payload.value);
    if (!payload.value) {
      commit("setGestationalAgeMutation", null);
    }
  },
  setGestationalAge: ({ commit }, payload) => {
    commit("setGestationalAgeMutation", payload.value);
  },
  setIsPuerperium: ({ commit }, payload) => {
    commit("setIsPuerperiumMutation", payload.value);
  },
  setUsePsychoactive: ({ commit }, payload) => {
    commit("setUsePsychoactiveMutation", payload.value);
  },
  setFrequentlyUsesHealthNetwork: ({ commit }, payload) => {
    commit("setFrequentlyUsesHealthNetworkMutation", payload.value);
  },
  setHealthPlaceFrequently: ({ commit }, payload) => {
    commit("setHealthPlaceFrequentlyMutation", payload.value);
  },
  setMotivationUsesHealthFrquently: ({ commit }, payload) => {
    commit("setMotivationUsesHealthFrquentlyMutation", payload.value);
  },
  setAccompanimentWillContinue: ({ commit }, payload) => {
    commit("setAccompanimentWillContinueMutation", payload.value);
  },
  setAccompanimentGuideline: ({ commit }, payload) => {
    commit("setAccompanimentGuidelineMutation", payload.value);
  },
  setSpontaneousAttendance: ({ commit }, payload) => {
    commit("setSpontaneousAttendanceMutation", payload.value);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setIsYoungFather: ({ commit }, payload) => {
    commit("setIsYoungFatherMutation", payload.value);
    if (payload.value == true) {
      commit("setIsYoungMotherMutation", false);
    }
  },
  setIsYoungMother: ({ commit }, payload) => {
    commit("setIsYoungMotherMutation", payload.value);
    if (payload.value == true) {
      commit("setIsYoungFatherMutation", false);
    }
  },
  setPurposeHomeVisit: ({ commit }, payload) => {
    commit("setPurposeHomeVisitMutation", payload.value);
  },
  setSocialFamilyContext: ({ commit }, payload) => {
    commit("setSocialFamilyContextMutation", payload.value);
  },
  setSocialEconomicContext: ({ commit }, payload) => {
    commit("setSocialEconomicContextMutation", payload.value);
  },
  setCommunityCoexistence: ({ commit }, payload) => {
    commit("setCommunityCoexistenceMutation", payload.value);
  },
  setNumberOfResidents: ({ commit }, payload) => {
    commit("setNumberOfResidentsMutation", payload.value);
  },
  setGender: ({ commit }, payload) => {
    commit("setGenderMutation", payload);
  },
  setGenderIdentityObservations: ({ commit }, payload) => {
    commit("setGenderIdentityObservationsMutation", payload.value);
  },
  setidentifiesAsLgbtqiapn: ({ commit }, payload) => {
    commit("setidentifiesAsLgbtqiapnMutation", payload);
  },
  setUsesPsychoactiveSubstances: ({ commit }, payload) => {
    commit("setUsesPsychoactiveSubstancesMutation", payload.value);
  },
  setUsesPsychoactiveSubstancesRecreationally: ({ commit }, payload) => {
    commit("setUsesPsychoactiveSubstancesRecreationallyMutation", payload.value);
  },
  setSelfMedicate: ({ commit }, payload) => {
    commit("setSelfMedicateMutation", payload.value);
  },
  setShowSignsOfSelfHarm: ({ commit }, payload) => {
    commit("setShowSignsOfSelfHarmMutation", payload.value);
  },
  setShowSignsOfCompromisedPhysicalHealth: ({ commit }, payload) => {
    commit("setShowSignsOfCompromisedPhysicalHealthMutation", payload.value);
  },
  setShowSignsOfCompromisedMentalHealth: ({ commit }, payload) => {
    commit("setShowSignsOfCompromisedMentalHealthMutation", payload.value);
  },
  setHealthSituationObservations: ({ commit }, payload) => {
    commit("setHealthSituationObservationsMutation", payload.value);
  },
  setJustificationForNonAcceptance: ({ commit }, payload) => {
    commit("setJustificationForNonAcceptanceMutation", payload.value);
  },

  // --> ACTIONS - FIELDS TO THE TABLE [CONTINUED ACCOMPANIMENT] <--
  setIdContinuedAccompaniment: ({ commit }, payload) => {
    commit("setIdContinuedAccompanimentMutation", payload);
  },
  setInscriptionContinuedAccompaniment: ({ commit }, payload) => {
    commit("setInscriptionContinuedAccompanimentMutation", payload);
  },
  setPsychosocialAccompanimentStatusContinuedAccompaniment: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentStatusContinuedAccompanimentMutation", payload);
  },
  setPsychosocialAccompanimentsTypeContinuedAccompaniment: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentsTypeContinuedAccompanimentMutation", payload);
  },
  setDateAccompanimentContinuedAccompaniment: ({ commit }, payload) => {
    commit("setDateAccompanimentContinuedAccompanimentMutation", payload.value);
  },
  setUserContinuedAccompaniment: ({ commit }, payload) => {
    commit("setUserContinuedAccompanimentMutation", payload);
  },
  setAccompanimentOriginIdContinuedAccompaniment: ({ commit }, payload) => {
    commit("setAccompanimentOriginIdContinuedAccompanimentMutation", payload);
  },

  // --> ACTIONS - FIELDS TO THE FORWARDINGS <--
  setForwardingPolicy: ({ dispatch, commit }, payload) => {
    commit("setForwardingPolicyMutation", payload);
    dispatch("loadFacilitiesByForwardingPolicy", payload);
  },
  setForwardingFacility: ({ commit }, payload) => {
    commit("setForwardingFacilityMutation", payload);
  },
  setForwardingEquipment: ({ commit }, payload) => {
    commit("setForwardingEquipmentMutation", payload);
  },
  setForwardingDate: ({ commit }, payload) => {
    commit("setForwardingDateMutation", payload);
  },
  setForwardingExpirationDate: ({ commit }, payload) => {
    commit("setForwardingExpirationDateMutation", payload);
  },
  setForwardingDescription: ({ commit }, payload) => {
    commit("setForwardingDescriptionMutation", payload);
  },
  setForwardingDifferenceBetweenDates: ({ commit }, payload) => {
    commit("setForwardingDifferenceBetweenDatesMutation", payload);
  },
  setForwardingFullDate: ({ commit }, payload) => {
    commit("setForwardingFullDateMutation", payload);
  },
  addForwarding: ({ commit }) => {
    commit("addForwarding");
    commit("addForwardingPolicy");
    commit("addForwardingFacility");
    commit("addFacilities");
  },
  deleteForwarding: ({ commit }) => {
    commit("deleteForwarding");
    commit("deleteForwardingPolicy");
    commit("deleteForwardingFacility");
    commit("deleteFacilities");
  },

  // --> ACTIONS - FIELDS TO THE PARTICIPANT_PRESENCES <--
  setRelationshipDegree: ({ commit }, payload) => {
    commit("setRelationshipDegreeMutation", payload);
  },
  setParticipantPresenceName: ({ commit }, payload) => {
    commit("setParticipantPresenceNameMutation", payload);
  },
  addParticipantPresence: ({ commit }) => {
    commit("addParticipantPresence");
    commit("addRelationshipDegreeParticipantPresence");
  },
  deleteParticipantPresence: ({ commit }) => {
    commit("deleteParticipantPresence");
    commit("deleteRelationshipDegreeParticipantPresence");
  },

  // --> ACTIONS - FIELDS TO THE ATTENDANT_TEAMS <--
  setPsychosocialAttendant: ({ commit }, payload) => {
    commit("setPsychosocialAttendantMutation", payload);
  },
  addAttendantTeam: ({ commit }) => {
    commit("addAttendantTeam");
    commit("addPsychosocialAttendant");
  },
  deleteAttendantTeam: ({ commit }) => {
    commit("deleteAttendantTeam");
    commit("deletePsychosocialAttendant");
  },

  // --> ACTIONS - FIELDS TO THE DISCIPLINARY_SANCTION <--
  addDisciplinarySanction: ({ commit }, payload) => {
    commit("addDisciplinarySanctionMutation", payload);
  },
  updateDisciplinarySanction: ({ commit }, payload) => {
    let value = payload.input ? payload.value : null;
    let object = payload.input ? null : { id: payload.id, name: payload.name };

    commit("updateDisciplinarySanctionMutation", {
      index: payload.index,
      field: payload.field,
      value: payload.input ? value : object,
    });
  },
  setDisciplinarySanctionMeasure: ({ commit }, payload) => {
    commit("setDisciplinarySanctionMeasureMutation", payload);
  },
  removeDisciplinarySanction: ({ commit }, index) => {
    commit("removeDisciplinarySanctionMutation", index);
  },

  // --> OTHERS ACTIONS <--
  clearQueryMainPage: ({ commit }) => {
    commit("clearQueryMainPage");
  },
  clearQueryEvolutionPage: ({ commit }) => {
    commit("clearQueryEvolutionPage");
  },
  setInscription: async ({ commit }, payload) => {
    await commit("setInscriptionMutation", payload);
  },
  setPsychosocialAccompaniment: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
  setRegisterForwarding: ({ commit }, payload) => {
    commit("setRegisterForwardingMutation", payload.value);
  },
  setRegisterVulnerabilityMatrix: ({ commit }, payload) => {
    commit("setRegisterVulnerabilityMatrixMutation", payload.value);
  },
  setRegisterParticipantPresence: ({ commit }, payload) => {
    commit("setRegisterParticipantPresenceMutation", payload.value);
  },
  setRegisterAttendatTeams: ({ commit }, payload) => {
    commit("setRegisterAttendatTeamsMutation", payload.value);
  },
  setIgnoreMatrixRisk: ({ commit }, payload) => {
    commit("setIgnoreMatrixRisk", payload);
  },

  // --> ACTIONS - CRUD <--
  save: async ({ commit, dispatch, rootState }) => {
    let forwarding_attribute = getForwardingAttributes();
    let participant_presence_attribute = getParticipantPresenceAttributes();
    let attendant_team_attribute = getAttendantTeamAttributes();
    commit("updateDisciplinarySanctionsAttributes");

    await createPsychosocialAccompanimentLogic(
      rootState,
      forwarding_attribute,
      participant_presence_attribute,
      attendant_team_attribute,
      commit,
      dispatch
    );
  },
  update: ({ dispatch, commit, rootState }) => {
    if (state.modalToggleAddAttendance) {
      dispatch("closeModalAddAttendance");
    }

    let participant_presence_attribute = getParticipantPresenceAttributes();
    let forwarding_attribute = getForwardingAttributes();
    let attendant_team_attribute = getAttendantTeamAttributes();
    commit("updateDisciplinarySanctionsAttributes");

    updatePsychosocialAccompanimentLogic(
      rootState,
      forwarding_attribute,
      attendant_team_attribute,
      participant_presence_attribute,
      commit,
      dispatch
    );
  },
  delete: ({ commit, dispatch }) => {
    destroyPsychosocialAccompaniment(state.psychosocialAccompanimentId)
      .then(() => {
        Vue.$toast.success("Atendimento deletado com sucesso!");
        commit("toggleModalDeletePsychosocialAccompaniment");
        dispatch("loadPsychosocialAccompanimentsByInscription");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
