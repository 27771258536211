var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col md:flex-row ml-5 md:ml-20" }, [
    _c("div", { staticClass: "w-full md:w-168" }, [
      _c(
        "div",
        { staticClass: "flex flex-col justify-center h-screen p-5 md:p-0" },
        [
          _c(
            "div",
            [
              _c(
                "BaseText",
                {
                  attrs: {
                    "type-text": "custom",
                    weight: "semibold",
                    size: "2xl md:3xl",
                    color: "herbal",
                  },
                },
                [
                  _c("p", [_vm._v("Olá!")]),
                  _c("p", [_vm._v("Bem-vindo(a) ao sistema")]),
                ]
              ),
              _c("BaseText", {
                attrs: {
                  "type-text": "custom",
                  text: "Virando o Jogo!",
                  weight: "extrabold",
                  size: "2xl md:3xl",
                  color: "herbal",
                },
              }),
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c("BaseText", {
                    attrs: {
                      "type-text": "custom",
                      text: "Vamos começar?",
                      weight: "normal",
                      size: "lg",
                      color: "herbal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c("div", {
      staticClass: "background w-full md:w-screen h-64 md:h-screen",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }