var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-12 my-6" },
    [
      _c(
        "div",
        {
          staticClass: "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          attrs: { id: "title-pre-inscriptions" },
        },
        [_vm._v(" Turmas ")]
      ),
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
      }),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 justify-between items-end gap-4 mt-2",
        },
        [
          _c(
            "div",
            [
              _c("Multiselect", {
                staticClass: "mr-5",
                attrs: {
                  id: "filterArea",
                  selectPlaceholder: "Selecionar",
                  "label-text": "filtrar por área",
                  closeOnSelect: "",
                  "show-label": "",
                  multipleOption: false,
                  options: _vm.user_areas,
                  value: _vm.areas_equipment,
                  dispatch: _vm.setArea,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "code-team",
                  "show-label": "",
                  "label-text": "Filtrar pelo código",
                  "input-placeholder": "Pesquisar pelo código",
                  "input-type": "text",
                  dispatch: "Teams/filterByTeamCode",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "equipment-team",
                  "show-label": "",
                  "label-text": "Filtrar pelo equipamento",
                  "input-placeholder": "Pesquisar pelo equipamento",
                  "input-type": "text",
                  dispatch: "Teams/filterByTeamEquipment",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "course-team",
                  "show-label": "",
                  "label-text": "Filtrar pelo curso",
                  "input-placeholder": "Pesquisar pelo curso",
                  "input-type": "text",
                  dispatch: "Teams/filterByTeamCourse",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Multiselect", {
                attrs: {
                  "close-on-select": "",
                  "show-label": "",
                  "select-placeholder": "Filtrar pelo Instrutor(a)",
                  "label-text": "Instrutor",
                  "multiple-option": false,
                  value: _vm.teacher,
                  options: _vm.teachers,
                  dispatch: _vm.setTeacher,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "birth-year-relative",
                  "show-label": "",
                  "label-text": "Data de Início",
                  "input-type": "date",
                  value: _vm.start,
                  dispatch: "Teams/filterTeamsByPeriodStart",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "birth-year-relative",
                  "show-label": "",
                  "label-text": "Data de Fim",
                  "input-type": "date",
                  value: _vm.closing,
                  dispatch: "Teams/filterTeamsByPeriodClosing",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "grid grid-rols-3 my-5" }, [
        _c(
          "div",
          { staticClass: "mt-5" },
          [_c("TeamsCards", { attrs: { data: _vm.teams } })],
          1
        ),
        _vm.teams.length > 0
          ? _c(
              "div",
              {
                staticClass: "mx-12 my-6 pb-3",
                attrs: { id: "team-pagination" },
              },
              [
                _c("BasePagination", {
                  attrs: {
                    total: _vm.totalTeams,
                    "total-per-page": _vm.totalPerPage,
                    dispatch: "Teams/setOffset",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("ModalTeam", { attrs: { open: _vm.modalTeamOpen } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }